import { useEffect, useMemo } from 'react';
import { useMeasure } from 'react-use';

import { useCalendarCtx } from '../CalendarCtx';

export const useMaxElementsPerCell = (itemSize = 20) => {
  const {
    maxItemsPerCell: [, setMaxItems],
    cellHeight: [, setCellHeight],
  } = useCalendarCtx();

  const [ref, { height }] = useMeasure();

  const maxItems = useMemo(() => {
    return Math.floor(height / (itemSize + 1));
  }, [height, itemSize]);

  useEffect(() => {
    setCellHeight(height);
    setMaxItems(maxItems);
  }, [height, maxItems, setCellHeight, setMaxItems]);

  return { ref, height, maxItems };
};
