import { ReactElement } from 'react';

import { ItemClickEvent } from '@bq/components/Calendar';

import {
  AppointmentEventsProvider,
  useAppointmentEvents,
} from '../Appointment/AppointmentEventsProvider';
import { AppointmentFormModal } from '../Appointment/AppointmentFormModal';
import { IAppointment } from '../Appointment/schema';

export const AppointmentModalContent = (
  props: ItemClickEvent<IAppointment>
): ReactElement => {
  const handlers = useAppointmentEvents();

  return (
    <AppointmentEventsProvider {...handlers}>
      <AppointmentFormModal
        mode="edit"
        appointmentID={props.item.additionalProps.ID}
      />
    </AppointmentEventsProvider>
  );
};
