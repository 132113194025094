import slugify from 'slugify';

import { uniqid } from 'app/assets/js/tsutil';

import { IOverviewEditorItem } from '../types';
import { OverviewDragItem, OverviewDragItemItem } from './types';

export function itemToDragItem(item: IOverviewEditorItem): OverviewDragItem {
  if (item.type === 'group') {
    return {
      id: uniqid(),
      hasChildren: true,
      content: item,
      children: (item.overviews ?? []).map(itemToDragItem),
    };
  }

  return {
    id: uniqid(),
    hasChildren: false,
    content: item,
  };
}

export function dragItemsToItems(
  items: OverviewDragItemItem[],
  prefix = ''
): IOverviewEditorItem[] {
  // Store previous sibling's slugs for deduplication
  const otherSlugs: string[] = [];

  return items.map((dragItem) => {
    const overviewItem = dragItemToItem(dragItem, otherSlugs, prefix);
    otherSlugs.push(overviewItem.slug);

    return overviewItem;
  });
}

function dragItemToItem(
  item: OverviewDragItemItem,
  siblingSlugs?: string[],
  prefix = ''
): IOverviewEditorItem {
  const slug = genSlug(item.content.title, prefix, siblingSlugs);

  if (item.hasChildren) {
    return {
      ...item.content,
      slug,
      overviews: dragItemsToItems(item.children, `${slug}-`),
    };
  }

  return {
    ...item.content,
    slug,
  };
}

export function genSlug(
  title: string,
  prefix = '',
  siblingSlugs: string[] = []
): string {
  const cleanTitle = title.replace(/[.()]/g, ' ');
  const ownSlug = slugify(cleanTitle, { lower: true });
  const fullSlug = `${prefix}${ownSlug}`;

  if (!siblingSlugs.includes(fullSlug)) {
    return fullSlug;
  }

  let dedupNumber = 1;
  while (siblingSlugs.includes(`${fullSlug}-${dedupNumber}`)) {
    ++dedupNumber;
  }

  return `${fullSlug}-${dedupNumber}`;
}

export function isItem(item: OverviewDragItem): item is OverviewDragItemItem {
  return item.content.type !== 'root';
}

export function resetIds(item: OverviewDragItemItem): OverviewDragItemItem {
  if (item.hasChildren) {
    return {
      ...item,
      id: uniqid(),
      children: item.children.map(resetIds),
    };
  }

  return {
    ...item,
    id: uniqid(),
  };
}
