import { useMemo } from 'react';
import { ButtonGroup } from '@chakra-ui/react';
import { addMonths, startOfMonth, subMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import { useCalendarCtx } from '@bq/components/Calendar/CalendarCtx';
import { IconButton } from '@bq/components/IconButton';

export const MonthsControls = () => {
  const {
    currentDate: [date, setDate],
  } = useCalendarCtx();
  const firstDayNextMonth = useMemo(
    () => startOfMonth(addMonths(date, 1)),
    [date]
  );
  const firstDayPrevMonth = useMemo(
    () => startOfMonth(subMonths(date, 1)),
    [date]
  );
  const { t } = useTranslation('global');

  return (
    <ButtonGroup isAttached variant="ghost">
      <IconButton
        onClick={() => {
          setDate(firstDayPrevMonth);
        }}
        icon={<FaArrowLeft />}
        label={t('global:pagination.previous')}
      />
      <IconButton
        onClick={() => {
          setDate(firstDayNextMonth);
        }}
        icon={<FaArrowRight />}
        label={t('global:pagination.next')}
      />
    </ButtonGroup>
  );
};
