import { MutableRefObject } from 'react';
import { useFormContext } from 'react-hook-form';

import { TicketEvents } from '../../TicketEvents';
import { TicketForm } from '../../types';
import { useCreateParams } from '../../use-create-params';
import { EventValueProvider } from '../../use-form-event-changes';

interface Props {
  isDisabled?: boolean;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}
export const CreateTicketEvents = ({ isDisabled, containerRef }: Props) => {
  const { control, getValues } = useFormContext<TicketForm>();

  return (
    <EventValueProvider control={control} getValues={getValues}>
      <TicketEvents
        ticketID={null}
        {...useCreateParams()}
        viewOnly={isDisabled}
        containerRef={containerRef}
      />
    </EventValueProvider>
  );
};
