import { PropsWithChildren, ReactElement } from 'react';

import { LoadingPage } from '@bq/components/LoadingPage';

import { TicketingContext } from './TicketingContext';
import { useLoadTicketing } from './use-load-ticketing';

export const TicketingProvider = ({
  children,
}: PropsWithChildren): ReactElement => {
  const {
    types,
    groups,
    ownGroupIds,
    permissions,
    priorities,
    reportMethods,
    autoSelectCompanyAsClient,
    states,
    customFields,
    overviews,
    customFormDefinitions,
  } = useLoadTicketing();

  if (
    !types.data ||
    !groups.data ||
    !ownGroupIds.data ||
    !permissions.data ||
    !priorities.data ||
    !reportMethods.data ||
    autoSelectCompanyAsClient.data === undefined ||
    !overviews.data ||
    !states.data ||
    !customFields ||
    !customFormDefinitions
  ) {
    return <LoadingPage />;
  }

  return (
    <TicketingContext.Provider
      value={{
        types: types.data,
        groups: groups.data,
        ownGroupsIds: ownGroupIds.data,
        permissions: permissions.data,
        overviews: overviews.data,
        priorities: priorities.data,
        reportMethods: reportMethods.data,
        autoSelectCompanyAsClient: autoSelectCompanyAsClient.data,
        states: states.data,
        customFormDefinitions,
        allCustomFields: customFields,
      }}
    >
      {children}
    </TicketingContext.Provider>
  );
};
