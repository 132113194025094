import { ReactElement } from 'react';
import { HStack, Text, VStack } from '@chakra-ui/react';

import { StartStopButton } from '@bq/components/StartStopButton';

import { TicketWork } from '../../types';
import { WorkDuration } from '../WorkDuration';
import { WorkTitle } from '../WorkTitle';

interface QuickWorkTimerContentProps
  extends Pick<
    TicketWork,
    'title' | 'inProgress' | 'durationSecs' | 'pendingTimeSince'
  > {
  onStart?: () => void;
  onStop?: () => void;
  isLoading?: boolean;
}

export const QuickWorkTimerContent = ({
  title,

  inProgress,
  durationSecs,
  pendingTimeSince,
  onStart,
  onStop,
  isLoading,
}: QuickWorkTimerContentProps): ReactElement => {
  return (
    <HStack mr="4" spacing="4">
      <VStack spacing="0">
        <Text as="span" fontSize="md">
          <WorkDuration
            pendingTimeSince={pendingTimeSince ?? null}
            durationSecs={durationSecs ?? 0}
          />
        </Text>
        <WorkTitle workTitle={title} fontSize="xs" />
      </VStack>
      <StartStopButton
        isStarted={inProgress ?? false}
        onStart={onStart}
        onStop={onStop}
        colorScheme="blue"
        isDisabled={isLoading}
      />
    </HStack>
  );
};
