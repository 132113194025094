import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { QueryKey } from '@tanstack/react-query';

interface UseInfiniteScrollGuardsOptions {
  /** After how many times will we stop trying to fetch items
   *  So they fill the screen */
  shouldStopTryingAfter: number;
  /**  Ensure that overflow is at least some number
   * Sometimes a very small overflow will behave
   * As if overflow doesn't exist
   * Basically we will trigger on scroll but it will not
   * Fulfill the scroll end condition */
  minimalOverflow: number;
}

const defaultOptions: UseInfiniteScrollGuardsOptions = {
  shouldStopTryingAfter: 6,
  minimalOverflow: 200,
};

interface UseInfiniteScrollGuardsReturn {
  fetchMoreIfNeeded: <T>(
    hasMore: boolean | undefined,
    fetchMore: () => Promise<T>
  ) => void;
}

export const useInfiniteScrollGuard = (
  queryKey: QueryKey,
  containerRef: MutableRefObject<HTMLDivElement | null>,
  opts?: Partial<UseInfiniteScrollGuardsOptions>
): UseInfiniteScrollGuardsReturn => {
  const options = useMemo(() => ({ ...defaultOptions, ...opts }), [opts]);

  const isInitialFetching = useRef<boolean>(true);
  const fetchCount = useRef<number>(0);

  useEffect(() => {
    isInitialFetching.current = true;
    fetchCount.current = 0;
  }, [queryKey]);

  const checkOverflow = useCallback(
    (element: HTMLElement) => {
      const isOverflowing = element.scrollHeight > element.clientHeight;
      const overflowValue = element.scrollHeight - element.clientHeight;
      const isOverflowingMinVal = overflowValue > options.minimalOverflow;

      return isOverflowing && isOverflowingMinVal;
    },
    [options.minimalOverflow]
  );

  const needsMoreItems = useCallback(() => {
    if (containerRef.current) {
      const isOverflowing = checkOverflow(containerRef.current);
      if (!isOverflowing) {
        // If this is the nth time we try to fetch
        // Stop it and throw and throw and error
        if (fetchCount.current === options.shouldStopTryingAfter) {
          throw Error('Tried to fetch too many times to fill screen');
        }

        return true;
      }

      // We stop trying to do initial fetches if we
      // Fetched enough to make it overflow
      isInitialFetching.current = false;

      return false;
    }

    return false;
  }, [checkOverflow, options.shouldStopTryingAfter, containerRef]);

  const onFetched = useCallback(() => {
    fetchCount.current++;
  }, []);

  const fetchMoreIfNeeded = useCallback(
    async <T>(hasMore: boolean | undefined, fetchMore: () => Promise<T>) => {
      setTimeout(() => {
        const fetchMoreIfNeeded = needsMoreItems();

        if (hasMore && fetchMoreIfNeeded) {
          fetchMore();
          onFetched();
        }
      });
    },
    [needsMoreItems, onFetched]
  );

  return { fetchMoreIfNeeded };
};
