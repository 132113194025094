import { ReactElement } from 'react';
import { Flex, FlexProps, useColorModeValue } from '@chakra-ui/react';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { OverviewEditorItem } from './OverviewEditorItem';
import { OverviewDragItem } from './types';

export interface DropAreaProps extends FlexProps {
  item: OverviewDragItem;
}

export const DropArea = ({
  item,
  ...flexProps
}: DropAreaProps): ReactElement => {
  const bg = useColorModeValue('brand.background', 'brand.backgroundDark');
  const colorValue = useColorModeValue('brand.darkGray', 'brand.lightGray');

  const { setNodeRef } = useDroppable({ id: `container:${item.id}` });
  if (!item.hasChildren) {
    throw new Error('Drop areas must have children');
  }

  return (
    <SortableContext
      items={item.children}
      strategy={verticalListSortingStrategy}
    >
      <Flex
        ref={setNodeRef}
        direction="column"
        bg={bg}
        color={colorValue}
        {...flexProps}
      >
        {item.children.map((item) => (
          <OverviewEditorItem key={item.id} {...item} />
        ))}
      </Flex>
    </SortableContext>
  );
};
