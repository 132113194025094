import { useCallback } from 'react';

import { useModal } from '@bq/components/UseModal';

import { ReminderFormFields } from './ReminderFormFields';
import { RemindersFormWrapper } from './RemindersFormWrapper';
import { ReminderWithChange } from './types';

export const useReminderModal = () => {
  const { addModal } = useModal();

  return useCallback(
    (
      reminder: ReminderWithChange | null,
      cb: (data: ReminderWithChange) => void
    ) => {
      addModal<ReminderWithChange>({
        callback: (data) => {
          cb(data);
        },
        children: (
          <RemindersFormWrapper reminder={reminder}>
            <ReminderFormFields />
          </RemindersFormWrapper>
        ),
      });
    },
    [addModal]
  );
};
