import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
} from '@chakra-ui/react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { WithChange } from '@bq/components/SettingsCRUD';

import { TicketShare } from '../../types';
import { UserOrGroupSelect } from '../../UserOrGroupSelect';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selected: WithChange<TicketShare> | null;
  onChange: (newValue: WithChange<TicketShare>) => void;
}

export const ShareModal = ({ isOpen, onClose, selected, onChange }: Props) => {
  const methods = useForm<TicketShare>({
    defaultValues: selected ?? {},
  });
  const { t } = useTranslation();

  const { field } = useController({
    control: methods.control,
    name: 'shareWith',
  });

  return (
    <Portal>
      <Modal isOpen={isOpen} onClose={onClose}>
        <form
          data-ignore-form
          onSubmit={(event) => {
            event.stopPropagation();
            event.preventDefault();
            methods.handleSubmit((data) => {
              if (selected) {
                onChange({ change: 'upd', ...data });
              } else {
                onChange({ change: 'add', ...data });
              }
              onClose();
            })(event);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('Ticketing:shares')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl mb={2}>
                <FormLabel>{t('Ticketing:share_with')}:</FormLabel>
                <UserOrGroupSelect
                  onChange={field.onChange}
                  value={field.value}
                />
              </FormControl>
              <FormControl mb={2}>
                <FormLabel>{t('Ticketing:can_write')}</FormLabel>
                <Checkbox {...methods.register('canWrite')} />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="red"
                mr={3}
                onClick={onClose}
                variant="outline"
              >
                {t('global:close')}
              </Button>
              <Button type="submit" colorScheme="green">
                {t('global:save')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Portal>
  );
};
