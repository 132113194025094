import { FaListAlt } from 'react-icons/fa';

import { CalendarItem } from '@bq/components/Calendar';

import { TicketCalendarEvent } from './types';

export const ticketToCalendarEvent = (
  event: TicketCalendarEvent
): CalendarItem => {
  const sd = new Date(event.startDate);
  const ed = new Date(event.endDate);

  return {
    ID: `${event.type}-${event.id}`,
    title: event.data.title,
    color: event.data.state.color,
    isWholeDayEvent: true,
    startDate: sd,
    endDate: ed,
    additionalProps: { ...event.data },
    type: 'ticket',
    icon: <FaListAlt />,
  };
};
