import { Api } from 'BootQuery/Assets/js/api';

export const deleteDependency = (
  ticketID: number,
  dependencyTicketID: number
) => {
  return Api.delete(
    `/api/ticketing/tickets/${ticketID}/dependencies/${dependencyTicketID}`
  );
};
