import { memo, ReactElement } from 'react';
import { Box } from '@chakra-ui/react';

import { EventCard } from 'app/Modules/Events/Assets/components/EventList/EventCard';
import { Event } from 'app/Modules/Events/Assets/components/types';

interface Props {
  event: Event;
}

const TimelineEvent = memo(
  ({ event }: Props): ReactElement => (
    <Box w="full">
      <EventCard {...event} />
    </Box>
  )
);
TimelineEvent.displayName = 'TimelineEvent';

export { TimelineEvent };
