import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';
import { parseID } from 'BootQuery/Assets/js/utils';

import { postTicket } from '../Api/ticket/postTicket';
import { PostTicket, TicketForm } from '../types';
import { CallbackTicket } from './Dependencies/types';
import { contactForBackend, shareForBackendCreate } from './util';

export const handleCreateSubmit = async (
  data: Partial<TicketForm>,
  params?: GetListingParams,
  parentID?: number | null
): Promise<CallbackTicket> => {
  const {
    contact,
    assignedUser,
    customer,
    group,
    links,
    priority,
    reminders,
    reportMethod,
    reportedAt,
    resolveUntil,
    respondUntil,
    scheduledFor,
    shares,
    state,
    tags,
    ticketType,
    title,
    data: formData,
    events,
    watch,
    subtickets,
  } = data;

  // there is basically 0 chance this will happen here but to be sure
  const parsePriorityID = parseID(priority?.ID);

  let parsedLinks: PostTicket['links'] = [];
  if (links) {
    parsedLinks = links.add.reduce<PostTicket['links']>(
      (all, current) => {
        if (current.otherTicket?.ID) {
          return [...all, { otherTicketID: current.otherTicket.ID }];
        }

        return all;
      },
      [] as PostTicket['links']
    );
  }
  let parsedReminders: PostTicket['reminders'] = [];
  if (reminders) {
    parsedReminders = reminders.add.map((reminder) => ({
      description: reminder.description,
      newStateID: reminder.newState?.ID,
      timestamp: reminder.timestamp,
      title: reminder.title,
    }));
  }
  let parsedShares: PostTicket['shares'] = [];
  if (shares) {
    parsedShares = shares.add
      .filter((share) => !!share.shareWith)
      .map(shareForBackendCreate);
  }

  const submitData: Partial<PostTicket> = {
    assignedUserID: assignedUser?.ID,
    customer: customer ? { ID: customer.ID, type: customer?.type } : undefined,
    contact: contactForBackend(contact),
    groupID: group?.ID,
    priorityID: parsePriorityID !== -1 ? parsePriorityID : undefined,
    reportedAt,
    resolveUntil,
    respondUntil,
    scheduledFor,
    reportMethodID: reportMethod?.ID,
    tags,
    title,
    typeID: ticketType?.ID,
    stateID: state?.ID,
    data: formData,
    events: events ?? [],
    links: parsedLinks,
    reminders: parsedReminders,
    shares: parsedShares,
    fromMailAddressID: null,
    watch,
    dependencies: subtickets?.map((subticket) => subticket.ID),
  };
  const apiData = await postTicket<Promise<CallbackTicket>>(submitData, params);

  return { ...apiData, parentID, dependencies: subtickets };
};
