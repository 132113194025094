import { PropsWithChildren, useMemo } from 'react';
import { ModalContent, Tabs } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';

import {
  getDependencies,
  handleCreateSubmit,
  handleEditSubmit,
  makeTicketFormDefaults,
  Ticket,
  TicketForm,
  TicketMutationProvider,
  TicketType,
  useTicketingContext,
  useTicketMutationContext,
} from 'app/Modules/Ticketing/Assets';

import { useFormState } from '../form-state';
import { loadValues, useFormDefinition } from '../FormEditor';
import { TabControlProvider } from '../TabControl/TabControlContext';
import { useTabControl } from '../TabControl/useTabControl';
import { useTicketDefaults } from '../use-ticket-defaults';
import { useUniqueID } from '../use-unique-id';
import { LoadingModal } from '../UseModal/LoadingModal';
import { useModalInstance } from '../UseModal/ModalInstanceContext';
import { TicketFormWrapperProps } from './types';

export const TicketFormWrapper = (
  props: PropsWithChildren<TicketFormWrapperProps>
) => {
  const [formState, setFormState] = useFormState();

  const [tabManual, tabIndex, setTab] = useTabControl({
    tabs: ['details', 'events', 'dependencies', 'works'],
  });

  const [saveID, resetSaveID] = useUniqueID();
  const formID = useMemo(() => {
    return props.mode === 'create'
      ? `create-ticket-${saveID}`
      : `edit-ticket-${props.ID}`;
  }, [props, saveID]);
  const { types, priorities } = useTicketingContext();

  const ticketType = useMemo(() => {
    if (typeof props.ticketType === 'string') {
      return types.find((type) => type.slug === props.ticketType) as TicketType;
    }

    return props.ticketType;
  }, [props.ticketType, types]);

  if (!ticketType) {
    throw Error('Unknown ticket type');
  }

  const defaults = useTicketDefaults({
    ticketType,
    createParams: props?.createParams,
    priorities,
  });

  const customFields = useFormDefinition(
    `TicketTypes.${ticketType.ID}`,
    'use',
    {
      enabled: !!ticketType,
    }
  );

  const { data: deps, isFetching: isFetchingDeps } = useQuery({
    queryKey: [
      'Ticketing.LoadDefaultDependencies',
      props.mode === 'edit' ? props.ID : -1,
    ],
    queryFn: () => {
      if (props.mode === 'create') {
        return undefined;
      }

      return getDependencies(props.ID);
    },
    enabled: props.mode === 'edit',
  });
  const ticket = useMemo(() => {
    if (customFields && props.data && ticketType) {
      return {
        ...defaults,
        ...props.data,
        data: loadValues(customFields, props.data.data ?? {}),
        subtickets: deps,
      };
    }

    return undefined;
  }, [customFields, props.data, ticketType, defaults, deps]);

  if (isFetchingDeps || !ticket) {
    return <LoadingModal />;
  }

  return (
    <TicketMutationProvider
      viewOnly={false}
      customFields={customFields}
      defaults={ticket ?? {}}
      formState={formState}
      setFormState={setFormState}
      onSubmit={() => {
        /** We handle this differently here, but this is a mandatory prop for this ctx */
        resetSaveID();
      }}
      formId={formID}
      saveID={saveID}
      {...(props.mode === 'edit'
        ? { ticketID: props.ID, mode: 'edit' }
        : { mode: 'create' })}
    >
      <TabControlProvider tab={tabManual} tabIndex={tabIndex} setTab={setTab}>
        <Tabs
          display="flex"
          flexDirection="column"
          h="full"
          w="full"
          index={tabIndex}
          onChange={setTab}
          colorScheme="brand"
          isLazy
          size={{ base: 'lg', lg: 'md' }}
        >
          {ticket && (
            <TicketFormSuppresor
              ticket={ticket}
              formID={formID}
              mode={props.mode}
            >
              {props.children}
            </TicketFormSuppresor>
          )}
        </Tabs>
      </TabControlProvider>
    </TicketMutationProvider>
  );
};

// The reason this exists is because react hook form is notorius at clearing things after
// It has been initially set, we make sure that we have 100% ticket ready before
// Loading it into react hook form
const TicketFormSuppresor = ({
  ticket,
  formID,
  children,
  mode,
}: PropsWithChildren<{
  ticket: Partial<Ticket & { parentID: number | null }>;
  formID: string;
  mode: 'create' | 'edit';
}>) => {
  const methods = useForm<TicketForm>({
    mode: 'onBlur',
    defaultValues: makeTicketFormDefaults(ticket ?? {}),
  });

  const { closeWithCallback } = useModalInstance();
  const { setFormState } = useTicketMutationContext();
  const { mutateAsync } = useMutation({
    mutationFn: (data: TicketForm) => {
      if (mode === 'create') {
        return handleCreateSubmit(data, {}, ticket.parentID);
      }

      return handleEditSubmit(data);
    },
  });

  return (
    <FormProvider {...methods}>
      <ModalContent
        data-ignore-form
        id={formID}
        overflow="hidden"
        as="form"
        onSubmit={methods.handleSubmit(
          async (data) => {
            setFormState('saving');
            const newData = await mutateAsync(data);
            setFormState('saved');
            closeWithCallback({ ...data, ...newData });
          },
          (e) => {
            console.log(e);
            setFormState('error');
          }
        )}
      >
        {children}
      </ModalContent>
    </FormProvider>
  );
};
