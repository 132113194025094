import { PopulateMonthCalendarParams } from '@bq/components/Calendar/types';
import { getEventBoundaryDates } from '@bq/components/Calendar/Utils/get-event-boundary-dates';

import {
  handleMultipleDayEventDifferentWeek,
  handleMultipleDayEventSameWeek,
  handleOneDayEvent,
} from './item-handlers';
import { populateCalendar } from './populate-calendar';
import { getItemFlags, getRawGridPositions } from './shared-utils';
import { sortItemsInWeeks } from './sort-items-in-weeks';
import { MonthItemsParams, WeeksMap } from './types';

export const getMonthItems = (params: PopulateMonthCalendarParams) => {
  const { gridMap, items, range, weekStartsOn, maxItems } = params;

  const splitByWeeks = items.reduce<WeeksMap>((all, currentItem) => {
    const dates = getEventBoundaryDates(
      currentItem.startDate,
      currentItem.endDate,
      range
    );

    const gridRawPositions = getRawGridPositions(
      gridMap,
      dates.startDate.formatted,
      dates.endDate.formatted
    );

    // This will be given to every one of our functions
    // They need pretty much all of those params to
    // do their funcionalities

    const params: MonthItemsParams = {
      dates,
      weekStartsOn,
      item: currentItem,
      weeksMap: all,
    };

    const { didWeekChange, isSingleDayEvent } = getItemFlags(
      dates,
      gridRawPositions
    );

    if (!isSingleDayEvent) {
      if (didWeekChange) {
        return handleMultipleDayEventDifferentWeek(params);
      }

      return handleMultipleDayEventSameWeek(params);
    }

    return handleOneDayEvent(params);
  }, {});

  const sortedItems = sortItemsInWeeks(splitByWeeks);

  const result = populateCalendar({
    gridMap,
    maxItems,
    range,
    weekStartsOn,
    sortedItems,
  });

  return result;
};
