import { Button, HStack, useColorModeValue, VStack } from '@chakra-ui/react';
import { ImArrowRight2 } from 'react-icons/im';

import { useTicketModal } from '@bq/components/TicketModal';
import { useModalInstance } from '@bq/components/UseModal';

import { useTicketingContext } from '../../../TicketingContext';

export const TicketCalendarCreateModalBodyContent = () => {
  const { createTicketModal } = useTicketModal();
  const { closeWithCallback } = useModalInstance();
  const { types } = useTicketingContext();

  const nameColor = useColorModeValue('black', 'white');
  const bodyColor = useColorModeValue('#F5F5F5', '#393939');
  const hoverColor = useColorModeValue('gray.200', 'brand.mediumGray');

  return (
    <VStack spacing={3} alignItems="left" px={8} py={2} flex="1 1 auto">
      {types.map((type) => (
        <HStack
          _hover={{ bg: hoverColor }}
          boxShadow=" rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
          borderRadius="5px"
          p={5}
          h={7}
          bg={bodyColor}
          key={type.ID}
        >
          <Button
            bg="transparent"
            _hover={{ textDecoration: 'none', color: nameColor }}
            color={nameColor}
            display="block"
            width="full"
            onClick={() => {
              createTicketModal({
                ticketType: type,
                closeCallback: (data) => {
                  closeWithCallback(data);
                },
                defaults: {},
              });
            }}
          >
            {type.name}
          </Button>
          <ImArrowRight2 />
        </HStack>
      ))}
    </VStack>
  );
};
