import { ReactElement, useCallback, useMemo } from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '@bq/components/SelectWrappers';

import { TicketForm } from '../../types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';
import { ReportMethodSelect } from '../ReportMethod/ReportMethodSelect';

export const ReportMethodField = (): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { viewOnly } = useTicketMutationContext();
  const { control } = useFormContext<TicketForm>();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({ control, name: 'reportMethod' });

  const reportMethodValue = useMemo(() => {
    if (!value) {
      return null;
    }

    return {
      value: value.ID,
      label: value.name,
    };
  }, [value]);

  const handleChange = useCallback(
    (newVal: SelectOption | null) => {
      if (newVal) {
        onChange({ ID: newVal.value, name: newVal.label });
      } else {
        onChange(null);
      }
    },
    [onChange]
  );

  return (
    <FormControl isInvalid={fieldState.invalid}>
      <FormLabel fontWeight="bold">
        {t('Ticketing:form.report_method')}:
      </FormLabel>
      <ReportMethodSelect
        isDisabled={viewOnly}
        value={reportMethodValue}
        onChange={handleChange}
      />
      {fieldState.error && (
        <FormErrorMessage>
          {fieldState.error.message || fieldState.error.type}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
