import { useMemo } from 'react';

import { CreateParams, EventData } from './types';
import { useCreateParams } from './use-create-params';

export function usePreloadedEvents(params?: CreateParams): EventData[] {
  const routerParams = useCreateParams();
  const { fromMailThreadID, fromCallID, fromChatID } = params ?? routerParams;

  return useMemo((): EventData[] => {
    if (fromMailThreadID) {
      return [{ type: 'mail', mailID: fromMailThreadID }];
    }
    if (fromCallID) {
      return [{ type: 'call', callID: fromCallID }];
    }
    if (fromChatID) {
      return [{ type: 'chat', chatID: fromChatID }];
    }

    return [];
  }, [fromMailThreadID, fromCallID, fromChatID]);
}
