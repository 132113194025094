import { memo, useMemo } from 'react';
import { Button, Tooltip } from '@chakra-ui/react';

import { OBJ } from 'BootQuery/Assets/js/globalTypes';

import { BulkAction, useTableSelection } from '../TableSelection';

interface Props<T extends OBJ> {
  action: BulkAction<T>;
}
const BulkActionTextButton = <T extends OBJ>({ action }: Props<T>) => {
  const tableSelectionCtx = useTableSelection<T>();
  const parsedSelection = useMemo(() => {
    return action.parseSelection !== undefined
      ? action.parseSelection(tableSelectionCtx.selection)
      : tableSelectionCtx.selection;
  }, [action, tableSelectionCtx.selection]);

  const allowed = useMemo(
    () => action.isAllowed(parsedSelection),
    [action, parsedSelection]
  );

  return (
    <Tooltip
      key={action.name}
      hasArrow
      label={
        !allowed && action.disabledTooltip ? action.disabledTooltip : undefined
      }
      shouldWrapChildren
    >
      <Button
        onClick={async () => {
          if (allowed) {
            await action.handler({
              ...tableSelectionCtx,
              selection: parsedSelection,
              rawSelection: tableSelectionCtx.selection,
            });
          }
        }}
        isDisabled={!allowed}
        leftIcon={action.icon}
        size="sm"
      >
        {action.name}
      </Button>
    </Tooltip>
  );
};

const BulkActionTextButtonMemo = memo(
  BulkActionTextButton
) as typeof BulkActionTextButton;

export { BulkActionTextButtonMemo as BulkActionTextButton };
