import tinycolor from 'tinycolor2';

/**
 * Function to make colors a bit softer, for headers, dynamic elements and such
 * @param color - color we want to make softer
 * @param lightnessIncrease - by how much we want to increase the lightness
 * @param saturationDecrease - by how much we want to desaturate the color
 * @returns pastelized color, as hex string
 */
export const pastelizeColor = (
  color: string,
  lightnessIncrease = 8,
  saturationDecrease = 8
): string => {
  const tc = tinycolor(color);
  const hsl = tc.toHsl();

  const saturationThreshold = 30;
  const lightnessThreshold = 80;

  if (hsl.s < saturationThreshold / 100 && hsl.l > lightnessThreshold / 100) {
    return tc.toHexString();
  }

  const pastelColor = tc
    .lighten(lightnessIncrease)
    .desaturate(saturationDecrease)
    .toHexString();

  return pastelColor;
};
