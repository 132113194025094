import {
  useIsMutating,
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import {
  createTicketWork,
  deleteTicketWork,
  getTicketWorks,
  startTicketWork,
  stopTicketWork,
  updateTicketWork,
} from '../Api';
import { TicketWork } from '../types';
import {
  useAddQueryWork,
  useRemoveQueryWork,
  useUpdateQueryWork,
} from './query-utils';
import { CreateWorkRequest } from './types';
import { getElapsedSeconds } from './util';

interface UpdateWorkProps {
  workID: number;
  changes: Partial<CreateWorkRequest>;
}

interface UseTicketWorksReslt {
  works: UseQueryResult<TicketWork[]>;
  createWork: UseMutationResult<TicketWork, unknown, CreateWorkRequest>;
  updateWork: UseMutationResult<TicketWork, unknown, UpdateWorkProps>;
  startWork: UseMutationResult<TicketWork, unknown, number, unknown>;
  stopWork: UseMutationResult<TicketWork, unknown, number, unknown>;
  removeWork: UseMutationResult<number, unknown, number, unknown>;
  isMutating: boolean;
}

export function useTicketWorks(ticketID: number): UseTicketWorksReslt {
  const queryKey = ['Ticketing.ticketWorks', ticketID];

  const updateQueryWork = useUpdateQueryWork(ticketID);
  const addQueryWork = useAddQueryWork(ticketID);
  const removeQueryWork = useRemoveQueryWork(ticketID);

  const works = useQuery({
    queryKey,
    queryFn: () => getTicketWorks(ticketID),
  });

  const updateWork = useMutation({
    mutationKey: queryKey,
    mutationFn: ({ workID, changes }: UpdateWorkProps) => {
      return updateTicketWork(ticketID, workID, changes);
    },
    onSuccess: (work) => updateQueryWork(work.ID, work),
  });

  const createWork = useMutation({
    mutationKey: queryKey,
    mutationFn: async (work: CreateWorkRequest) => {
      return createTicketWork(ticketID, work);
    },
    onSuccess: addQueryWork,
  });

  const startWork = useMutation({
    mutationKey: queryKey,
    mutationFn: (workID: number) => startTicketWork(ticketID, workID),
    onSuccess: (work) => updateQueryWork(work.ID, work),
  });

  const stopWork = useMutation({
    mutationKey: queryKey,
    mutationFn: (workID: number) => stopTicketWork(ticketID, workID),
    onSuccess: (work) => updateQueryWork(work.ID, work),
    onMutate: (workID) => {
      // Oportunistically stop the timer so it doesn't count while stopping,
      // updates the stored duration to current value.
      // This reduces skipping counter back after server confirms stop.
      updateQueryWork(workID, (work) => {
        const newDurationSecs = Math.floor(getElapsedSeconds(work));

        return {
          ...work,
          inProgress: false,
          pendingTimeSince: null,
          durationSecs: newDurationSecs,
        };
      });
    },
  });

  const removeWork = useMutation({
    mutationKey: queryKey,
    mutationFn: async (workID: number) => {
      await deleteTicketWork(ticketID, workID);

      return workID;
    },
    onSuccess: removeQueryWork,
  });

  const isMutating = useIsMutating({ mutationKey: queryKey }) > 0;

  return {
    works,
    createWork,
    updateWork,
    startWork,
    stopWork,
    removeWork,
    isMutating,
  };
}
