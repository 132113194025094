import { areIntervalsOverlapping, format, isWithinInterval } from 'date-fns';

import {
  CalendarDateRange,
  EventBoundaryDates,
} from '@bq/components/Calendar/types';
import { parseToDate } from 'BootQuery/Assets/js/dateUtils';

export const getEventBoundaryDates = (
  startDate: string | Date,
  endDate: string | Date,
  range: CalendarDateRange
): EventBoundaryDates => {
  let startF = range.start;
  let endF = range.end;

  // We need both a raw date, for calculating purposes
  // and we need a formatted date, as key to gridMap
  // and calendar map

  const parseStartDate = parseToDate(startDate);
  const parseEndDate = parseToDate(endDate);

  // We are sure the start date will be inside range, but
  // the end date might be "leaking" out of the range (month + padding)
  // To ensure we don't try to add items outside of our range
  // We check if the end date is within our range

  const isInRangeEnd = isWithinInterval(parseEndDate, {
    start: range.start,
    end: range.end,
  });
  const isInRangeStart = isWithinInterval(parseStartDate, {
    start: range.start,
    end: range.end,
  });

  const isOverlapping = areIntervalsOverlapping(
    { start: range.start, end: range.end },
    { start: parseStartDate, end: parseEndDate },
    { inclusive: true }
  );

  if (isInRangeStart) {
    startF = parseStartDate;
  }
  if (isInRangeEnd) {
    endF = parseEndDate;
  }

  return {
    overflowsRight: !isInRangeEnd,
    overflowsLeft: !isInRangeStart,
    isOverlapping,
    startDate: {
      formatted: format(startF, 'yyyy-MM-dd'),
      rawDate: startF,
    },
    endDate: {
      formatted: format(endF, 'yyyy-MM-dd'),
      rawDate: endF,
    },
  };
};
