import { ReactElement, useCallback } from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FaEyeSlash, FaGripVertical } from 'react-icons/fa6';

import { DeleteButton } from '@bq/components/DeleteButton';

import { DropArea } from './DropArea';
import { useOverviewEditorContext } from './OverviewEditorContext';
import { OverviewDragItem } from './types';

export const OverviewEditorItem = (props: OverviewDragItem): ReactElement => {
  if (props.content.type === 'root') {
    throw new Error('Tried to render root item as OverviewEditorItem');
  }

  const bg = useColorModeValue('brand.background', 'brand.backgroundDark');
  const colorValue = useColorModeValue('brand.darkGray', 'brand.lightGray');

  const { id, content } = props;

  const { selectedId, setSelectedId, removeItem } = useOverviewEditorContext();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    data: props,
  });

  const select = useCallback(() => {
    setSelectedId(id.toString());
  }, [setSelectedId, id]);

  const deleteYourself = useCallback(() => {
    removeItem(id);
  }, [removeItem, id]);

  return (
    <Box
      ref={setNodeRef}
      opacity={isDragging ? 0.7 : 1.0}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <Flex
        alignItems="center"
        onClick={select}
        px="2"
        py="1"
        bg={bg}
        color={selectedId === id ? 'blue.500' : colorValue}
        fontWeight={selectedId === id ? 'bold' : 'normal'}
        borderBottom="1px solid"
        borderBottomColor="gray.200"
      >
        <Flex alignItems="center" flex="1 0 auto">
          <Box cursor="move" {...attributes} {...listeners}>
            <FaGripVertical />
          </Box>
          <Text as={content.visible ? 'span' : 'del'} noOfLines={1}>
            {content.title}
          </Text>
          {content.visible || (
            <>
              &nbsp;
              <FaEyeSlash />
            </>
          )}
        </Flex>
        <DeleteButton
          onClick={deleteYourself}
          flex="0 1 auto"
          variant="outline"
          size="sm"
        />
      </Flex>
      {props.hasChildren && (
        <DropArea
          item={props}
          direction="column"
          ml="1"
          pl="2"
          pb="3"
          borderLeft="1px solid"
          borderLeftColor="gray.300"
        />
      )}
    </Box>
  );
};
