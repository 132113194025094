import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useTicketingContext } from './TicketingContext';
import { TicketForm, TicketGroupFull } from './types';

export function useSelectedGroup(): TicketGroupFull | null {
  const { groups } = useTicketingContext();
  const { control } = useFormContext<TicketForm>();
  const selectedGroup = useWatch({
    control,
    name: 'group',
  });

  const groupID = selectedGroup?.ID ?? null;

  return useMemo(() => {
    return groups.find((g) => g.ID === groupID) ?? null;
  }, [groups, groupID]);
}
