import { ReactElement, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import {
  Calendar as CalendarC,
  useCalendarHandlers,
} from '@bq/components/Calendar';
import { useCalendarController } from '@bq/components/Calendar/Controller/CalendarController';
import { PageWrapper } from 'BootQuery/Assets/components/PageWrapper';
import { DateType, formatDate } from 'BootQuery/Assets/js/dateUtils';
import { useAppTitle } from 'BootQuery/Assets/js/use-app-title';

import { listCalendarEvents } from '../components/api';
import { useEventTypes } from '../components/event-types';

export const Calendar = (): ReactElement => {
  const { t } = useTranslation('Events');
  useAppTitle(t('Events:calendar_title'));

  const defaultDate = useMemo(() => new Date(), []);

  const props = useCalendarController({
    startDate: defaultDate,
    startingView: 'month',
  });

  const { start, end } = props.dateRange;
  const { data: calendarEvents } = useQuery({
    queryKey: ['Calendar', 'events', start, end],
    queryFn: () => {
      return listCalendarEvents({
        limit: 300,
        filters: {
          before: formatDate(end, DateType.DateISO),
          after: formatDate(start, DateType.DateISO),
        },
      });
    },
  });

  const eventTypes = useEventTypes();
  const { eventsToItems, itemClickHandler, dateClickHandler } =
    useCalendarHandlers(eventTypes);

  const items = useMemo(
    () => eventsToItems(calendarEvents ?? []),
    [calendarEvents, eventsToItems]
  );

  return (
    <PageWrapper p={0} m={0} h="fullNoNavbar" overflow="scroll">
      <CalendarC
        ID="Test-Ticketing-Calendar"
        {...props}
        items={items}
        itemClickHandler={(e) => {
          itemClickHandler(e);
        }}
        dateCellClickHandler={(e) => {
          dateClickHandler(e);
        }}
      />
    </PageWrapper>
  );
};
