import { useCallback } from 'react';

import { useModal } from '@bq/components/UseModal';
import { EventCalendar } from 'app/Modules/Events/Assets/components';

import { DateCellClickEvent } from '../types';
import { CalendarEventProviderMap } from './use-event-type-providers';

export const useCalendarDateCellActionHookInvoker = (
  calendarEventProviderMap: CalendarEventProviderMap
) => {
  const { addModal } = useModal();

  // TODO: POPOVER INVOKER

  const findDefinition = useCallback(
    (type: keyof CalendarEventProviderMap) => {
      return calendarEventProviderMap?.[type] ?? null;
    },
    [calendarEventProviderMap]
  );
  const actionReducer = useCallback(
    (definition: EventCalendar, action: DateCellClickEvent) => {
      switch (definition.dateCellAction.type) {
        case 'modal':
          addModal({
            callback: definition.dateCellAction.callback,
            children: <definition.dateCellAction.content {...action} />,
            componentProps: definition.dateCellAction.componentProps,
          });
          break;
        case 'function':
          definition.dateCellAction.handler(action);
          break;
        default:
          throw Error('Unknown action type for calendar action');
      }
    },
    [addModal]
  );

  return useCallback(
    (action: DateCellClickEvent, type: keyof CalendarEventProviderMap) => {
      const definition = findDefinition(type);
      if (!definition) {
        throw Error('Definition for the calendar action was not found');
      }
      actionReducer(definition, action);
    },
    [actionReducer, findDefinition]
  );
};
