import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { ModalContent } from '@chakra-ui/react';
import {
  DeepPartial,
  FieldErrors,
  FormProvider,
  useForm,
} from 'react-hook-form';

import {
  durationFromSeconds,
  durationToSeconds,
} from '@bq/components/DurationInput';
import { useFormState } from '@bq/components/form-state';
import { WithChange } from '@bq/components/SettingsCRUD';
import { useModalInstance } from '@bq/components/UseModal/ModalInstanceContext';

import { CreateWorkRequest } from '../types';
import { useTicketWorks } from '../use-ticket-works';
import { IWorkFormCtx, IWorkFormFields, WorkFormData } from './types';

const WorkFormCtx = createContext<IWorkFormCtx | null>(null);

export const WorkFormWrapper = (props: PropsWithChildren<WorkFormData>) => {
  const { createWork, updateWork } = useTicketWorks(props.ticketID);
  const methods = useForm<WithChange<IWorkFormFields>>({
    defaultValues: makeDefaults(props),
  });

  const {
    preventClose: { setState },
    closeWithNoCallback,
  } = useModalInstance();
  const isDirty = useMemo(
    () => methods.formState.isDirty,
    [methods.formState.isDirty]
  );
  const [formState, setFormState] = useFormState();
  useEffect(() => {
    setState(isDirty);
  }, [isDirty, setState]);

  const onSubmit = useCallback(
    async (data: WithChange<IWorkFormFields>) => {
      setFormState('saving');

      if (props.mode === 'create') {
        await createWork.mutateAsync(formToApi(data));
      }
      if (props.mode === 'edit') {
        const changes: Partial<CreateWorkRequest> = formToApi(data);

        // Leave out durationSecs if not changed
        // otherwise the backend would wrongly log that we manually set timer.
        if (!methods.formState.dirtyFields.duration) {
          delete changes.durationSecs;
        }

        await updateWork.mutateAsync({
          workID: props.data.ID,
          changes,
        });
      }

      setFormState('saved');
      closeWithNoCallback(true);
    },
    [setFormState, props, methods, createWork, updateWork, closeWithNoCallback]
  );

  const onError = useCallback(
    (err: FieldErrors<WithChange<IWorkFormFields>>) => {
      console.error('Form errors: ', err);

      setFormState('error');
    },
    [setFormState]
  );

  return (
    <WorkFormCtx.Provider value={{ formState, setFormState, mode: props.mode }}>
      <FormProvider {...methods}>
        <ModalContent
          as="form"
          data-ignore-form-save
          onSubmit={methods.handleSubmit(onSubmit, onError)}
        >
          {props.children}
        </ModalContent>
      </FormProvider>
    </WorkFormCtx.Provider>
  );
};

export const useWorkFormWrapper = () => {
  const ctx = useContext(WorkFormCtx);
  if (!ctx) {
    throw Error('Missing Work Form Wrapper');
  }

  return ctx;
};

function makeDefaults(
  work: WorkFormData
): DeepPartial<WithChange<IWorkFormFields>> {
  if (work.mode === 'edit') {
    return {
      ID: work.data.ID,
      title: work.data.title ?? '',
      workAt: work.data.workAt,
      duration: durationFromSeconds(work.data.durationSecs ?? 0),
      data: work.data.data,
    };
  }

  return {};
}

function formToApi(data: IWorkFormFields): CreateWorkRequest {
  return {
    title: data.title,
    workAt: data.workAt ? data.workAt.toISOString() : undefined,
    durationSecs: durationToSeconds(data.duration || '00:00:00'),
    data: data.data,
  };
}
