import { useMemo } from 'react';

import {
  EventCalendar,
  EventProviderMap,
} from 'app/Modules/Events/Assets/components';

export type CalendarEventProviderMap = Record<string, EventCalendar>;

export const useCalendarEventProviderMap = (
  eventProviderMap: EventProviderMap
) => {
  const calendarEventProviderMap = Object.entries(
    eventProviderMap
  ).reduce<CalendarEventProviderMap>((all, [key, provider]) => {
    if (provider.calendar) {
      return { ...all, [key]: provider.calendar };
    }

    return all;
  }, {});
  const numberOfItems = Object.entries(calendarEventProviderMap).length;

  return useMemo(
    () => ({
      calendarEventProviderMap,
      numberOfItems,
    }),
    [calendarEventProviderMap, numberOfItems]
  );
};
