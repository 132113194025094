import { Fragment, memo, ReactElement, useCallback } from 'react';
import { Box, Flex, useColorModeValue, VStack } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';

import { useScrollEvents } from '@bq/components/scroll-events';
import { useIsMobile } from '@bq/components/use-is-mobile';
import { useEventList } from 'app/Modules/Events/Assets/components/EventList/EventListContext';
import { Event } from 'app/Modules/Events/Assets/components/types';

import { TimelineEvent } from './TimelineEvent';

interface Props {
  id?: string;
  events: Event[];
}

const Timeline = memo(({ id, events }: Props): ReactElement => {
  const left = useColorModeValue('-2.65rem', '-2.58rem');
  const { eventQuery } = useEventList();
  const isMobile = useIsMobile();
  const { fetchMore, hasMore } = eventQuery;

  useScrollEvents(
    'scrollBottom',
    useCallback(() => {
      if (hasMore) {
        fetchMore();
      }
    }, [hasMore, fetchMore])
  );

  return (
    <VStack
      {...(!isMobile && borderProps)}
      id={id}
      w="full"
      alignItems="start"
      spacing={4}
      h="full"
    >
      {events.map((event) => (
        <Fragment key={`${event.id}-${event.type}`}>
          {isMobile ? (
            <TimelineEvent event={event} />
          ) : (
            <Flex key={`${event.type}-${event.id}`} w="full">
              <Box flexGrow={0} position="relative">
                <Box
                  position="absolute"
                  left={left}
                  top="0.25em"
                  color="gray.200"
                >
                  <FaCircle />
                </Box>
              </Box>
              <Box px="2" pb="4" w="full">
                <TimelineEvent event={event} />
              </Box>
            </Flex>
          )}
        </Fragment>
      ))}
    </VStack>
  );
});
Timeline.displayName = 'Timeline';

export { Timeline };

const borderProps = {
  pl: 8,
  borderLeftWidth: 'thick',
  borderLeftColor: 'gray.200',
};
