import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getTitleForEvent } from './Api/title-for-event';
import { CreateParams } from './types';
import { useCreateParams } from './use-create-params';

export const useDefaultTitle = (
  createParams?: CreateParams
): UseQueryResult<string | null> => {
  const routerParams = useCreateParams();
  const { fromMailThreadID, fromCallID } = createParams ?? routerParams;

  return useQuery({
    queryKey: ['ticketDefaultTitle', fromMailThreadID, fromCallID],
    queryFn: () => {
      if (fromMailThreadID) {
        return getTitleForEvent('mail', fromMailThreadID);
      }
      if (fromCallID) {
        return getTitleForEvent('call', fromCallID);
      }

      return null;
    },
  });
};
