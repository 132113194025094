import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash-es';

import { TicketWithDependencies } from '../../TicketTable/Dependencies';
import { treeQueryMutations } from './tree-query-mutations';
import { TicketDependecyMutation, TicketDependencyQueryKey } from './types';

interface UpdateDataParams {
  mutation: TicketDependecyMutation;
  currentParentID?: number | null;
  searchingParent?: number | null;
}
export const useTicketQueryMutation = (queryKey?: TicketDependencyQueryKey) => {
  const queryClient = useQueryClient();
  const updateData = useCallback(
    ({ mutation, currentParentID, searchingParent }: UpdateDataParams) => {
      if (queryKey) {
        queryClient.setQueryData(
          queryKey,
          (prev: TicketWithDependencies[] | undefined) => {
            if (!prev) {
              return prev;
            }
            const omitFields = omit(mutation.data, ['parentID']);

            return treeQueryMutations({
              deps: prev,
              mutation: { action: mutation.action, data: omitFields },
              searchingParent,
              currentParentID,
            });
          }
        );
      }
    },
    [queryClient, queryKey]
  );

  return updateData;
};

export const useTicketFormMutation = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...data: any) => void,
  defaults?: TicketWithDependencies[]
) => {
  const [dependencies, setDependencies] = useState<TicketWithDependencies[]>(
    defaults ?? []
  );
  useEffect(() => {
    if (onChange) {
      onChange(dependencies);
    }
  }, [dependencies, onChange]);

  const updateData = useCallback(
    ({ mutation, currentParentID, searchingParent }: UpdateDataParams) => {
      setDependencies((prev: TicketWithDependencies[]) => {
        if (!prev) {
          return prev;
        }
        const correctData = omit(mutation.data, ['parentID']);

        return treeQueryMutations({
          deps: prev,
          mutation: { action: mutation.action, data: correctData },
          searchingParent,
          currentParentID,
        });
      });
    },
    []
  );

  return updateData;
};
