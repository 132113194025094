import { useMemo } from 'react';

import { useTicketingContext } from '../../TicketingContext';
import { CreateParams, TicketReportMethod } from '../../types';
import { useCreateParams } from '../../use-create-params';

export const useReportMethod = (
  createParams?: CreateParams
): TicketReportMethod | undefined => {
  const routerParams = useCreateParams();
  const params = createParams ?? routerParams;
  const { reportMethods } = useTicketingContext();
  const getType = useMemo(() => getReportMethodType(params), [params]);
  if (getType !== undefined) {
    return reportMethods?.find((item) => item.name === getType);
  }

  return undefined;
};

const getReportMethodType = (params: CreateParams) => {
  if (params.fromCallID) {
    return 'phone';
  }
  if (params.fromMailThreadID) {
    return 'email';
  }
  if (params.fromChatID) {
    return 'chat';
  }

  return undefined;
};
