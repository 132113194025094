import { ReactElement } from 'react';
import { Divider, SimpleGrid, VStack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TicketForm } from '../../types';
import { TicketDependers } from '../Dependers/TicketDependers';
import { TicketingDescriptiveDate } from '../TicketingDescriptiveDate';
import { TicketTypeDisplay } from '../TicketTypeDisplay';
import { AgentField } from './AgentField';
import { ContactField } from './ContactField';
import { CreatedByDisplay } from './CreatedByDisplay';
import { CustomerField } from './CustomerField';
import { GroupField } from './GroupField';
import { LinksField } from './LinksField';
import { PriorityField } from './PriorityField';
import { RemindersField } from './RemindersField';
import { ReportedAtField } from './ReportedAtField';
import { ReportMethodField } from './ReportMethodField';
import { ResolveUntilField } from './ResolveUntilField';
import { RespondUntilField } from './RespondUntilField';
import { ScheduledForField } from './ScheduledForField';
import { SharesField } from './SharesField';
import { StateField } from './StateField';
import { TagsField } from './TagsField';
import { TitleField } from './TitleField';

export interface Props {
  /**
   * If true, layout all fields vertically, don't group
   * state, priority, group and agent
   */
  flatten?: boolean;
  /** Leave out the state field */
  omitState?: boolean;
}

export const EssentialFields = ({
  flatten = false,
  omitState = false,
}: Props): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { control } = useFormContext<TicketForm>();

  const [ticketType, updatedAt, createdAt, dependers] = useWatch({
    control,
    name: ['ticketType', 'updatedAt', 'createdAt', 'dependers'],
  });

  return (
    <VStack px="4" py="4" w="full" alignItems="stretch" spacing="3">
      <TitleField />

      <SimpleGrid columns={flatten ? 1 : { base: 1, sm: 2 }} gridGap={4}>
        {!omitState && <StateField />}
        <PriorityField />
        <GroupField />
        <AgentField />
      </SimpleGrid>

      <Divider display={{ base: 'none', sm: 'block' }} my={4} />

      <CustomerField />
      <ContactField />
      <ScheduledForField />
      <ReportMethodField />
      <ReportedAtField />
      <RespondUntilField />
      <ResolveUntilField />
      <TagsField />

      <Divider my={4} />

      <RemindersField />
      <SharesField />
      <LinksField />

      {(dependers && dependers.length) > 0 && (
        <>
          <Divider my={4} />
          <TicketDependers dependers={dependers} />
        </>
      )}

      <Divider my={4} />

      <TicketTypeDisplay
        ticketType={ticketType}
        label={t('Ticketing:form.ticket_type')}
      />
      <TicketingDescriptiveDate
        date={updatedAt}
        label={t('global:item_crud_dates.updated_at')}
      />
      <TicketingDescriptiveDate
        date={createdAt}
        label={t('global:item_crud_dates.created_at')}
      />
      <CreatedByDisplay />
    </VStack>
  );
};
