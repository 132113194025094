import { eachDayOfInterval, format } from 'date-fns';

import { WeekKey } from './types';

export const generateWeekDatesWithAvaliableSpaces = (
  weekKey: WeekKey,
  maxItems: number
): Record<string, number[]> => {
  const [start, end] = weekKey.split('/');
  const weekStartDate = new Date(start);
  const weekEndDate = new Date(end);

  // Generate an object directly using reduce on eachDayOfInterval
  return eachDayOfInterval({ start: weekStartDate, end: weekEndDate }).reduce(
    (acc, currentDate) => {
      const formattedDate = format(currentDate, 'yyyy-MM-dd');

      // Create an array of numbers with a length of maxItems - 1
      const numberArray = Array.from(
        { length: maxItems - 1 },
        (_, index) => index + 1
      ); // Fill with sequential indexes

      return { ...acc, [formattedDate]: numberArray };
    },
    {}
  );
};
