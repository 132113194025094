import { useMemo, useRef, useState } from 'react';
import { Box, FormControl, HStack, Link, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaLink } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { useToggle } from 'react-use';

import { ConditionalWrapper } from '@bq/components/ConditionalWrapper';
import { SettingsMethods, WithChange } from '@bq/components/SettingsCRUD';

import { TicketSelect } from '../../TicketSelect';
import { Ticket } from '../../types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';
import { getVal } from '../utils';
import { TicketLinkButtons } from './TicketLinkButtons';
import { FormTicketLink } from './TicketLinks';

interface Props {
  link: WithChange<FormTicketLink>;
  methods: SettingsMethods<FormTicketLink>;
  error?: string;
  isDisabled?: boolean;
  excludeKnown?: number[];
}
export const TicketLink = ({
  link,
  methods,
  error,
  isDisabled,
  excludeKnown,
}: Props) => {
  const [selected, setSelected] = useState<FormTicketLink | null>(link ?? null);
  const isNew = useRef(typeof link.ID === 'string');
  const [edit, toggleEdit] = useToggle(
    selected?.otherTicket === null || selected?.otherTicket === undefined
  );
  const { t } = useTranslation('Ticketing');
  const tmProps = useTicketMutationContext();
  const excludedTicketIds = useMemo(
    () => [
      ...(tmProps.mode === 'edit' ? [tmProps.ticketID] : []),
      ...(excludeKnown ?? []),
    ],
    [excludeKnown, tmProps]
  );

  return (
    <FormControl as={VStack} alignItems="center" isInvalid={!!error}>
      <HStack justifyContent="space-between" width="100%">
        {edit ? (
          <Box flexGrow="1">
            <TicketSelect
              isInvalid={!!error}
              excludedTicketIds={excludedTicketIds}
              onChange={(newValue) => {
                if (newValue && isNew) {
                  setSelected({
                    ...link,
                    otherTicket: newValue,
                  });
                  isNew.current = false;
                  methods.upd({
                    ...link,
                    otherTicket: newValue,
                  });
                  toggleEdit();
                } else {
                  setSelected(null);
                }
              }}
              value={getVal(selected)}
            />
          </Box>
        ) : (
          <ConditionalWrapper
            shouldWrap={!!link.otherTicket && !link.change}
            render={(children) => {
              const typecast = link.otherTicket as Pick<Ticket, 'ID' | 'title'>;

              return (
                <Link
                  as={RouterLink}
                  to={`/ticketing/edit/${typecast.ID}?linkSecret=${link.secret}`}
                  display="flex"
                  overflow="hidden"
                >
                  {children}
                </Link>
              );
            }}
          >
            <HStack overflow="hidden">
              <Box>
                <FaLink />
              </Box>
              <Box
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {link?.otherTicket?.title ?? t('Ticketing:no_selected_link')}
              </Box>
            </HStack>
          </ConditionalWrapper>
        )}

        <Box flex="0 1 auto">
          {!isDisabled && (
            <TicketLinkButtons
              isNew={isNew}
              selected={selected}
              toggleEdit={toggleEdit}
              edit={edit}
              methods={methods}
              link={link}
            />
          )}
        </Box>
      </HStack>
    </FormControl>
  );
};
