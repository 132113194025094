import {
  Box,
  ButtonGroup,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { UseControllerReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaBell, FaEdit, FaPlus, FaTrash, FaUndo } from 'react-icons/fa';

import { ChangeBadge } from '@bq/components/ChangeBadge';
import { useCrud } from '@bq/components/SettingsCRUD/use-crud';
import { useIsMobile } from '@bq/components/use-is-mobile';
import { uniqid } from 'app/assets/js/tsutil';
import { DateType, formatDate } from 'BootQuery/Assets/js/dateUtils';

import { TicketForm } from '../../types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';
import { useSyncCrudField } from '../use-sync-crud-field';
import { FormTicketReminder } from './types';
import { useReminderModal } from './use-reminder-modal';

export const Reminders = ({
  field,
  isDisabled,
}: UseControllerReturn<TicketForm, 'reminders'> & { isDisabled?: boolean }) => {
  const { t } = useTranslation('Ticketing');
  const isMobile = useIsMobile();
  const { saveID } = useTicketMutationContext();
  const [items, modify, modifications, clearModifications] =
    useCrud<FormTicketReminder>({
      items: field.value.defaults,
    });

  useSyncCrudField({ saveID, field, modifications, clearModifications });
  const addModal = useReminderModal();

  return (
    <FormControl mb={isMobile ? 0 : 2}>
      <HStack>
        <FormLabel w="full">{t('Ticketing:reminders')}</FormLabel>
        {!isDisabled && (
          <IconButton
            aria-label={t('global:add')}
            size="sm"
            variant="ghost"
            colorScheme="green"
            icon={isMobile ? <FaPlus size={25} /> : <FaPlus />}
            onClick={() => {
              addModal(null, (data) => {
                modify.add({ ...data, ID: uniqid() });
              });
            }}
          />
        )}
      </HStack>
      <VStack w="full" spacing={0.5}>
        {items.map((reminder, idx) => {
          return (
            <VStack
              key={idx}
              alignItems="center"
              w="full"
              p={2}
              spacing={0}
              shadow="base"
            >
              <VStack opacity={reminder.done ? '0.5' : 1} w="full" spacing={0}>
                <HStack w="full">
                  <Box w="fit-content">
                    <FaBell />
                  </Box>
                  <Box w="full" fontWeight="bold" m={0} p={0}>
                    {reminder.title}
                  </Box>
                  <ChangeBadge type={reminder.change} />
                </HStack>
                <HStack w="full" p={0}>
                  <Box fontWeight="bold" mr={2}>{`${t(
                    'Ticketing:remindersForm.date'
                  )}:`}</Box>
                  <Box>
                    {formatDate(reminder.timestamp ?? null, DateType.DateTime)}
                  </Box>
                </HStack>
                <HStack w="full" spacing={0}>
                  <Box fontWeight="bold" mr={2}>{`${t(
                    'Ticketing:remindersForm.description'
                  )}:`}</Box>
                  <Box>{reminder.description}</Box>
                </HStack>
                <HStack w="full" spacing={0}>
                  <Box fontWeight="bold" mr={2}>{`${t(
                    'Ticketing:remindersForm.new_state'
                  )}:`}</Box>
                  {reminder.newState?.name && (
                    <Box
                      bg={reminder.newState.color}
                      py={0.25}
                      px={2}
                      rounded="sm"
                    >
                      {reminder.newState.name}
                    </Box>
                  )}
                </HStack>
              </VStack>

              {!reminder.done && !isDisabled && (
                <HStack w="full">
                  <ButtonGroup isAttached variant="ghost" marginLeft="auto">
                    {reminder.change && (
                      <IconButton
                        aria-label={t('global:undo_changes')}
                        size="sm"
                        icon={<FaUndo />}
                        onClick={() => {
                          modify.undo(reminder.ID);
                        }}
                      />
                    )}
                    <IconButton
                      aria-label={t('global:edit')}
                      size="sm"
                      icon={<FaEdit />}
                      onClick={() => {
                        addModal(reminder, (data) => {
                          modify.upd(data);
                        });
                      }}
                    />
                    <IconButton
                      aria-label={t('global:delete')}
                      size="sm"
                      colorScheme="red"
                      icon={<FaTrash />}
                      onClick={() => {
                        modify.del(reminder.ID);
                      }}
                    />
                  </ButtonGroup>
                </HStack>
              )}
            </VStack>
          );
        })}
      </VStack>
    </FormControl>
  );
};
