import { ReactElement, useCallback, useState } from 'react';

import { formatDuration } from 'app/assets/js/format-duration';
import { useDelayedInterval } from 'app/Modules/Telephony/Assets/components/use-delayed-interval';

import { TimeProps } from './types';

export const WorkDuration = ({
  pendingTimeSince,
  durationSecs,
}: TimeProps): ReactElement => {
  return pendingTimeSince ? (
    <RunningTime
      durationSecs={durationSecs}
      pendingTimeSince={pendingTimeSince}
    />
  ) : (
    <StoppedTime
      durationSecs={durationSecs}
      pendingTimeSince={pendingTimeSince ?? null}
    />
  );
};

interface RunningTimeProps extends TimeProps {
  pendingTimeSince: Date;
}

export const RunningTime = (currentWork: RunningTimeProps): ReactElement => {
  const [elapsed, setElapsed] = useState(getElapsedSeconds(currentWork));

  const updateElapsed = useCallback(() => {
    setElapsed(getElapsedSeconds(currentWork));
  }, [currentWork]);

  const delay = getDelayToNextWholeSecond(currentWork.pendingTimeSince);
  useDelayedInterval(updateElapsed, 1000, delay);

  return <>{formatDuration(elapsed, true)}</>;
};

export const StoppedTime = (work: TimeProps): ReactElement => {
  const elapsedSeconds = getElapsedSeconds(work);

  return <>{formatDuration(elapsedSeconds, true)}</>;
};

function getDelayToNextWholeSecond(time: Date): number {
  return Math.ceil(time.getTime() / 1000) * 1000 - time.getTime();
}

function getElapsedSeconds(currentWork: TimeProps | null): number {
  if (!currentWork) {
    return 0;
  }

  const currentElapsedMS = currentWork.pendingTimeSince
    ? Date.now() - currentWork.pendingTimeSince.getTime()
    : 0;

  return currentWork.durationSecs + currentElapsedMS / 1000;
}
