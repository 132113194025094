import { eachDayOfInterval, format } from 'date-fns';

import { CalendarDateRange, Day } from '@bq/components/Calendar/types';

export const generateFormattedWeekForDateRange = (
  range: CalendarDateRange
): Day[] => {
  return eachDayOfInterval({ start: range.start, end: range.end }).reduce<
    Day[]
  >((all, current) => {
    return [...all, { key: format(current, 'yyyy-MM-dd'), date: current }];
  }, []);
};
