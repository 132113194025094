import { ReactElement, useState } from 'react';
import {
  Button,
  Grid,
  GridItem,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { FaFolderPlus, FaPlus } from 'react-icons/fa';

import { DndContainersProvider } from '@bq/components/DndContainers';
import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { FilterTypes } from '../../FilterBar/types';
import { OverviewList } from '../types';
import { DropArea } from './DropArea';
import { OverviewEditorContext } from './OverviewEditorContext';
import { OverviewEditorDragOverlay } from './OverviewEditorDragOverlay';
import { OverviewSettings } from './OverviewSettings';
import { OverviewDragItem, RootItem } from './types';
import { useOverviewMutations } from './use-overview-mutations';
import { dragItemsToItems, itemToDragItem } from './util';

export interface OverviewEditorProps {
  filterTypes: FilterTypes;
  value: OverviewList;
  onChange: (value: OverviewList) => void;
  withGroupVisibility?: boolean;
}

export const OverviewEditor = ({
  filterTypes,
  value: inValue,
  onChange,
  withGroupVisibility = false,
}: OverviewEditorProps): ReactElement => {
  const { t } = useTranslation();

  const dropAreaBg = useColorModeValue('gray.200', 'brand.mediumGray');
  const editingPersonal = false;

  const [items, setItems] = useState<OverviewDragItem[]>(() => {
    return initialDragItems(inValue);
  });

  const root = items[0] as RootItem;

  const [selectedId, setSelectedId] = useState<string | null>(
    root.children[0]?.id?.toString() ?? null
  );

  useChangeEffect(
    inValue,
    () => {
      setItems(initialDragItems(inValue));
    },
    [setItems],
    isEqual
  );

  useChangeEffect(
    root.children,
    () => {
      onChange(dragItemsToItems(root.children));
    },
    [],
    isEqual
  );

  const { addOverview, addGroup, modifyItem, removeItem, duplicateItem } =
    useOverviewMutations({ setItems });

  return (
    <DndContainersProvider items={items} onChange={setItems}>
      <OverviewEditorContext.Provider
        value={{
          selectedId,
          setSelectedId,
          dragItems: items,
          removeItem,
          modifyItem,
          duplicateItem,
          editingPersonal,
          withGroupVisibility,
        }}
      >
        <Grid templateColumns="1fr 3fr" maxH="full" overflow="hidden">
          <GridItem
            display="flex"
            flexDirection="column"
            height="full"
            overflow="hidden"
          >
            <DropArea
              item={root}
              bg={dropAreaBg}
              paddingBottom="12"
              shadow="inner"
              overflowY="auto"
            />
            <HStack
              display="flex"
              justifyContent="flex-end"
              borderColor="gray.200"
              borderTopWidth="1px"
              borderStyle="solid"
              flex="0 1 auto"
              px="2"
              py="2"
            >
              <Button
                onClick={addGroup}
                display="flex"
                alignItems="center"
                colorScheme="green"
                size="sm"
              >
                <FaFolderPlus />
                {t('global:overviews.add_group')}
              </Button>
              <Button
                onClick={addOverview}
                display="flex"
                alignItems="center"
                colorScheme="green"
                size="sm"
              >
                <FaPlus />
                {t('global:overviews.add')}
              </Button>
            </HStack>
          </GridItem>
          <GridItem height="full" overflow="hidden">
            <OverviewSettings filterTypes={filterTypes} />
          </GridItem>
        </Grid>
        <OverviewEditorDragOverlay />
      </OverviewEditorContext.Provider>
    </DndContainersProvider>
  );
};

function initialDragItems(value: OverviewList): OverviewDragItem[] {
  return [
    {
      id: 'root',
      hasChildren: true,
      children: value.map(itemToDragItem),
      content: { type: 'root' },
    },
  ];
}
