import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { TicketForm, TicketGroupFull } from './types';
import { useSelectedGroup } from './use-selected-group';

export function useFormatSubject() {
  const { control } = useFormContext<TicketForm>();
  const ticketID = useWatch({ control, name: 'ID' });
  const group = useSelectedGroup();

  return useCallback(
    (subject: string) => {
      return formatSubject(subject, ticketID, group);
    },
    [ticketID, group]
  );
}

export function formatSubject(
  subject: string,
  ticketID: number | null,
  group: TicketGroupFull | null
): string {
  if (!ticketID || !group) {
    return subject.trim();
  }

  const addStyle = group.mailSubjectFormat || 'none';

  const idText = `[Ticket#${ticketID}]`;
  if (subject.trim().length === 0) {
    // On new mail
    if (addStyle !== 'none') {
      subject = idText; // On new mail
    }
  } else if (!subject.includes(idText)) {
    if (addStyle === 'idLeft') {
      subject = `${idText} ${subject}`;
    } else if (addStyle === 'idRight') {
      subject = `${subject} ${idText}`;
    }
  }

  return subject;
}
