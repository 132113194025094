import { getTags } from '../../Api';
import { TicketTag } from '../../types';
import { FilterOption } from './TagSelect';

export const loadOptions = async (search: string) => {
  const {
    data: { data },
  } = await getTags({
    filters: {
      'tag:contains:ci': search,
    },
    fields: ['ID', 'tag'],
  });

  return ticketTagsToFilterVal(data);
};

const ticketTagsToFilterVal = (tags: TicketTag[]): FilterOption[] => {
  return tags.map((tag) => ({
    label: tag.tag,
    value: tag.tag,
  }));
};
