import { ReactElement } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

import { useAutoupdateWorks } from '../use-autoupdate-works';
import { ExistingWorkQuickTimer } from './ExistingWorkQuickTimer';
import { PlaceholderWorkQuickTimer } from './PlaceholderWorkQuickTimer';
import { useCurrentWork } from './use-current-work';

interface Props {
  ticketID: number;
}

export const QuickWorkTimer = ({ ticketID }: Props): ReactElement => {
  // Called here because it's the easiest way to ensure it's called exactly once
  // This is kinda ugly so,
  // TODO: find a better place for this
  useAutoupdateWorks(ticketID);
  const { currentWork, isLoaded } = useCurrentWork({ ticketID });

  if (!isLoaded) {
    return <ScaleLoader height="1rem" />;
  }

  if (!currentWork) {
    return <PlaceholderWorkQuickTimer ticketID={ticketID} />;
  }

  return <ExistingWorkQuickTimer ticketID={ticketID} workID={currentWork.ID} />;
};
