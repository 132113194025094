import { MutableRefObject } from 'react';
import { ButtonGroup, HStack, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaTimes, FaUndo } from 'react-icons/fa';

import { ChangeBadge } from '@bq/components/ChangeBadge';
import { SettingsMethods, WithChange } from '@bq/components/SettingsCRUD';

import { TicketLinkMenu } from './TicketLinkMenu';
import { FormTicketLink } from './TicketLinks';

interface Props {
  link: WithChange<FormTicketLink>;
  edit: boolean;
  selected: FormTicketLink | null;
  methods: SettingsMethods<FormTicketLink>;
  isNew: MutableRefObject<boolean>;
  toggleEdit: () => void;
}

export const TicketLinkButtons = ({
  link,
  edit,
  selected,
  methods,
  isNew,
  toggleEdit,
}: Props) => {
  const { t } = useTranslation();

  return (
    <HStack w="full" justifyContent="end">
      <ChangeBadge type={link.change} />
      <ButtonGroup isAttached>
        {link.change && (
          <IconButton
            aria-label={t('global:undo_changes')}
            size="sm"
            variant="ghost"
            icon={<FaUndo />}
            onClick={() => {
              if (selected) {
                methods.undo(selected.ID);
              }
            }}
          />
        )}

        {edit && (
          <IconButton
            aria-label={t('global:cancel')}
            size="sm"
            variant="ghost"
            colorScheme="red"
            icon={<FaTimes />}
            onClick={() => {
              if (selected?.otherTicket && !isNew.current) {
                toggleEdit();
              } else {
                methods.del(link.ID);
              }
            }}
          />
        )}
        {!edit && (
          <TicketLinkMenu
            toggleEdit={toggleEdit}
            link={link}
            methods={methods}
          />
        )}
      </ButtonGroup>
    </HStack>
  );
};
