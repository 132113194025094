import { ReactElement } from 'react';
import { ModalBody, ModalContent, ModalHeader } from '@chakra-ui/react';

import { DateCellClickEvent } from '@bq/components/Calendar';
import { TypeSelectHeader } from '@bq/components/TicketModal/TypeSelect/TypeSelectHeader';

import { TicketingProvider } from '../../../TicketingContext';
import { TicketCalendarCreateModalBodyContent } from './TicketCalendarCreateModalBodyContent';

export const TicketCalendarCreateModalContent = (
  _props: DateCellClickEvent
): ReactElement => {
  return (
    <TicketingProvider>
      <ModalContent>
        <ModalHeader p={0}>
          <TypeSelectHeader />
        </ModalHeader>
        <ModalBody>
          <TicketCalendarCreateModalBodyContent />
        </ModalBody>
      </ModalContent>
    </TicketingProvider>
  );
};
