import { TimeProps } from './types';

export function getElapsedSeconds(currentWork: TimeProps | null): number {
  if (!currentWork) {
    return 0;
  }

  const currentElapsedMS = currentWork.pendingTimeSince
    ? Date.now() - currentWork.pendingTimeSince.getTime()
    : 0;

  return currentWork.durationSecs + currentElapsedMS / 1000;
}
