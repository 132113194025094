import { DeepPartial } from 'react-hook-form';

import { ModificationsWithDefaults } from '@bq/components/SettingsCRUD/types';

import {
  Ticket,
  TicketContact,
  TicketForm,
  TicketLink,
  TicketReminder,
  TicketReportMethod,
  TicketShare,
} from '../../types';
import { translateReportMethodName } from '../../utils';
import { SelectCustomOption } from '../Contact/type';
import { shareFromBackend } from '../util';

type FormDefaults = Omit<
  DeepPartial<TicketForm>,
  'reminders' | 'shares' | 'links'
> & {
  reminders: ModificationsWithDefaults<TicketReminder>;
  shares: ModificationsWithDefaults<TicketShare>;
  links: ModificationsWithDefaults<TicketLink>;
};
export const makeDefaults = (form: Partial<Ticket>): FormDefaults => ({
  ...form,
  reminders: {
    add: [],
    del: [],
    upd: {},
    defaults: form.reminders ?? [],
  } as ModificationsWithDefaults<TicketReminder>,
  shares: {
    add: [],
    del: [],
    upd: {},
    defaults: form.shares?.map(shareFromBackend) ?? [],
  } as ModificationsWithDefaults<TicketShare>,
  links: {
    add: [],
    del: [],
    upd: {},
    defaults: form.links ?? [],
  } as ModificationsWithDefaults<TicketLink>,
  contact: makeContactDefault(form.contact?.contact),
  reportMethod: makeReportMethodDefault(form.reportMethod),
});

function makeReportMethodDefault(
  method: TicketReportMethod | null | undefined
): TicketReportMethod | null | undefined {
  if (!method) {
    return method;
  }

  if (method.isSystem) {
    return {
      ...method,
      name: translateReportMethodName(method.name),
    };
  }

  return method;
}

function makeContactDefault(
  contact: TicketContact | undefined
): SelectCustomOption | undefined {
  if (!contact) {
    return undefined;
  }

  if (contact.type === 'manual') {
    return {
      type: 'manual',
      value: contact.manualContact,
      label: contact.manualContact,
    };
  }

  return {
    type: contact.type,
    value: contact.ID,
    label: contact.type === 'person' ? contact.fullName : contact.name,
  };
}
