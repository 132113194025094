import { useCallback } from 'react';

import { useSocketEvents } from 'BootQuery/Assets/js/use-socket-events';

import { ticketWorkSchema } from '../Api';
import { ticketingEventListener } from '../ticketing-event-listener';
import { TicketWork } from '../types';

type WorkHandler = (data: TicketWork) => void;
type RawWorkHandler = (data: unknown) => void;

/**
 * Call a callback when a work on `ticketID` is created.
 */
export function useOnTicketWorkCreated(ticketID: number, handler: WorkHandler) {
  return useSocketEvents(
    ticketingEventListener(),
    `ticketing/tickets/${ticketID}/workCreated`,
    useRawWorkHandler(handler)
  );
}

/**
 * Call a callback when a work on `ticketID` is updated.
 * If `workID` is supplied, only listen for updates on that specific work
 */
export function useOnTicketWorkUpdated(
  ticketID: number,
  handler: WorkHandler,
  workID?: number
) {
  const key = workID
    ? `ticketing/tickets/${ticketID}/workUpdated/${workID}`
    : `ticketing/tickets/${ticketID}/workUpdated`;

  return useSocketEvents(
    ticketingEventListener(),
    key,
    useRawWorkHandler(handler)
  );
}

/**
 * Call a callback when a work on `ticketID` is deleted.
 * If `workID` is supplied, only listen for deletes of that specific work
 */
export function useOnTicketWorkDeleted(
  ticketID: number,
  handler: WorkHandler,
  workID?: number
) {
  const key = workID
    ? `ticketing/tickets/${ticketID}/workDeleted/${workID}`
    : `ticketing/tickets/${ticketID}/workDeleted`;

  return useSocketEvents(
    ticketingEventListener(),
    key,
    useRawWorkHandler(handler)
  );
}

/**
 * Call a callback when a work on **any** ticket is created
 */
export function useOnWorkCreated(handler: WorkHandler) {
  return useSocketEvents(
    ticketingEventListener(),
    'ticketing/works/workCreated',
    useRawWorkHandler(handler)
  );
}

/**
 * Call a callback when a work on **any** ticket is updated.
 * If `workID` is supplied, only listen for updates on that specific work
 */
export function useOnWorkUpdated(handler: WorkHandler, workID?: number) {
  const key = workID
    ? `ticketing/works/workUpdated/${workID}`
    : 'ticketing/works/workUpdated';

  return useSocketEvents(
    ticketingEventListener(),
    key,
    useRawWorkHandler(handler)
  );
}

/**
 * Call a callback when a work on **any** ticket is deleted.
 * If `workID` is supplied, only listen for deletes of that specific work
 */
export function useOnWorkDeleted(handler: WorkHandler, workID?: number) {
  const key = workID
    ? `ticketing/works/workDeleted/${workID}`
    : 'ticketing/works/workDeleted';

  return useSocketEvents(
    ticketingEventListener(),
    key,
    useRawWorkHandler(handler)
  );
}

const useRawWorkHandler = (handler: WorkHandler): RawWorkHandler => {
  return useCallback(
    (data: unknown) => {
      handler(ticketWorkSchema.parse(data));
    },
    [handler]
  );
};
