import { ReactElement } from 'react';
import { Box, ButtonGroup, HStack } from '@chakra-ui/react';
import { FaUser, FaUsers } from 'react-icons/fa';

import { ChangeBadge } from '@bq/components/ChangeBadge';
import { WithChange } from '@bq/components/SettingsCRUD';

import { TicketShare } from '../../types';
import { ShareChangeIndicator } from './ShareChangeIndicator';
import { ShareEditMenu } from './ShareEditMenu';

interface Props {
  share: WithChange<TicketShare>;
  isDisabled?: boolean;
  onShareEdit: (shareID: string) => void;
  onShareDelete: (shareID: string) => void;
  onShareUndo: (shareID: string) => void;
}

export const ShareRow = ({
  share,
  isDisabled,
  onShareEdit,
  onShareDelete,
  onShareUndo,
}: Props): ReactElement => {
  return (
    <HStack w="full" flexWrap="nowrap">
      <Box flex="0 1 auto">
        {share.shareWith.type === 'group' ? <FaUsers /> : <FaUser />}
      </Box>
      <Box
        flex="1 1 auto"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        {share.shareWith.label}
      </Box>
      {share.change && <ChangeBadge type={share.change} />}

      {!isDisabled && (
        <ButtonGroup isAttached ml="auto">
          {share.change && (
            <ShareChangeIndicator shareID={share.ID} onUndo={onShareUndo} />
          )}
          <ShareEditMenu
            shareID={share.ID}
            onEdit={onShareEdit}
            onDelete={onShareDelete}
          />
        </ButtonGroup>
      )}
    </HStack>
  );
};
