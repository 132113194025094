import { Fragment, ReactElement } from 'react';
import { Button } from '@chakra-ui/react';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Card, CardBody } from '@bq/components/Card';
import {
  useHeadingProps,
  useListingSettings,
  useSplitColumnsFeatures,
} from '@bq/components/ListingSettings';
import { ListWrapper } from '@bq/components/ListWrapper';
import { FieldValue } from 'BootQuery/Assets/components/FormEditor';
import { ListHeading } from 'BootQuery/Assets/components/ListHeading';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import {
  CardBulkActionMenu,
  TableSelectionProvider,
} from 'BootQuery/Assets/components/Table';

import { PhonebookListContext } from '../PhonebookListContext';
import { Permissions, PersonApiData } from '../types';
import { useSelectedOverview } from '../use-selected-overview';
import { Actions } from './Actions';
import { columns, personCardFeatures } from './columns';
import { personFilterTypes } from './filter-types';
import { getCardPeople } from './get-people';
import { usePersonBulkActions } from './person-list-bulk-actions';
import { PersonCard } from './PersonCard';

interface Props {
  customFields?: FieldValue[];
  permissions: Permissions;
}

export const MobilePersonCardList = ({
  customFields,
  permissions,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const selectedOverview = useSelectedOverview('people');

  const listingSettings = useListingSettings<PersonApiData>({
    listingName: 'Phonebook.PersonList',
    viewName: 'mobile',
    filterTypes: personFilterTypes,
    columns: columns(permissions),
    otherFilters: selectedOverview?.filters ?? [],
    customFields,
  });

  const { filters, limit, columnsToShow } = listingSettings;

  const { columns: customColumns, features } = useSplitColumnsFeatures(
    columnsToShow,
    personCardFeatures
  );

  const { data, refetch, fetchNextPage, hasNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: ['Phonebook.PersonList.mobile', limit, filters],
      queryFn: async ({ pageParam = 1 }) => {
        const data = await getCardPeople({ page: pageParam, filters, limit });
        const maxPages = Math.ceil((data?.meta?.count ?? 1) / limit);
        const shouldFetchMore = pageParam < maxPages;

        return { ...data, page: pageParam, shouldFetchMore };
      },
      placeholderData: keepPreviousData,
      getNextPageParam: (lastPage) => {
        if (lastPage.shouldFetchMore) {
          return lastPage.page + 1;
        }

        return undefined;
      },
      initialPageParam: 1,
    });

  const bulkActions = usePersonBulkActions(refetch);

  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    noPagination: true,
    count: null,
  });

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <PhonebookListContext.Provider value={{ refetch }}>
      <TableSelectionProvider<PersonApiData>
        page={null}
        idColumn="ID"
        selectAll={async () => {
          const { data } = await getCardPeople({
            fields: [
              'ID',
              'firstName',
              'lastName',
              [
                'phoneNumbers',
                ['phoneNumber.phoneNumberE164', 'numberType.ID'],
              ],
            ],
            page: 1,
            limit: 'null',
            filters,
          });

          return data;
        }}
        selectPage={() => {
          return data.pages.map((page) => page.data.map((item) => item)).flat();
        }}
      >
        <ListWrapper spacing="5" px="3" py="3">
          <Card>
            <CardBody>
              <ListHeading
                {...headingProps}
                menuProps={undefined}
                Actions={<Actions />}
              />
            </CardBody>
          </Card>

          {data.pages.map((page) => (
            <Fragment key={page.page}>
              {page.data.map((person) => (
                <PersonCard
                  key={person.ID}
                  person={person}
                  features={features}
                  customColumns={customColumns}
                />
              ))}
            </Fragment>
          ))}
          {hasNextPage && (
            <Button
              w="full"
              isLoading={isLoading}
              onClick={() => {
                fetchNextPage();
              }}
              colorScheme="blue"
            >
              {t('global:load_more')}
            </Button>
          )}
        </ListWrapper>
        <CardBulkActionMenu<PersonApiData> actionGroups={bulkActions} />
      </TableSelectionProvider>
    </PhonebookListContext.Provider>
  );
};
