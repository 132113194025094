import { ReactElement, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

import { IconButton } from '@bq/components/IconButton';

import { TicketForm } from '../../types';

interface WatchStarProps {
  watchTicket: boolean | undefined;
  toggleWatchTicket: () => void;
}

export const TicketWatchStar = ({
  watchTicket,
  toggleWatchTicket,
}: WatchStarProps): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { control } = useFormContext<TicketForm>();
  const assignee = useWatch({ control, name: 'assignedUser' });

  const isDisabled = useMemo(
    () => assignee?.ID === window.Bootstrap.bootquery.session.userID,
    [assignee?.ID]
  );

  return (
    <IconButton
      isDisabled={isDisabled}
      variant="ghost"
      size="md"
      label={isDisabled ? t('Ticketing:own_ticket') : t('Ticketing:follow')}
      icon={
        watchTicket ? (
          <AiFillStar color="gold" size="25" />
        ) : (
          <AiOutlineStar size="25" />
        )
      }
      onClick={toggleWatchTicket}
    />
  );
};
