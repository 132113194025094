import { ReactElement, useCallback } from 'react';

import { FormActions } from '@bq/components/FormActions';
import { useModalInstance } from '@bq/components/UseModal';

import { useWorkFormWrapper } from './WorkFormWrapper';

export const WorkFormActions = (): ReactElement => {
  const { formState } = useWorkFormWrapper();
  const { closeWithNoCallback } = useModalInstance();

  const close = useCallback(() => {
    closeWithNoCallback();
  }, [closeWithNoCallback]);

  return <FormActions state={formState} onCancel={close} />;
};
