import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AsyncSelect } from 'chakra-react-select';

import { getPriority } from '../../Api';
import { PriorityOption } from './types';
import { loadOptions } from './utils';

interface Props {
  onChange: (newValue: PriorityOption | null) => void;
  value: PriorityOption | number | null;
  isDisabled?: boolean;
}

export const PrioritySelect = ({ onChange, value, isDisabled }: Props) => {
  const valueIsNumber = typeof value === 'number';
  const { data: loadedData } = useQuery({
    queryKey: ['prioritySelectLoadOne', value],
    queryFn: async (): Promise<PriorityOption> => {
      if (!valueIsNumber) {
        throw Error('Invalid state, query should not be enabled');
      }
      const { data } = await getPriority(value);

      return {
        label: data.name,
        value: data.ID,
        responseInterval: data.responseInterval,
        resolutionInterval: data.resolutionInterval,
      };
    },
    enabled: valueIsNumber,
  });
  const parsedValue = useMemo(() => {
    return valueIsNumber ? loadedData : value;
  }, [loadedData, value, valueIsNumber]);

  return (
    <AsyncSelect<PriorityOption>
      isDisabled={isDisabled}
      useBasicStyles
      size={{ base: 'lg', md: 'sm', lg: 'sm' }}
      value={parsedValue}
      cacheOptions
      defaultOptions
      onChange={(newVal) => {
        if (parsedValue?.value !== newVal?.value) {
          onChange(newVal);
        }
      }}
      loadOptions={loadOptions}
      selectedOptionColorScheme="brand"
    />
  );
};
