import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';

import { SettingsMethods, WithChange } from '@bq/components/SettingsCRUD';

import { FormTicketLink } from './TicketLinks';

interface Props {
  toggleEdit: () => void;
  methods: SettingsMethods<FormTicketLink>;
  link: WithChange<FormTicketLink>;
}

export const TicketLinkMenu = ({ toggleEdit, methods, link }: Props) => {
  const { t } = useTranslation();

  return (
    <Menu>
      <MenuButton
        size="sm"
        as={IconButton}
        icon={<GiHamburgerMenu />}
        variant="ghost"
      />
      <MenuList>
        <MenuItem icon={<FaEdit />} onClick={toggleEdit}>
          {t('global:edit')}
        </MenuItem>
        <MenuItem
          icon={<FaTrash />}
          onClick={() => {
            methods.del(link.ID);
          }}
        >
          {t('global:delete')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
