import { MouseEvent } from 'react';
import mitt from 'mitt';

import { createUseMitt } from 'BootQuery/Assets/js/use-mitt';

import { DateCellClickEvent, ItemClickEvent } from '../types';

interface RawDateCellClickEvent
  extends Omit<DateCellClickEvent, 'topOffsetPercent'> {
  rawEvent: MouseEvent<HTMLDivElement>;
}
interface ShowMoreClickEvent {
  calendarID: string;
  targetDate: Date;
}
export type CalendarEvents = {
  dateCellClick: RawDateCellClickEvent;
  itemClick: ItemClickEvent;
  showMoreClick: ShowMoreClickEvent;
  // optional TODO: Register future events here
  // like item add, delete, edit
};

export const calendarEvents = mitt<CalendarEvents>();

export const useCalendarEvents = createUseMitt(calendarEvents);

export const handleDateCellClick = (event: RawDateCellClickEvent) => {
  calendarEvents.emit('dateCellClick', event);
};

export const handleItemClick = (event: ItemClickEvent) => {
  calendarEvents.emit('itemClick', event);
};

export const handleShowMoreClick = (event: ShowMoreClickEvent) => {
  calendarEvents.emit('showMoreClick', event);
};
