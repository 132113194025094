import { Box } from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';

import { useCalendarCtx } from '../CalendarCtx';

export const CalendarSidebar = () => {
  const {
    currentDate: [currentDate, setCurrentDate],
    locale,
  } = useCalendarCtx();

  return (
    <Box>
      <ReactDatePicker
        inline
        locale={locale}
        selected={currentDate}
        onChange={(date) => {
          if (date) {
            setCurrentDate(date);
          }
        }}
      />
    </Box>
  );
};
