import { Box, Circle, HStack, SimpleGrid, VStack } from '@chakra-ui/react';
import { chakraComponents, GroupBase, OptionProps } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

import { TicketSelectOption as TicketSelectOptionI } from './types';

export const TicketSelectOption = (
  props: OptionProps<TicketSelectOptionI, false, GroupBase<TicketSelectOptionI>>
) => {
  const { t } = useTranslation('Ticketing');
  const {
    data: { assignedUser, customer, group, state, label },
  } = props;

  return (
    <chakraComponents.Option {...props}>
      <VStack w="full" spacing={0.5}>
        <HStack w="full">
          <Circle bg={state.color} size="14px"></Circle>
          <Box fontWeight="bold" flex="1 1 auto">
            {label}
          </Box>
        </HStack>
        <SimpleGrid w="full" columns={3} opacity={0.7}>
          <HStack spacing={0.5}>
            <Box fontWeight="bold">{t('Ticketing:form.agent')}:</Box>
            {assignedUser ? (
              <Box>
                {assignedUser.person?.fullName ?? assignedUser.username}
              </Box>
            ) : (
              <Box>-</Box>
            )}
          </HStack>

          <HStack spacing={0.5}>
            <Box fontWeight="bold">{t('Ticketing:form.group')}:</Box>
            <Box>{group.name}</Box>
          </HStack>

          <HStack spacing={0.5}>
            <Box fontWeight="bold">{t('Ticketing:form.customer')}:</Box>
            {customer ? (
              <Box>
                {customer.type === 'person' ? customer.fullName : customer.name}
              </Box>
            ) : (
              <Box>-</Box>
            )}
          </HStack>
        </SimpleGrid>
      </VStack>
    </chakraComponents.Option>
  );
};
