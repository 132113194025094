import { FaListAlt } from 'react-icons/fa';

import { ModuleEventTypeProviders } from 'app/Modules/Events/Assets/components';
import i18n from 'BootQuery/Assets/js/i18n';

import { ticketToCalendarEvent } from './ticket-to-calendar-item';
import { TicketCalendarModalContent } from './TicketCalendarModalContent';
import { TicketCalendarCreateModalContent } from './TicketCalendarTicketModal/TicketCalendarCreateModalContent';
import { TicketContactColumn } from './TicketContactColumn';
import { TicketEventCard } from './TicketEventCard';
import { TicketEventColumn } from './TicketEventColumn';
import { TicketTypeColumn } from './TicketTypeColumn';
import { TicketUserColumn } from './TicketUserColumn';
import { Ticket } from './types';

export * from './types';
export { TicketEventCard };
export const ticketEventProvider: ModuleEventTypeProviders = {
  ticket: {
    components: {
      Card: TicketEventCard,
    },
    columns: {
      type: { Cell: TicketTypeColumn },
      event: { Cell: TicketEventColumn },
      user: { Cell: TicketUserColumn },
      contact: { Cell: TicketContactColumn },
    },
    calendar: {
      info: {
        icon: <FaListAlt />,
        name: () => i18n.t('Ticketing:ticketing'),
        createLabel: () => i18n.t('Ticketing:add_ticket'),
      },
      transformFn: ticketToCalendarEvent,
      dateCellAction: {
        type: 'modal',
        content: TicketCalendarCreateModalContent,
        componentProps: { modal: { size: '6xl', scrollBehavior: 'inside' } },
        callback: (data: Ticket) => {
          // TODO: Maybe add something here
          // e.g. emit event that we catch somewhere
        },
      },
      action: {
        type: 'modal',
        content: TicketCalendarModalContent,
        componentProps: { modal: { size: '6xl', scrollBehavior: 'inside' } },
        callback: (data: Ticket) => {
          // TODO: Maybe add something here
          // console.log(data);
        },
      },
    },
  },
};
