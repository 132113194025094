import { useMemo } from 'react';
import { Box, Center, HStack, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';

import {
  getReadableSoftFontColor,
  pastelizeColor,
} from '@bq/components/ColorUtils';

import { useCalendarCtx } from '../CalendarCtx';
import { handleItemClick } from '../Events/calendar-events';
import { HourItem as HourItemProps } from '../types';

export const HourItem = (props: HourItemProps & { idx: number }) => {
  const {
    ID,
    column,
    height,
    isWholeDayEvent,
    row,
    startDate,
    title,
    topOffset,
    color,
    icon,
    splitInfo,
    idx,
    width,
    leftOffset,
    type,
    additionalProps,
    endDate,
  } = props;

  const {
    view: [view],
    ID: calendarID,
  } = useCalendarCtx();
  const bg = pastelizeColor(color ?? '#515152');
  const fontColor = getReadableSoftFontColor(bg);

  const startTime = useMemo(() => {
    return isWholeDayEvent ? null : format(startDate, 'HH:mm');
  }, [isWholeDayEvent, startDate]);

  return (
    <VStack
      shadow="md"
      border="1px solid #dbdbdb"
      overflow="hidden"
      textOverflow="ellipsis"
      minH="24px"
      onClick={() => {
        handleItemClick({
          calendarID,
          view,
          item: {
            ID,
            endDate,
            isWholeDayEvent,
            startDate,
            title,
            type,
            additionalProps,
            color,
            icon,
          },
        });
      }}
      key={splitInfo ? `${ID}-${idx}` : ID}
      cursor="pointer"
      rounded="md"
      px={2}
      py={0.5}
      gridColumn={`${column.start} / ${column.end}`}
      gridRow={`${row.start} / ${row.end}`}
      pos="relative"
      fontSize="xs"
      top={`${topOffset}px`}
      left={leftOffset}
      h={height}
      bg={bg}
      color={fontColor}
      width={width}
    >
      <HStack w="full">
        {icon && <Center>{icon}</Center>}
        <Box>{isWholeDayEvent ? title : `${startTime}-${title}`}</Box>
      </HStack>
    </VStack>
  );
};
