import { addDays, format } from 'date-fns';

import {
  CalendarGridMap,
  CalendarGridPos,
  Day,
  Hour,
} from '@bq/components/Calendar/types';

import { getWeekGridKeyFromDate, getWeekGridKeyFromKeyString } from './utils';

export const generateWeekGridMap = (
  week: Day[],
  hours: Hour[],
  layoutShift = 0
): CalendarGridMap => {
  return hours.reduce<CalendarGridMap>((prev, current, hourIdx) => {
    const realHourIDx = hourIdx + 1 + layoutShift;
    const didDayShift = current.dayShift >= 1 || current.dayShift <= -1;

    const dayHourMap = week.map((day, dayIdx) => {
      const realDayIdx = dayIdx + 1;

      if (didDayShift) {
        const newDate = addDays(day.date, current.dayShift);
        const newKey = format(newDate, 'yyyy-MM-dd');

        return {
          [`${newKey}/${current.key}`]: {
            row: { start: realHourIDx, end: realHourIDx + 1 },
            column: { start: realDayIdx, end: realDayIdx + 1 },
          },
        };
      }

      return {
        [`${day.key}/${current.key}`]: {
          row: { start: realHourIDx, end: realHourIDx + 1 },
          column: { start: realDayIdx, end: realDayIdx + 1 },
        },
      };
    });

    return { ...prev, ...Object.assign({}, ...dayHourMap) };
  }, {});
};

export const getWeekGridPosition = (
  key: string,
  weekGridMap: CalendarGridMap
): CalendarGridPos => {
  // We ensure we get a "clean key", that points to a specific
  // Hour instead of e.g. 15:15
  const cleanedUpKey = getWeekGridKeyFromKeyString(key);

  return weekGridMap[cleanedUpKey];
};

export const getWeekGridPositionFromDate = (
  date: Date,
  weekGridMap: CalendarGridMap
) => {
  const key = getWeekGridKeyFromDate(date);

  return getWeekGridPosition(key, weekGridMap);
};
