import { Box, HStack } from '@chakra-ui/react';

import { useCalendarCtx } from '@bq/components/Calendar/CalendarCtx';
import { CalendarMenu } from '@bq/components/Calendar/SharedComponents/CalendarMenu';
import { TodayButton } from '@bq/components/Calendar/SharedComponents/TodayButton';
import { useGetMonth } from '@bq/components/Calendar/utils';
import { useHeaderColor } from '@bq/components/use-header-color';

import { MonthsControls } from './MonthsControls';

export const MonthHeader = () => {
  const {
    currentDate: [currentDate],
  } = useCalendarCtx();

  const monthInfo = useGetMonth(currentDate);
  const headerColor = useHeaderColor();

  return (
    <HStack flex="0 0 42px" w="full" px={4} bg={headerColor}>
      <HStack flex="1 1 auto">
        <TodayButton />
        <MonthsControls />
        <Box fontWeight="bold" fontSize="xl">
          {monthInfo}
        </Box>
      </HStack>
      <CalendarMenu />
    </HStack>
  );
};
