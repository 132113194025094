import { useMemo } from 'react';

import { useCalendarCtx } from '@bq/components/Calendar/CalendarCtx';

import { generateFormattedWeekForDateRange } from './generate-formatted-week-for-date-range';
import { generateLocalizedHours } from './generate-localized-hours';
import { generateWeekGridMap } from './week-grid-map-utils';
import { weekGridWithDays } from './week-grid-with-days';
import { getWeekItems } from './week-items';
import { getWholeDayEvents } from './whole-day-events';

export const useSetupWeekCalendar = () => {
  const {
    locale,
    dateRange,
    items,
    firstDayOfWeek: [weekStartsOn],
    weekStartShift: [shift],
    maxItemsPerCell: [maxItems],
  } = useCalendarCtx();

  const localizedHours = useMemo(
    () => generateLocalizedHours(locale, shift),
    [locale, shift]
  );
  const week = useMemo(() => {
    return generateFormattedWeekForDateRange(dateRange);
  }, [dateRange]);

  const weekGridMap = useMemo(
    () => generateWeekGridMap(week, localizedHours, 2),
    [localizedHours, week]
  );

  const weekGridMapWithDays = useMemo(
    () => weekGridWithDays(weekGridMap, week),
    [week, weekGridMap]
  );

  const hourItems = useMemo(() => {
    return getWeekItems({
      gridMap: weekGridMapWithDays,
      items,
      maxItems,
      range: dateRange,
      weekStartsOn,
      hourShift: shift,
      days: week,
      hours: localizedHours,
    });
  }, [
    dateRange,
    items,
    localizedHours,
    maxItems,
    shift,
    week,
    weekGridMapWithDays,
    weekStartsOn,
  ]);

  const weekItems = useMemo(() => {
    return getWholeDayEvents({
      gridMap: weekGridMapWithDays,
      items: hourItems.wholeDayEvents,
      maxItems,
      range: dateRange,
      weekStartsOn,
    });
  }, [
    dateRange,
    hourItems.wholeDayEvents,
    maxItems,
    weekGridMapWithDays,
    weekStartsOn,
  ]);

  return { week, localizedHours, hourItems, weekItems, weekGridMapWithDays };
};
