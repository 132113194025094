import { PropsWithChildren } from 'react';
import { ModalContent } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { useModalInstance } from '@bq/components/UseModal';

import { ReminderWithChange } from './types';

interface Props {
  reminder: ReminderWithChange | null;
}

export const RemindersFormWrapper = ({
  reminder,
  children,
}: PropsWithChildren<Props>) => {
  const methods = useForm<ReminderWithChange>({
    defaultValues: reminder ?? {},
  });
  const { closeWithCallback } = useModalInstance();

  return (
    <FormProvider {...methods}>
      <ModalContent
        as="form"
        data-ignore-form
        onSubmit={methods.handleSubmit((data) => {
          closeWithCallback(data);
        })}
      >
        {children}
      </ModalContent>
    </FormProvider>
  );
};
