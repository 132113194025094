import { useMemo } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Textarea,
} from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ChakraDateInput } from '@bq/components/ChakraDateInput/ChakraDateInput';
import { WithChange } from '@bq/components/SettingsCRUD';
import { useModalInstance } from '@bq/components/UseModal';
import { dateNotInPast, parseDateish } from 'BootQuery/Assets/js/dateUtils';

import { TicketReminder } from '../../types';
import { TicketStateSelect } from '../State/TicketStateSelect';

export const ReminderFormFields = () => {
  const { closeWithNoCallback } = useModalInstance();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<WithChange<TicketReminder>>();
  const { t } = useTranslation();

  const {
    field: timestamp,
    fieldState: { error: timestampError },
  } = useController({
    name: 'timestamp',
    control,
    rules: {
      required: {
        message: t('global:form_errors.required_field'),
        value: true,
      },

      validate: (v) => {
        return dateNotInPast(v) || t('Ticketing:remindersForm.not_in_past');
      },
    },
  });
  const {
    field: state,
    fieldState: { error: stateError },
  } = useController({
    name: 'newState',
    control,
  });

  const date = useMemo(() => {
    if (!timestamp.value) {
      return null;
    }

    return parseDateish(timestamp.value);
  }, [timestamp.value]);

  const minDate = useMemo(() => new Date(), []);

  return (
    <>
      <ModalHeader>{t('Ticketing:reminders')}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl isInvalid={!!errors.title?.message} mb="2">
          <FormLabel>{t('Ticketing:form.title')}</FormLabel>
          <Input
            size={['lg', 'lg', 'sm']}
            {...register('title', {
              required: {
                message: t('global:form_errors.required_field'),
                value: true,
              },
            })}
          />
        </FormControl>
        <FormControl mb="2" isInvalid={!!timestampError?.message}>
          <FormLabel>{t('Ticketing:form.time')}</FormLabel>

          <ChakraDateInput
            minDate={minDate}
            inputClass=" reminder.datepicker-input "
            value={date}
            onChange={(value) => timestamp.onChange(value)}
            showTime
            size={['lg', 'lg', 'sm']}
          />
          {timestampError?.message && (
            <FormErrorMessage>{timestampError.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!stateError?.message} mb="2">
          <FormLabel>{t('Ticketing:form.state_after_reminder')}</FormLabel>
          <TicketStateSelect
            isClearable
            inputSize={['lg', 'lg', 'sm']}
            onChange={(val) => {
              let valParse = null;
              if (val) {
                valParse = {
                  color: val.color,
                  ID: val.value,
                  name: val.label,
                };
              }
              state.onChange(valParse);
            }}
            value={
              state.value
                ? {
                    color: state.value.color,
                    label: state.value.name,
                    value: state.value.ID,
                  }
                : null
            }
          />
          {stateError && (
            <FormErrorMessage>{stateError.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl mb="2">
          <FormLabel>{t('Ticketing:form.description')}</FormLabel>
          <Textarea size="sm" {...register('description')} />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button
          colorScheme="red"
          mr={3}
          onClick={() => {
            closeWithNoCallback();
          }}
          variant="outline"
        >
          {t('global:close')}
        </Button>
        <Button type="submit" colorScheme="green">
          {t('global:save')}
        </Button>
      </ModalFooter>
    </>
  );
};
