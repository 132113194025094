import { useMemo } from 'react';
import { FormControl, FormLabel, HStack, IconButton } from '@chakra-ui/react';
import { FormState, UseControllerReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { useCrud } from '@bq/components/SettingsCRUD/use-crud';
import { useIsMobile } from '@bq/components/use-is-mobile';
import { uniqid } from 'app/assets/js/tsutil';

import { TicketLink as ITicketLink, TicketForm } from '../../types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';
import { useSyncCrudField } from '../use-sync-crud-field';
import { TicketLink } from './TicketLink';

export type FormTicketLink = Omit<Partial<ITicketLink>, 'ID'> & {
  ID: number | string;
};
export const TicketLinks = ({
  field,
  formState,
  isDisabled,
}: UseControllerReturn<TicketForm, 'links'> & {
  formState: FormState<TicketForm>;
  isDisabled?: boolean;
}) => {
  const { t } = useTranslation('Ticketing');
  const isMobile = useIsMobile();
  const [items, modify, modifications, clearModifications] =
    useCrud<FormTicketLink>({
      items: field.value.defaults,
    });

  const { saveID } = useTicketMutationContext();

  const excludeKnown = useMemo(() => {
    return items.reduce((all: number[], current) => {
      if (current.otherTicket?.ID) {
        return [...all, current.otherTicket.ID];
      }

      return all;
    }, []);
  }, [items]);

  useSyncCrudField({ saveID, field, modifications, clearModifications });

  return (
    <FormControl mb={2}>
      <HStack>
        <FormLabel w="full">{t('Ticketing:links')}</FormLabel>
        {!isDisabled && (
          <IconButton
            aria-label={t('global:add')}
            size="sm"
            variant="ghost"
            colorScheme="green"
            icon={isMobile ? <FaPlus size={25} /> : <FaPlus />}
            onClick={() => {
              modify.add({
                ID: uniqid(),
              });
            }}
          />
        )}
      </HStack>
      {items.map((link, idx) => (
        <TicketLink
          key={idx}
          link={link}
          methods={modify}
          error={formState.errors.links?.type}
          isDisabled={isDisabled}
          excludeKnown={excludeKnown}
        />
      ))}
    </FormControl>
  );
};
