import { useMemo } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { format, getDate, isSameMonth } from 'date-fns';
import { UseMeasureRef } from 'react-use/lib/useMeasure';

import { useCalendarCtx } from '../../CalendarCtx';
import { handleDateCellClick } from '../../Events/calendar-events';
import { useCalendarColors } from '../../use-calendar-colors';
import { getDayNumber } from '../../utils';

interface Props {
  date: Date;
  currentMonth: Date;
  column: string;
  row: string;
  measureRef?: UseMeasureRef<HTMLDivElement>;
}
export const MonthDateCell = ({
  date,
  currentMonth,
  column,
  row,
  measureRef,
}: Props) => {
  const { locale, ID: calendarID } = useCalendarCtx();
  const dayNumber = useMemo(() => getDayNumber(date, locale), [date, locale]);
  const sameMonthDate = useMemo(
    () => isSameMonth(date, currentMonth),
    [currentMonth, date]
  );
  const firstDayInMonth = useMemo(() => getDate(date) === 1, [date]);

  const { currentMonthColor, notCurrentMonthColor, fill, borders } =
    useCalendarColors();

  return (
    <VStack
      position="relative"
      bg={fill}
      color={sameMonthDate ? currentMonthColor : notCurrentMonthColor}
      fontSize="xs"
      gridColumn={column}
      gridRow={row}
      spacing={0}
      outline="1px solid"
      outlineColor={borders}
      onClick={(e) => {
        const day = format(date, 'yyyy-MM-dd');
        handleDateCellClick({
          calendarID,
          rawEvent: e,
          date,
          hasHour: false,
          view: 'month',
          formattedDay: day,
        });
      }}
    >
      <Box w="full" px={1}>
        {firstDayInMonth
          ? `${format(date, 'MMM', { locale })} ${dayNumber}`
          : dayNumber}
      </Box>
      <Box flex="1 1 auto" w="full" pos="relative" ref={measureRef}></Box>
    </VStack>
  );
};
