import { ReactElement, useCallback } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaEdit } from 'react-icons/fa';

import { useConfirmationModal } from '@bq/components/ConfirmationModal/use-confirmation-modal';
import { DeleteButton } from '@bq/components/DeleteButton';
import { IconButton } from '@bq/components/IconButton';
import { StartStopButton } from '@bq/components/StartStopButton';
import { useIsMobile } from '@bq/components/use-is-mobile';
import { useModal } from '@bq/components/UseModal';

import { TicketWork } from '../../types';
import { TicketWorkFormModal } from '../TicketWorkForm';
import { CreateWorkRequest } from '../types';
import { WorkDuration } from '../WorkDuration';
import { WorkTitle } from '../WorkTitle';
import { TicketWorkItemDetails } from './TicketWorkItemDetails';

interface Props {
  ticketID: number;
  work: TicketWork;
  onStart?: () => void;
  onStop?: () => void;
  onDelete?: () => void;
  onUpdate?: (changes: Partial<CreateWorkRequest>) => void;
  isLoading?: boolean;
}

export const TicketWorkItemContent = ({
  ticketID,
  onStart,
  onStop,
  onDelete,
  onUpdate,
  isLoading,
  work,
}: Props): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { addModal } = useModal();
  const currentUserID = window.Bootstrap.bootquery.session.userID;
  const isCurrentUser = work.createdByUser?.ID === currentUserID;
  const isMobile = useIsMobile();
  const requestConfirm = useConfirmationModal();

  const handleDelete = useCallback(() => {
    return requestConfirm({
      headerContent: '',
      onConfirm: () => {
        if (onDelete) {
          onDelete();
        }
      },
    });
  }, [requestConfirm, onDelete]);

  const edit = useCallback(() => {
    addModal({
      children: (
        <TicketWorkFormModal ticketID={ticketID} mode="edit" data={work} />
      ),
      preventClose: {
        defaultPreventState: false,
      },
      componentProps: {
        modal: { size: '6xl' },
      },
      callback: onUpdate,
    });
  }, [ticketID, addModal, work, onUpdate]);

  return (
    <>
      <Accordion h="full" mt="5" w="full" allowToggle>
        <AccordionItem
          borderBottom="1px solid"
          borderTop="1px solid"
          borderColor="brand.500"
          w="full"
          shadow="base"
        >
          <AccordionButton
            as={isMobile ? VStack : HStack}
            w="full"
            spacing={['3', '3', '3', '8']}
            px="2"
            py={['3', '3', '2']}
          >
            <Box flex="1 1 auto">
              <WorkTitle workTitle={work.title} />
              {isMobile && <AccordionIcon />}
            </Box>

            <Box>
              <StartStopButton
                isStarted={work.inProgress}
                isDisabled={!isCurrentUser || isLoading}
                disabledLabel={
                  !isCurrentUser
                    ? t('Ticketing:can_only_time_your_ticket')
                    : undefined
                }
                colorScheme="blue"
                onStart={onStart}
                onStop={onStop}
              />
            </Box>

            <Box flex="0 1 auto">
              <WorkDuration
                pendingTimeSince={work.pendingTimeSince}
                durationSecs={work.durationSecs}
              />
            </Box>

            <Box flex="0 1 auto">
              <DeleteButton
                onClick={(e) => {
                  // stopPropagination() stops triggering accordion on button click
                  e.stopPropagation();
                  handleDelete();
                }}
                size="sm"
                isDisabled={isLoading}
              />
              &nbsp;
              <IconButton
                size="sm"
                icon={<FaEdit />}
                label={t('global:edit')}
                isDisabled={isLoading}
                colorScheme="blue"
                onClick={(e) => {
                  // stopPropagination() stops triggering accordion on button click
                  e.stopPropagation();
                  edit();
                }}
              />
            </Box>
            {!isMobile && <AccordionIcon />}
          </AccordionButton>
          <AccordionPanel pb={4}>
            <TicketWorkItemDetails work={work} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
