import tinycolor from 'tinycolor2';

export const getReadableSoftFontColor = (bgColor: string) => {
  const tc = tinycolor(bgColor);

  const softLightColor = tinycolor('#ffffe0');
  const softDarkColor = tinycolor('#1E1E1E');

  const readableColor = tinycolor.mostReadable(
    tc,
    [softLightColor, softDarkColor],
    {
      includeFallbackColors: true,
      level: 'AA',
      size: 'small',
    }
  );

  return readableColor.toHexString();
};
