import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import { CalendarEventProvider } from './Events/CalendarEventProvider';
import { CalendarContextProps, CalendarContextValue } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CalendarCtx = createContext<CalendarContextValue | null>(null);

export const CalendarProvider = ({
  children,
  ...rest
}: PropsWithChildren<CalendarContextProps>) => {
  const maxItemsPerCell = useState<number>(0);
  const cellHeight = useState<number>(0);
  const disclosureState = useDisclosure();

  return (
    <CalendarCtx.Provider
      value={{
        ...rest,
        cellHeight,
        maxItemsPerCell,
        disclosureState,
      }}
    >
      <CalendarEventProvider>{children}</CalendarEventProvider>
    </CalendarCtx.Provider>
  );
};

export const useCalendarCtx = (): CalendarContextValue => {
  const ctxVal = useContext(CalendarCtx);
  if (!ctxVal) {
    throw Error('Missing Calendar Context');
  }

  return ctxVal as CalendarContextValue;
};
