import { useMemo } from 'react';
import { isWithinInterval } from 'date-fns';

import { CalendarDateRange } from './types';

export const useIsNowInsideRange = (dateRange: CalendarDateRange): boolean => {
  return useMemo(() => {
    const now = new Date();

    return isWithinInterval(now, {
      start: dateRange.start,
      end: dateRange.end,
    });
  }, [dateRange]);
};
