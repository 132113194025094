import {
  Box,
  FormLabel,
  HStack,
  Link,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaListAlt } from 'react-icons/fa';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { IOverviewEditorItem } from '@bq/components/Overviews';

import { TicketForm } from '../../types';
import { useSelectedOverview } from '../../use-selected-overview';

interface Props {
  dependers: TicketForm['dependers'];
}

export const TicketDependers = ({ dependers }: Props) => {
  const selectedOverview = useSelectedOverview();
  const query = useLocation().search;
  const { t } = useTranslation('Ticketing');
  const iconColor = useColorModeValue('brand.600', 'brand.200');

  return (
    <VStack alignItems="flex-start" fontSize="md" w="full">
      <FormLabel fontWeight="bold">{t('Ticketing:dependers')}:</FormLabel>
      {dependers.map((item) => (
        <Link
          w="full"
          as={RouterLink}
          key={item.depender.ID}
          to={getLink(item.depender.ID, query, selectedOverview)}
        >
          <HStack w="full">
            <Box color={iconColor}>
              <FaListAlt style={{ color: 'inherit' }} />
            </Box>
            <Box>#{item.depender.ID}</Box>
            <Box fontWeight="bold" flex="1 1 auto">
              {item.depender.title}
            </Box>
            <Box
              flex="0 1 auto"
              ml="auto"
              textAlign="center"
              bg={item.depender.state.color}
              rounded="sm"
              py="0.5"
              px="1"
              fontWeight="bold"
              fontSize="xs"
            >
              {item.depender.state.name}
            </Box>
          </HStack>
        </Link>
      ))}
    </VStack>
  );
};

const getLink = (
  ticketID: number,
  query: string,
  selectedOverview?: IOverviewEditorItem | null
) => {
  if (selectedOverview) {
    return `/ticketing/list/${selectedOverview.slug}/${ticketID}${query}`;
  }

  return `/ticketing/edit/${ticketID}${query}`;
};
