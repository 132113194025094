import { Api } from 'BootQuery/Assets/js/api';
import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import { PatchTicket } from '../../types';

export const patchTicket = async <T = { ID: number }>(
  ID: number | string,
  allData: Partial<PatchTicket>,
  params?: GetListingParams
) => {
  const { data } = await Api.patch<T>(`/api/ticketing/tickets/${ID}`, allData, {
    params,
  });

  return data;
};
