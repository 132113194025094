import { useCallback, useMemo, useState } from 'react';

import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { UseTabControlProps, UseTabControlReturn } from './types';

export const useTabControl = <T extends string>({
  tabs,
  defaultTab,
}: UseTabControlProps<T>): UseTabControlReturn<T> => {
  const [tab, setTabState] = useState<T>(defaultTab ?? tabs[0]);

  const setTab = useCallback(
    (newTab: T | number) => {
      if (typeof newTab === 'number') {
        if (tabs.length >= newTab) {
          setTabState(tabs[newTab]);
        }
      } else {
        setTabState(newTab);
      }
    },
    [tabs]
  );

  const currentTabIndex = useMemo(() => tabs.indexOf(tab), [tab, tabs]);

  useChangeEffect(tabs, () => {
    const foundInNewTabs = tabs.find((item) => item === tab);
    if (!foundInNewTabs) {
      setTabState(tabs[0]);
    }
  }, []);

  return [tab, currentTabIndex, setTab];
};
