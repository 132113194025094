import { Box, HStack } from '@chakra-ui/react';

import { useCalendarCtx } from '@bq/components/Calendar/CalendarCtx';
import { CalendarMenu } from '@bq/components/Calendar/SharedComponents/CalendarMenu';
import { TodayButton } from '@bq/components/Calendar/SharedComponents/TodayButton';
import { useGetWeek } from '@bq/components/Calendar/utils';
import { useHeaderColor } from '@bq/components/use-header-color';

import { WeekControls } from './WeekControls';

export const WeekHeader = () => {
  const { dateRange } = useCalendarCtx();

  const monthInfo = useGetWeek(dateRange);
  const headerColor = useHeaderColor();

  return (
    <HStack
      flex="0 0 40px"
      w="full"
      bg={headerColor}
      px={4}
      zIndex={3}
      top={0}
      pos="sticky"
    >
      <HStack flex="1 1 auto">
        <TodayButton />

        <WeekControls />

        <Box fontWeight="bold" fontSize="xl">
          {monthInfo}
        </Box>
      </HStack>

      <CalendarMenu />
    </HStack>
  );
};
