import { useWatch } from 'react-hook-form';

import {
  TicketForm,
  TicketType,
  useTicketingContext,
} from 'app/Modules/Ticketing/Assets';

export function useFullFormType(): TicketType {
  const formType = useWatch<TicketForm, 'ticketType'>({ name: 'ticketType' });
  const { types } = useTicketingContext();

  const fullType = types.find((fullType) => fullType.ID === formType.ID);
  if (!fullType) {
    throw new Error('Ticket type missing from form');
  }

  return fullType;
}
