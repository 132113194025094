import { ReactElement, useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { CreatableSelect } from 'chakra-react-select';
import { useController, useFormContext } from 'react-hook-form';

import { formatNumber } from 'app/assets/js/tsutil';
import { chakraSelectStyles } from 'BootQuery/Assets/components/Select';

import { ClientInfo } from '../js/types';
import type { CallForm } from './AddCallModal';

interface Props {
  clientInfo: ClientInfo | null;
}

export const CallNumberSelector = ({ clientInfo }: Props): ReactElement => {
  const options = useNumberOptions(clientInfo);
  const { control } = useFormContext<CallForm>();
  const { field } = useController({ control, name: 'phoneNumber' });

  return (
    <Flex justifyContent="center">
      <Box width="full" maxWidth="sm">
        <CreatableSelect
          menuPlacement="auto"
          menuPosition="fixed"
          value={field.value}
          onChange={field.onChange}
          options={options}
          styles={chakraSelectStyles}
          selectedOptionColorScheme="brand"
        />
      </Box>
    </Flex>
  );
};

export interface Option {
  label: string;
  value: string;
}

export function useNumberOptions(client: ClientInfo | null): Option[] {
  return useMemo(() => {
    if (!client) {
      return [];
    }

    return client.phoneNumbers.map((num) => ({
      value: num.phoneNumber,
      label: formatNumber(num.phoneNumber),
    }));
  }, [client]);
}
