import { useMemo } from 'react';
import { uniq } from 'lodash-es';

import { PerTicketWatchers } from './types';

/**
 * Returns a list of userIDs watching the current ticket
 */
export function useWatcherList(
  perTicket: PerTicketWatchers,
  ticketID: number | null
): number[] {
  const ownUserID = window.Bootstrap.bootquery.session.userID;

  return useMemo(() => {
    if (!ticketID) {
      return [];
    }
    const forThisTicket = perTicket[ticketID];
    if (!forThisTicket) {
      return [];
    }

    const watchers = Object.values(forThisTicket)
      .map((watcher) => watcher.userID)
      .filter((userID) => userID !== ownUserID);

    return uniq(watchers);
  }, [perTicket, ticketID, ownUserID]);
}
