import { ReactElement, useCallback, useMemo } from 'react';
import {
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaCalendarCheck } from 'react-icons/fa';

import { FormState, useFormState } from '@bq/components/form-state';
import { FormActions } from '@bq/components/FormActions';
import { LoadingModalContent } from '@bq/components/LoadingModalContent';
import { useModalInstance } from '@bq/components/UseModal';

import {
  createAppointment,
  PatchAppointment,
  PostAppointment,
  updateAppointment,
  useAppointment,
} from './api';
import { useAppointmentEvents } from './AppointmentEventsProvider';
import { AppointmentFormModalFields } from './AppointmentFormModalFields';
import { useAppointmentForm } from './form';
import { IAppointmentForm } from './schema';

export interface CreateAppointmentProps {
  mode: 'create';
  defaultValues?: Partial<IAppointmentForm>;
}

export interface EditAppointmentProps {
  mode: 'edit';
  appointmentID: number;
}

export type AppointmentFormModalProps =
  | CreateAppointmentProps
  | EditAppointmentProps;

export const AppointmentFormModal = (
  props: AppointmentFormModalProps
): ReactElement => {
  if (props.mode === 'create') {
    return <CreateAppointmentFormModal {...props} />;
  }

  return <EditAppointmentFormModal {...props} />;
};

const EditAppointmentFormModal = ({
  appointmentID,
}: EditAppointmentProps): ReactElement => {
  const { closeWithCallback, closeWithNoCallback } = useModalInstance();
  const [formState, setFormState] = useFormState();
  const { data: appointment, refetch } = useAppointment(appointmentID);
  const onModify = useAppointmentEvents()?.onModify;

  const submit = useCallback(
    async (data: IAppointmentForm) => {
      setFormState('saving');

      const appointment = await updateAppointment(
        appointmentID,
        formToUpdate(data),
        { fields: ['$full'] }
      );
      refetch(); // Clear data for next load

      setFormState('saved');

      closeWithCallback(appointment);
      onModify?.(appointment);
    },
    [setFormState, closeWithCallback, appointmentID, onModify, refetch]
  );

  const cancel = useCallback(() => {
    setFormState(null);

    closeWithNoCallback();
  }, [setFormState, closeWithNoCallback]);

  const defaultValues = useMemo((): Partial<IAppointmentForm> => {
    if (!appointment) {
      return {};
    }

    return {
      ...appointment,
      attendees: appointment.participants,
      reminders: {
        add: [],
        upd: {},
        del: [],
        defaults: appointment.reminders,
      },
    };
  }, [appointment]);

  if (!appointment) {
    return <LoadingModalContent />;
  }

  return (
    <AppointmentFormModalContent
      mode="edit"
      formState={formState}
      onSubmit={submit}
      onCancel={cancel}
      defaultValues={defaultValues}
    />
  );
};

const CreateAppointmentFormModal = ({
  defaultValues,
}: CreateAppointmentProps): ReactElement => {
  const { closeWithCallback, closeWithNoCallback } = useModalInstance();
  const [formState, setFormState] = useFormState();
  const onAdd = useAppointmentEvents()?.onAdd;

  const submit = useCallback(
    async (data: IAppointmentForm) => {
      setFormState('saving');

      const appointment = await createAppointment(formToCreate(data), {
        fields: ['$full'],
      });

      setFormState('saved');

      closeWithCallback(appointment);
      onAdd?.(appointment);
    },
    [setFormState, closeWithCallback, onAdd]
  );

  const cancel = useCallback(() => {
    setFormState(null);

    closeWithNoCallback();
  }, [setFormState, closeWithNoCallback]);

  return (
    <AppointmentFormModalContent
      mode="create"
      formState={formState}
      onSubmit={submit}
      onCancel={cancel}
      defaultValues={{
        attendees: [],
        reminders: { add: [], del: [], upd: {}, defaults: [] },
        data: {},
        location: { type: 'unknown' },
        ...defaultValues,
      }}
    />
  );
};

interface AppointmentModalContentProps {
  mode: 'create' | 'edit';
  formState: FormState;
  onSubmit: (data: IAppointmentForm) => void;
  onCancel: () => void;
  defaultValues: Partial<IAppointmentForm>;
}

const AppointmentFormModalContent = ({
  mode,
  formState,
  onSubmit,
  onCancel,
  defaultValues,
}: AppointmentModalContentProps): ReactElement => {
  const { t } = useTranslation('Events');

  const formMethods = useAppointmentForm({
    defaultValues,
  });
  const {
    reset,
    formState: { isDirty },
  } = formMethods;

  const cancel = useCallback(() => {
    reset();
    onCancel();
  }, [reset, onCancel]);

  return (
    <FormProvider {...formMethods}>
      <ModalContent
        as="form"
        data-form-dirty={isDirty}
        data-ignore-form-save
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <ModalHeader display="flex" alignItems="center">
          <FaCalendarCheck />
          &nbsp;
          {mode === 'create'
            ? t('Events:create_appointment')
            : t('Events:edit_appointment')}
        </ModalHeader>
        <ModalBody>
          <AppointmentFormModalFields />
        </ModalBody>
        <ModalFooter>
          <FormActions state={formState} onCancel={cancel} />
        </ModalFooter>
      </ModalContent>
    </FormProvider>
  );
};

function formToCreate(data: IAppointmentForm): PostAppointment {
  return {
    ...data,
    participants: data.attendees,
    reminders: creatableReminders(data.reminders.add),
  };
}

function formToUpdate(data: IAppointmentForm): PatchAppointment {
  return {
    ...data,
    participants: data.attendees,
    reminders: {
      add: creatableReminders(data.reminders.add),
      upd: data.reminders.upd as Record<number, { timestamp: string | Date }>,
      del: data.reminders.del,
    },
  };
}

function creatableReminders(
  toAdd: IAppointmentForm['reminders']['add']
): { timestamp: Date }[] {
  return toAdd
    .map((item) => item.timestamp)
    .filter((timestamp): timestamp is Date => !!timestamp)
    .map((timestamp) => ({ timestamp }));
}
