import { create } from 'zustand';

import { makeListener } from 'BootQuery/Assets/js/socket-event-listener';

import { getAllUserStatuses } from './Api/user-states';
import { UserPresence, UserStatus, UserStatusMap } from './types';

interface UserPresenceEvent extends UserPresence {
  userID: number;
}

interface UserOnlineEvent {
  userID: number;
  online: boolean;
}

export interface IUsersPresenceStore {
  init: () => Promise<void>;
  initialized: boolean;
  users: UserStatusMap | null;
}

export const usersPresenceStore = create<IUsersPresenceStore>((set, get) => {
  const updateUser = (userID: number, changes: Partial<UserStatus>) => {
    set((prev) => {
      if (!prev.users || !prev.users[userID]) {
        return prev;
      }

      return {
        ...prev,
        users: {
          ...prev.users,
          [userID]: {
            ...prev.users[userID],
            ...changes,
          },
        },
      };
    });
  };

  return {
    init: async () => {
      const usersList = await getAllUserStatuses();
      const users = usersList.reduce<UserStatusMap>(
        (users, user) => ({
          ...users,
          [user.ID]: user,
        }),
        {}
      );

      userEventListener().subscribeWebSocket(
        'user/presence',
        (presence: UserPresenceEvent) => {
          console.log('User presence', presence);
          updateUser(presence.userID, { presence });
        }
      );

      userEventListener().subscribeWebSocket(
        'user/online',
        ({ userID, online }: UserOnlineEvent) => {
          console.log('User online', userID, online);
          updateUser(userID, { online });
        }
      );

      set({ users, initialized: true });
    },
    users: null,
    initialized: false,
  };
});

const userEventListener = makeListener('userPresenceStore');
