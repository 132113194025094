import { ConditionalWrapper } from '@bq/components/ConditionalWrapper';
import { ListingGrid, ListingGridItem } from '@bq/components/ListingGrid';
import { handleScroll } from '@bq/components/scroll-events';
import { useIsMobile } from '@bq/components/use-is-mobile';

import { EssentialFields } from '../EssentialFields';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';
import { TicketFormBodyTabs } from './TicketFormBodyTabs';

export const TicketFormBody = () => {
  const isMobile = useIsMobile();
  const { saveID, customFields, ...rest } = useTicketMutationContext();

  const ticketID = rest.mode === 'edit' ? rest.ticketID : null;

  return (
    <ConditionalWrapper
      shouldWrap={!isMobile}
      render={(children) => (
        <ListingGrid overflow="hidden" flex="1 1 auto">
          {children}
        </ListingGrid>
      )}
    >
      <ListingGridItem
        initialWidth="5fr"
        overflow="auto"
        onScroll={(e) => {
          if (!isMobile) {
            handleScroll(e);
          }
        }}
      >
        <TicketFormBodyTabs
          customFields={customFields}
          mode={rest.mode}
          ticketID={ticketID}
          saveID={saveID}
        />
      </ListingGridItem>
      {!isMobile && (
        <ListingGridItem minWidth={300} initialWidth="300px" maxWidth={400}>
          <EssentialFields />
        </ListingGridItem>
      )}
    </ConditionalWrapper>
  );
};
