import { ReactElement, useCallback, useMemo } from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ContactItem, ContactSelect } from '@bq/components/FormFields';
import { singleValueToSelectOption } from '@bq/components/FormFields/Contact/utils';

import { TicketForm } from '../../types';
import { parseSelectCustomOptionToContactItem } from '../../utils';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';

export const ContactField = (): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { viewOnly } = useTicketMutationContext();
  const { control } = useFormContext<TicketForm>();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({ control, name: 'contact' });

  const contactValue = useMemo(() => {
    return parseSelectCustomOptionToContactItem(value);
  }, [value]);

  const handleChange = useCallback(
    (newVal: ContactItem | null) => {
      // For some reason we keep this in as Select Option
      onChange(singleValueToSelectOption(newVal));
    },
    [onChange]
  );

  return (
    <FormControl isInvalid={fieldState.invalid}>
      <FormLabel fontWeight="bold">{t('Ticketing:form.contact')}:</FormLabel>
      <ContactSelect
        createMode="saveOnApiModal"
        hasDetails={true}
        isClearable
        isMulti={false}
        isDisabled={viewOnly}
        value={contactValue}
        onChange={handleChange}
        allowedTypes={{
          company: true,
          companyLocation: true,
          person: true,
          user: false,
          companyDepartment: true,
        }}
        hasManualOption={true}
      />
      {fieldState.error && (
        <FormErrorMessage>
          {fieldState.error.message || fieldState.error.type}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
