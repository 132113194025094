import { useTranslation } from 'react-i18next';
import { FaCodeBranch, FaExchangeAlt, FaTrashAlt } from 'react-icons/fa';

import { EventMenuItem } from 'app/Modules/Events/Assets/components/types';
import { MailEvent } from 'app/Modules/Mails/Assets/components/EventTypes';

import { pickTicket } from '../../js/pick-ticket';
import { addEventToTicket } from '../Api';
import { UseEventChangesResult } from '../use-event-changes';
import { useTicketId } from '../use-ticket-id';
import { useCanDelete } from './use-can-delete';

export const useMailEventMenus = (
  eventChanges: UseEventChangesResult
): EventMenuItem[] => {
  const { t } = useTranslation('Ticketing');
  const currentTicketID = useTicketId();

  if (!useCanDelete()) {
    return [];
  }
  const items = [
    {
      label: t('Ticketing:move_to_another_ticket'),
      onClick(ev: MailEvent) {
        pickTicket(
          (ticket) => {
            addEventToTicket(ticket.ID, { type: 'mail', mailID: ev.data.ID });
            eventChanges.removeTicketEvent({
              type: 'mail',
              mailID: ev.data.rootID,
            });
          },
          {
            title: t('Ticketing:move_to_another_ticket'),
            notTicketId: currentTicketID ?? undefined,
          }
        );
      },
      icon: FaExchangeAlt,
    } as EventMenuItem,
    {
      label: t('global:delete'),
      onClick(ev: MailEvent) {
        eventChanges.removeTicketEvent({
          type: 'mail',
          mailID: ev.data.rootID,
        });
      },
      icon: FaTrashAlt,
      color: 'red',
    } as EventMenuItem,
  ];
  if (currentTicketID) {
    items.splice(1, 0, {
      label: t('Ticketing:split'),
      href(ev: MailEvent) {
        return `/ticketing/create/?fromMailThreadID=${ev.data.ID}&fromTicketID=${currentTicketID}`;
      },
      icon: FaCodeBranch,
    } as EventMenuItem);
  }

  return items;
};
