import { Box, FormControl, FormLabel } from '@chakra-ui/react';

import { TicketType } from '../types';

interface Props {
  ticketType: TicketType;
  label: string;
}

export const TicketTypeDisplay = ({ ticketType, label }: Props) => {
  return (
    <FormControl mb="2">
      <FormLabel fontWeight="bold">{label}</FormLabel>
      <Box>{ticketType.name}</Box>
    </FormControl>
  );
};
