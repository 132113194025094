import { getTicketPriorities } from '../../Api';
import { TicketPriority } from '../../types';
import { PriorityOption } from './types';

export const loadOptions = async (search: string) => {
  const data = await getTicketPriorities({
    filters: {
      $search: search,
    },
    fields: ['ID', 'name', 'responseInterval', 'resolutionInterval'],
  });

  return ticketPrioritiesToFilterVal(data);
};

const ticketPrioritiesToFilterVal = (
  priorities: TicketPriority[]
): PriorityOption[] => {
  return priorities.map((priority) => ({
    label: priority.name,
    value: priority.ID,
    resolutionInterval: priority.resolutionInterval,
    responseInterval: priority.responseInterval,
  }));
};
