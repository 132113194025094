import { ReactElement, useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { TicketWork } from '../types';
import { WorkDuration } from './WorkDuration';

interface Props {
  works: TicketWork[];
}

export const WorksTotalDuration = ({ works }: Props): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const total = useTotalWorksDurationSecs(works);
  const pendingTimeSince = useWorkPendingTimeSince(works);

  return (
    <Box>
      <Text as="span" fontWeight="bold">
        {t('Ticketing:total_work_duration')}:
      </Text>
      &nbsp;
      <WorkDuration durationSecs={total} pendingTimeSince={pendingTimeSince} />
    </Box>
  );
};

function useTotalWorksDurationSecs(works: TicketWork[]): number {
  return useMemo(() => {
    return works.reduce((total, work) => total + work.durationSecs, 0);
  }, [works]);
}

function useWorkPendingTimeSince(works: TicketWork[]): Date | null {
  const workWithDuration = useMemo(() => {
    return works.find((work) => work.pendingTimeSince);
  }, [works]);

  return workWithDuration?.pendingTimeSince ?? null;
}
