import { eachWeekOfInterval, endOfWeek, format } from 'date-fns';

import { DaysOfWeek, EventBoundaryDates } from '@bq/components/Calendar/types';

export const generateWeeksBetweenDates = (
  start: Date,
  end: Date,
  weekStartsOn: DaysOfWeek
): EventBoundaryDates[] => {
  const weeks = eachWeekOfInterval({ start, end }, { weekStartsOn });

  return weeks.map((weekStart) => {
    const weekEnd = endOfWeek(weekStart, { weekStartsOn });

    return {
      isOverlapping: true,
      overflowsLeft: false,
      overflowsRight: false,
      startDate: {
        rawDate: weekStart,
        formatted: format(weekStart, 'yyyy-MM-dd'),
      },
      endDate: {
        rawDate: weekEnd,
        formatted: format(weekEnd, 'yyyy-MM-dd'),
      },
    };
  });
};
