import { Box, ButtonGroup } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import { ListHeading } from '@bq/components/ListHeading';
import {
  useHeadingProps,
  useListingSettings,
} from '@bq/components/ListingSettings';
import { LoadingPage } from '@bq/components/LoadingPage';
import { WithChange } from '@bq/components/SettingsCRUD';
import { Table, TableSelectionProvider } from '@bq/components/Table';
import { useLoadUserSettings } from 'BootQuery/Assets/js/user-settings';

import { getTicketsByID } from '../../Api/ticket/getTicketsById';
import { ticketFilterTypes } from '../../filter-types';
import { useTicketingContext } from '../../TicketingContext';
import { AddNewTicket } from '../../TicketTable/AddNewTicket';
import { ticketColumns } from '../../TicketTable/Columns/columns';
import { defaultVisibleColumns } from '../../TicketTable/Columns/defaults';
import {
  DependenciesProvider,
  TicketWithDependencies,
} from '../../TicketTable/Dependencies';
import { TicketRow } from '../../TicketTable/types';
import { expandDependencies } from '../../TicketTable/use-tickets-with-dependencies';
import { Ticket, TicketForm } from '../../types';
import { AddOtherTicketAsDep } from './AddOtherTicketAsDep';
import { ticketMutationEvent } from './dependencies-events';
import { TicketDependencyActions } from './TicketDependencyActions';
import { useTicketTreeMutation } from './use-ticket-tree-mutation';

export const CreateTicketDependencies = () => {
  const { allCustomFields } = useTicketingContext();
  const settingsLoaded = useLoadUserSettings('Ticketing.DependencyTable', {});
  const listingSettings = useListingSettings<Ticket>({
    listingName: 'Ticketing.DependencyTable',
    viewName: 'table',
    filterTypes: ticketFilterTypes,
    columns: ticketColumns(),
    storage: 'state',
    defaults: {
      visibleColumns: defaultVisibleColumns,
      sort: [{ direction: 'desc', sortBy: 'ID' }],
    },
    customFields: allCustomFields ?? [],
  });

  const { page, columnsToShow } = listingSettings;

  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    count: null,
    noPagination: true,
  });

  const { control } = useFormContext<TicketForm>();

  const {
    field: { value: subtickets, onChange },
  } = useController<TicketForm, 'subtickets'>({ name: 'subtickets', control });

  useTicketTreeMutation({
    ticketID: null,
    form: { defaults: subtickets, onChange },
  });
  const fullTickets = expandDependencies(subtickets ?? [], 0);

  if (!settingsLoaded) {
    return <LoadingPage />;
  }

  return (
    <>
      <Box py={4}>
        <ListHeading
          {...headingProps}
          Actions={
            <ButtonGroup>
              <AddNewTicket
                modalOnly={true}
                submitCallback={async (data) => {
                  const typecasted = data as unknown as Ticket;
                  ticketMutationEvent({
                    parentID: null,
                    mutation: { action: 'insert', data: typecasted },
                  });
                }}
              />
              <AddOtherTicketAsDep
                onSubmit={async (depIds) => {
                  const tickets = await getTicketsByID(depIds);

                  tickets.data.forEach((ticket) => {
                    ticketMutationEvent({
                      parentID: null,
                      mutation: { action: 'insert', data: ticket },
                    });
                  });
                }}
              />
            </ButtonGroup>
          }
        />
      </Box>
      <DependenciesProvider>
        <TableSelectionProvider<WithChange<TicketWithDependencies>>
          idColumn="ID"
          page={page}
        >
          <Table<TicketRow>
            columns={[
              ...columnsToShow,
              {
                key: '',
                tdProps: { p: 0 },
                minWidth: 120,
                width: '120px',
                Cell: ({ row }) => (
                  <TicketDependencyActions
                    ticket={row}
                    actionCallbacks={{
                      onDelete: (data) => {
                        ticketMutationEvent({
                          parentID: null,
                          mutation: { action: 'insert', data },
                        });
                      },
                      onUnlinkDep: (data) => {
                        ticketMutationEvent({
                          parentID: null,
                          mutation: { action: 'insert', data },
                        });
                      },
                      onUpdate: (data) => {
                        ticketMutationEvent({
                          parentID: null,
                          mutation: { action: 'insert', data },
                        });
                      },
                    }}
                  />
                ),
              },
            ]}
            rows={fullTickets}
          />
        </TableSelectionProvider>
      </DependenciesProvider>
    </>
  );
};
