import { eachDayOfInterval, format } from 'date-fns';

import { WeekKey } from './types';

export const generateNumberOfItems = (
  weekKey: WeekKey
): Record<string, number> => {
  const [start, end] = weekKey.split('/');
  const weekStartDate = new Date(start);
  const weekEndDate = new Date(end);

  // Generate an object directly using reduce on eachDayOfInterval
  return eachDayOfInterval({ start: weekStartDate, end: weekEndDate }).reduce(
    (acc, currentDate) => {
      const formattedDate = format(currentDate, 'yyyy-MM-dd');

      return { ...acc, [formattedDate]: 0 };
    },
    {}
  );
};
