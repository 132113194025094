import { FormTicketLink } from './Links/TicketLinks';

export const getVal = (selected: FormTicketLink | null) => {
  if (!selected) {
    return null;
  }
  if (typeof selected === 'number') {
    return selected;
  }
  if (selected.otherTicket) {
    return selected.otherTicket;
  }

  return null;
};
