import { Api } from 'BootQuery/Assets/js/api';

import { CustomerAndContact } from '../types';

export async function getCustomerForEvent(
  eventType: string,
  eventID: number
): Promise<CustomerAndContact | null> {
  const { data: customer } = await Api.get(
    `/api/ticketing/customerForEvent/${eventType}/${eventID}`
  );

  return customer;
}
