import {
  CalendarDateRange,
  CalendarGridMap,
  CalendarShowMoreButton,
} from '../types';
import { getRawGridPositions } from '../Views/Month/utils/shared-utils';
import { getEventBoundaryDates } from './get-event-boundary-dates';

interface GenerateShowMoreButtonParams {
  showMoreButtonMap: Record<string, number>;
  range: CalendarDateRange;
  gridMap: CalendarGridMap;
}

export const populateWeekShowMoreButtons = ({
  showMoreButtonMap,
  gridMap,
  range,
}: GenerateShowMoreButtonParams) => {
  return Object.entries(showMoreButtonMap).reduce<CalendarShowMoreButton[]>(
    (all, [key, count]) => {
      if (count > 0) {
        const dates = getEventBoundaryDates(key, key, range);
        const { startGridPos } = getRawGridPositions(
          gridMap,
          dates.startDate.formatted,
          dates.endDate.formatted
        );
        const showMoreButtonProps: CalendarShowMoreButton = {
          calendarItemType: 'show-more',
          ID: `${dates.startDate.formatted}/show-more`,
          date: dates.startDate.formatted,
          column: {
            start: startGridPos.column.start,
            end: startGridPos.column.end,
          },
          row: { start: startGridPos.row.start, end: startGridPos.row.end },
          countAfter: count,
        };

        return [...all, showMoreButtonProps];
      }

      return all;
    },
    []
  );
};
