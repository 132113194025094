import { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { addDays, addHours, format, startOfDay } from 'date-fns';

import { useCalendarCtx } from '../CalendarCtx';
import { handleDateCellClick } from '../Events/calendar-events';
import { CalendarGridMap, Day, Hour } from '../types';
import { useCalendarColors } from '../use-calendar-colors';
import { getWeekGridPosition } from '../Views/Week/utils/week-grid-map-utils';

interface Props {
  day: Day;
  hour: Hour;
  gridMap: CalendarGridMap;
}

export const TallCalendarHourBox = ({ day, hour, gridMap }: Props) => {
  const {
    ID: calendarID,
    view: [view],
  } = useCalendarCtx();
  const { borders, fill } = useCalendarColors();
  const didDayShift = useMemo(
    () => hour.dayShift >= 1 || hour.dayShift <= -1,
    [hour.dayShift]
  );

  const date = useMemo(() => {
    if (didDayShift) {
      const addShift = addDays(day.date, hour.dayShift);
      const setToStart = startOfDay(addShift);
      const setToHours = addHours(setToStart, hour.numericalValue);

      return setToHours;
    }

    const setToStart = startOfDay(day.date);
    const setToHours = addHours(setToStart, hour.numericalValue);

    return setToHours;
  }, [day.date, didDayShift, hour.dayShift, hour.numericalValue]);

  const key = useMemo(() => {
    if (didDayShift) {
      const newDate = addDays(day.date, hour.dayShift);
      const newKey = format(newDate, 'yyyy-MM-dd');

      return `${newKey}/${hour.key}`;
    }

    return `${day.key}/${hour.key}`;
  }, [day.date, day.key, didDayShift, hour.dayShift, hour.key]);

  const pos = useMemo(() => getWeekGridPosition(key, gridMap), [gridMap, key]);

  return (
    <Box
      onClick={(e) => {
        const [day, hour] = key.split('/');
        handleDateCellClick({
          rawEvent: e,
          calendarID,
          date,
          formattedDay: day,
          view,
          formattedHour: hour,
          hasHour: true,
        });
      }}
      borderRight="1px solid"
      borderTop="1px solid"
      borderColor={borders}
      bg={fill}
      gridColumn={`${pos.column.start} / ${pos.column.end}`}
      gridRow={`${pos.row.start} / ${pos.row.end}`}
    />
  );
};
