import { Api } from 'BootQuery/Assets/js/api';

import { Ticket, TicketWithDependers } from '../../types';

export const getTicket = async (ID: number) => {
  const data = await Api.get<Ticket>(`/api/ticketing/tickets/${ID}`);

  return data;
};

export const getTicketWithDependers = async (ID: number) => {
  const { data } = await Api.get<TicketWithDependers>(
    `/api/ticketing/tickets/${ID}`,
    {
      params: { fields: ['$full', 'dependers.depender.$full'] },
    }
  );

  return data;
};
