import { FormEvent, ReactElement, useCallback, useContext, useId } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPhoneAlt } from 'react-icons/fa';

import { dial } from 'app/Modules/Telephony/Assets/components/Dialer/store/dialer';
import { DialInterceptorContext } from 'app/Modules/Telephony/Assets/components/DialInterceptor';
import { CancelButton } from 'BootQuery/Assets/components/CancelButton';

import { CallNumberSelector, Option } from './CallNumberSelector';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AddCallModal = ({ isOpen, onClose }: Props): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const formId = useId();
  const { onDial } = useContext(DialInterceptorContext);
  const formMethods = useForm<CallForm>();
  const { handleSubmit } = formMethods;

  const onSubmit = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      ev.stopPropagation();

      handleSubmit((data: Partial<CallForm>) => {
        if (data.phoneNumber?.value) {
          dial(data.phoneNumber.value).then((resp) => onDial && onDial(resp));
        }
        onClose();
      })(ev);
    },
    [handleSubmit, onClose, onDial]
  );

  return (
    <Modal size="6xl" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center">
          <FaPhoneAlt style={{ display: 'inline' }} />
          &nbsp;
          {t('Ticketing:add_call')}
        </ModalHeader>
        <ModalBody>
          <Box minHeight="xs">
            <FormProvider {...formMethods}>
              <form
                data-ignore-form
                style={{ width: '100%' }}
                id={formId}
                onSubmit={onSubmit}
              >
                <CallNumberSelector clientInfo={null} />
              </form>
            </FormProvider>
          </Box>
        </ModalBody>
        <ModalFooter justifyContent="flex-end">
          <CancelButton onClick={onClose} />
          <Button type="submit" colorScheme="green" form={formId}>
            <FaPhoneAlt style={{ display: 'inline' }} />
            &nbsp;
            {t('Ticketing:confirm_call')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export interface CallForm {
  phoneNumber: Option;
}
