import { useParams } from 'react-router-dom';

type RouteParamsWithTicketId = {
  ticketID?: string;
};

export function useTicketId(): number | null {
  const { ticketID } = useParams<RouteParamsWithTicketId>();
  const numericID = parseInt(ticketID ?? '', 10);

  return Number.isNaN(numericID) ? null : numericID;
}
