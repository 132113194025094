import { ReactElement, useCallback, useMemo } from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { TicketForm } from '../../types';
import { AgentSelect } from '../Agent/AgentSelect';
import { AgentSelectOption } from '../Agent/types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';

export const AgentField = (): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { viewOnly } = useTicketMutationContext();
  const { control } = useFormContext<TicketForm>();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({ control, name: 'assignedUser' });

  const agentValue: AgentSelectOption | null = useMemo(() => {
    if (!value) {
      return null;
    }

    return {
      type: 'user',
      value: value.ID,
      label: value.person?.fullName ?? value.username,
    };
  }, [value]);

  const handleChange = useCallback(
    (newVal: AgentSelectOption | null) => {
      const option = newVal
        ? {
            ID: newVal.value,
            username: newVal.label,
          }
        : null;

      onChange(option);
    },
    [onChange]
  );

  const group = useWatch({
    control,
    name: 'group',
  });

  // Clear agent on group change
  useChangeEffect(
    group.ID,
    () => {
      onChange(null);
    },
    [onChange]
  );

  return (
    <FormControl isInvalid={fieldState.invalid}>
      <FormLabel fontWeight="bold">{t('Ticketing:form.agent')}:</FormLabel>
      <AgentSelect
        groupID={group?.ID}
        isDisabled={viewOnly}
        value={agentValue}
        onChange={handleChange}
      />
      {fieldState.error && (
        <FormErrorMessage>
          {fieldState.error.message || fieldState.error.type}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
