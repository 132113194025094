import { ReactElement, useMemo } from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { Portal } from '@bq/components/Portal';

import { useTicketingContext } from '../TicketingContext';
import { CallbackTicket } from '../TicketingForm/Dependencies/types';
import { TicketType } from '../types';
import { createTicketLink } from '../utils';
import { AddSingleTypeTicket } from './AddSingleTypeTicket';
import { useCreateHandleClick } from './use-create-handle-click';

interface Props {
  modalOnly?: boolean;
  submitCallback?: (data: CallbackTicket) => void;
  parentID?: number;
  overviewSlug?: string;
  creatableTypeIds?: number[] | null;
}
export const AddNewTicket = ({
  modalOnly,
  submitCallback,
  parentID,
  overviewSlug,
  creatableTypeIds,
}: Props): ReactElement => {
  const { t } = useTranslation('Ticketing');

  const { types } = useTicketingContext();
  const creatableTypes = useCreatableTicketTypes(types, creatableTypeIds);

  if (creatableTypes?.length === 1) {
    const singleType = creatableTypes[0];

    return (
      <AddSingleTypeTicket
        type={singleType}
        modalOnly={modalOnly}
        overviewSlug={overviewSlug}
        submitCallback={submitCallback}
        parentID={parentID}
      />
    );
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<FaChevronDown />}
        colorScheme="green"
        isLoading={!creatableTypes}
      >
        {t('Ticketing:create_new_ticket')}
      </MenuButton>
      <Portal>
        <MenuList>
          {creatableTypes?.map((type) => (
            <CreateTicketMenuItem
              overviewSlug={overviewSlug}
              parentID={parentID}
              key={type.ID}
              {...type}
              modalOnly={modalOnly}
              submitCallback={submitCallback}
            />
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};

const CreateTicketMenuItem = ({
  modalOnly,
  submitCallback,
  parentID,
  overviewSlug,
  ...type
}: TicketType & {
  modalOnly?: boolean;
  parentID?: number;
  submitCallback?: (data: CallbackTicket) => void;
  overviewSlug?: string;
}): ReactElement => {
  const ticketLink = createTicketLink(overviewSlug, type);
  const handleClick = useCreateHandleClick({
    type,
    modalOnly,
    submitCallback,
    parentID,
  });

  if (modalOnly) {
    return <MenuItem onClick={handleClick}>{type.name}</MenuItem>;
  }

  return (
    <MenuItem as={Link} to={ticketLink} onClick={handleClick}>
      {type.name}
    </MenuItem>
  );
};

function useCreatableTicketTypes(
  types: TicketType[] | undefined,
  creatableTypeIds: number[] | null | undefined
): TicketType[] | undefined {
  return useMemo(() => {
    if (!types) {
      return types;
    }

    if (!creatableTypeIds) {
      return types;
    }

    return creatableTypeIds
      .map((id) => types.find((type) => type.ID === id))
      .filter((type): type is TicketType => !!type);
  }, [types, creatableTypeIds]);
}
