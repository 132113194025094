import { ButtonGroup, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaTrash, FaUndo, FaUnlink } from 'react-icons/fa';

import { ConfirmationModal } from '@bq/components/ConfirmationModal';
import { WithChange } from '@bq/components/SettingsCRUD';
import { useTicketModal } from '@bq/components/TicketModal';

import { deleteDependency } from '../../Api/delete-dep';
import { deleteTicket } from '../../Api/ticket/deleteTicket';
import { TicketRow } from '../../TicketTable/types';
import { ActionCallbacks } from './types';

interface Props {
  ticket: WithChange<TicketRow>;
  actionCallbacks: ActionCallbacks;
}
export const TicketDependencyActions = ({ ticket, actionCallbacks }: Props) => {
  const { t } = useTranslation('Ticketing');
  const { editTicketModal } = useTicketModal();

  return (
    <ButtonGroup isAttached size="sm" variant="ghost" alignSelf="flex-start">
      {ticket.change && actionCallbacks && actionCallbacks.onUndo && (
        <IconButton
          isDisabled={!ticket.change}
          aria-label="undo-change"
          icon={<FaUndo />}
          onClick={() => {
            if (actionCallbacks && actionCallbacks.onUndo) {
              actionCallbacks.onUndo({ ID: ticket.ID });
            }
          }}
        />
      )}
      <IconButton
        aria-label={t('global:edit')}
        icon={<FaEdit />}
        onClick={() => {
          editTicketModal({
            ID: ticket.ID,
            closeCallback: (data) => {
              if (actionCallbacks && actionCallbacks.onUpdate) {
                actionCallbacks.onUpdate(data);
              }
            },
          });
        }}
      />
      {ticket.parentID && (
        <ConfirmationModal
          icon={<FaUnlink />}
          results={[
            `${t('Ticketing:unlink_ticket')} ${t('global:for')} ${t(
              'Ticketing:ticket'
            )}: ${ticket.title}-#${ticket.ID}`,
          ]}
          onConfirm={async () => {
            if (ticket.parentID) {
              await deleteDependency(ticket.parentID, ticket.ID);
              if (actionCallbacks && actionCallbacks.onUnlinkDep) {
                actionCallbacks.onUnlinkDep({
                  ID: ticket.ID,
                  parentID: ticket.ID,
                });
              }
            }
          }}
        >
          <IconButton
            icon={<FaUnlink />}
            aria-label={t('Ticketing:unlink_ticket')}
          />
        </ConfirmationModal>
      )}
      <ConfirmationModal
        results={[
          `${t('global:delete')} ${t('Ticketing:ticket')}: ${ticket.title}-#${
            ticket.ID
          }`,
        ]}
        icon={<FaTrash />}
        onConfirm={async () => {
          await deleteTicket(ticket.ID);
          if (actionCallbacks && actionCallbacks.onDelete) {
            actionCallbacks.onDelete({
              ID: ticket.ID,
              parentID: ticket.parentID,
            });
          }
        }}
      >
        <IconButton aria-label={t('global:delete')} icon={<FaTrash />} />
      </ConfirmationModal>
    </ButtonGroup>
  );
};
