import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaPlus } from 'react-icons/fa';

import { parseToDate } from 'BootQuery/Assets/js/dateUtils';

import { useCalendarCtx } from '../../CalendarCtx';
import { handleShowMoreClick } from '../../Events/calendar-events';
import { CalendarShowMoreButton } from '../../types';

export const MonthViewShowMoreButton = ({
  column,
  row,
  countAfter,
  date,
}: CalendarShowMoreButton) => {
  const {
    maxItemsPerCell: [maxItems],
    currentDate: [_],
    ID,
  } = useCalendarCtx();

  const { t } = useTranslation();

  return (
    <Button
      colorScheme="brand"
      h="20px"
      w="full"
      justifyContent="space-between"
      variant="ghost"
      gridColumn={`${column.start} / ${column.end}`}
      gridRow={`${row.start} / ${row.end}`}
      pos="relative"
      fontSize="xs"
      top={`${maxItems * 21}px`}
      cursor="pointer"
      rightIcon={<FaChevronDown />}
      leftIcon={<FaPlus />}
      onClick={() => {
        const toDate = parseToDate(date);
        handleShowMoreClick({ calendarID: ID, targetDate: toDate });
      }}
    >
      {countAfter} {t('global:more')}
    </Button>
  );
};
