import { useCallback } from 'react';

import { CalendarEvent } from 'app/Modules/Events/Assets/components';

import { CalendarItem } from '../types';
import { CalendarEventProviderMap } from './use-event-type-providers';

export const useCalendarEventTransformator = (
  calendarEventProviderMap: CalendarEventProviderMap
) => {
  return useCallback(
    (items: CalendarEvent[]): CalendarItem[] => {
      return items.map((item) => {
        const definition = calendarEventProviderMap?.[item.type];
        if (!definition) {
          throw Error(`Couldn't find definition for type: ${item.type}`);
        }

        return definition.transformFn(item);
      });
    },
    [calendarEventProviderMap]
  );
};
