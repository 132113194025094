import { EventMenus } from 'app/Modules/Events/Assets/components';

import { UseEventChangesResult } from '../use-event-changes';
import { useCallEventMenus } from './use-call-event-menus';
import { useChatEventMenus } from './use-chat-event-menus';
import { useMailEventMenus } from './use-mail-event-menus';

export const useTicketEventMenus = (
  eventChanges: UseEventChangesResult
): EventMenus => {
  return {
    call: useCallEventMenus(eventChanges),
    mail: useMailEventMenus(eventChanges),
    customerChat: useChatEventMenus(eventChanges),
  };
};
