import { useCallback } from 'react';

import { addEventToTicket, removeEventFromTicket } from './Api';
import { EventData } from './types';
import { useFormEventChanges } from './use-form-event-changes';
import { useTicketId } from './use-ticket-id';

export interface UseEventChangesResult {
  addTicketEvent: (event: EventData) => void;
  removeTicketEvent: (event: EventData) => void;
}

type RemoveEvent = (type: string, id: string) => void;

export function useEventChanges(
  removeEvent: RemoveEvent
): UseEventChangesResult {
  const ticketID = useTicketId();
  const { addFormEvent, removeFormEvent } = useFormEventChanges();

  const addTicketEvent = useCallback(
    (event: EventData) => {
      if (ticketID) {
        addEventToTicket(ticketID, event);
      } else {
        addFormEvent(event);
      }
    },
    [ticketID, addFormEvent]
  );

  const removeTicketEvent = useCallback(
    (event: EventData) => {
      if (ticketID) {
        removeEventFromTicket(ticketID, event);
      } else {
        removeFormEvent(event);
      }

      switch (event.type) {
        case 'call':
          removeEvent(event.type, event.callID.toString());
          break;
        case 'mail':
          removeEvent(event.type, event.mailID.toString());
          break;
        case 'chat':
          removeEvent(event.type, event.chatID.toString());
          break;
        case 'custom':
          removeEvent(event.type, event.eventID.toString());
          break;
        case 'appointment':
          removeEvent(event.type, event.appointmentID.toString());
          break;
        default:
          break;
      }
    },
    [ticketID, removeFormEvent, removeEvent]
  );

  return { addTicketEvent, removeTicketEvent };
}
