import { ReactElement } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { TicketForm } from '../../types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';
import { Reminders } from '../Reminders/Reminders';

export const RemindersField = (): ReactElement => {
  const { viewOnly } = useTicketMutationContext();
  const { control } = useFormContext<TicketForm>();
  const reminders = useController({ control, name: 'reminders' });

  return <Reminders {...reminders} isDisabled={viewOnly} />;
};
