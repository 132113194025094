import { useMemo } from 'react';
import { format } from 'date-fns';

import { useCalendarCtx } from '@bq/components/Calendar/CalendarCtx';
import { Day } from '@bq/components/Calendar/types';

import { generateLocalizedHours } from '../../Week/utils/generate-localized-hours';
import { generateWeekGridMap } from '../../Week/utils/week-grid-map-utils';
import { weekGridWithDays } from '../../Week/utils/week-grid-with-days';
import { getWeekItems } from '../../Week/utils/week-items';
import { getWholeDayEvents } from '../../Week/utils/whole-day-events';

export const useSetupDayCalendar = () => {
  const {
    locale,
    dateRange,
    items,
    firstDayOfWeek: [weekStartsOn],
    maxItemsPerCell: [maxItems],
  } = useCalendarCtx();

  const localizedHours = useMemo(
    () => generateLocalizedHours(locale),
    [locale]
  );
  const day: Day[] = useMemo(
    () => [
      {
        key: format(dateRange.start, 'yyyy-MM-dd'),
        date: dateRange.start,
      },
    ],
    [dateRange.start]
  );

  const gridMap = generateWeekGridMap(day, localizedHours, 1);
  const weekGridMapWithDays = useMemo(
    () => weekGridWithDays(gridMap, day, 1),
    [day, gridMap]
  );

  const hourItems = useMemo(() => {
    return getWeekItems({
      gridMap: weekGridMapWithDays,
      items,
      maxItems,
      range: dateRange,
      weekStartsOn,
      hourShift: 0,
      days: day,
      hours: localizedHours,
    });
  }, [
    dateRange,
    day,
    items,
    localizedHours,
    maxItems,
    weekGridMapWithDays,
    weekStartsOn,
  ]);

  const weekItems = useMemo(() => {
    return getWholeDayEvents({
      gridMap: weekGridMapWithDays,
      items: hourItems.wholeDayEvents,
      maxItems,
      range: dateRange,
      weekStartsOn,
    });
  }, [
    dateRange,
    hourItems.wholeDayEvents,
    maxItems,
    weekGridMapWithDays,
    weekStartsOn,
  ]);

  return {
    localizedHours,
    gridMap,
    day,
    weekGridMapWithDays,
    hourItems,
    weekItems,
  };
};
