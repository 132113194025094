import { Api } from 'BootQuery/Assets/js/api';
import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import { PostTicket } from '../../types';

export const postTicket = async <CallbackTicket>(
  allData: Partial<PostTicket>,
  params?: GetListingParams
) => {
  const { data } = await Api.post<CallbackTicket>(
    '/api/ticketing/tickets',
    allData,
    { params }
  );

  return data;
};
