import { CalendarItemSplitted } from '@bq/components/Calendar/types';
import { populateWeek } from '@bq/components/Calendar/Utils/populate-week';
import { populateWeekShowMoreButtons } from '@bq/components/Calendar/Utils/populate-week-show-more-buttons';

import { generateWeekDatesWithAvaliableSpaces } from './gen-week-with-avaliable-spaces';
import { generateNumberOfItems } from './generate-number-of-items';
import { generateShowMoreFlags } from './generate-show-more-flags';
import { CalendarLayoutItems, PopulateCalendarParams, WeekKey } from './types';

export const populateCalendar = ({
  gridMap,
  maxItems,
  range,
  sortedItems,
}: PopulateCalendarParams): CalendarLayoutItems => {
  return Object.entries(sortedItems).reduce<CalendarLayoutItems>(
    (all, [key, { items }]) => {
      // Unfortunaly TS didn't read correctly the type so we cast it
      const typecastKey = key as WeekKey;
      const typeCastItems = items as CalendarItemSplitted[];
      // This is a map of all the avaliable spaces for every day in the week
      const weekSpacesMap = generateWeekDatesWithAvaliableSpaces(
        typecastKey,
        maxItems
      );
      // This is map with flags if we already put a show more button
      const showMoreButtonMap = generateShowMoreFlags(typecastKey);
      const noOfItemsInEachColumn = generateNumberOfItems(typecastKey);

      // first pass go through all items and place those for which
      // we have space
      const { newItems, showMoreButtonMap: showMoreButtonMapUpdated } =
        populateWeek({
          gridMap,
          showMoreButtonMap,
          items: typeCastItems,
          range,
          weekSpacesMap,
          noOfItemsInEachColumn,
        });
      // second pass add the show more buttons
      // insert show more buttons
      const showMoreButtons = populateWeekShowMoreButtons({
        showMoreButtonMap: showMoreButtonMapUpdated,
        gridMap,
        range,
      });

      return [...all, ...newItems, ...showMoreButtons];
    },
    []
  );
};
