import { Button, HStack, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaSave, FaUndo } from 'react-icons/fa';

import { useTicketMutationContext } from '../TicketingForm/FormWrapper/TicketMutationContext';
import { TicketForm } from '../types';

export const SimpleSaveAction = () => {
  const { t } = useTranslation();
  const { reset } = useFormContext<TicketForm>();
  const { formState } = useTicketMutationContext();

  return (
    <HStack justifyContent="flex-end">
      {formState === 'error' && (
        <Text as="span" color="red.500">
          {t('global:save_error')}
        </Text>
      )}
      {formState === 'saved' && (
        <Text as="span" color="green.500">
          {t('global:save_success')}
        </Text>
      )}
      <Button
        colorScheme="red"
        variant="outline"
        leftIcon={<FaUndo />}
        onClick={() => {
          reset();
        }}
      >
        {t('Ticketing:save_actions.undo_changes')}
      </Button>
      <Button
        type="submit"
        colorScheme="brand"
        isLoading={formState === 'saving'}
        leftIcon={<FaSave />}
      >
        {t('global:save')}
      </Button>
    </HStack>
  );
};
