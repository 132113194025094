import { PropsWithChildren } from 'react';

import { useCalendarEventReducer } from './calendar-event-reducer';

export const CalendarEventProvider = ({ children }: PropsWithChildren) => {
  // I just want to use context this just does that
  useCalendarEventReducer();

  return <>{children}</>;
};
