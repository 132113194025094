import { formatDuration } from 'app/assets/js/format-duration';

export function durationFromSeconds(durationSecs: number): string {
  return formatDuration(durationSecs, true);
}

export function durationToSeconds(duration: string): number {
  const segments = duration.split(':');

  if (segments.length !== 3) {
    throw new Error('Invalid duration format');
  }

  return (
    parseInt(segments[0], 10) * 3600 +
    parseInt(segments[1], 10) * 60 +
    parseInt(segments[2], 10)
  );
}
