import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { User } from '../TicketingForm/Agent/types';
import { TicketGroup } from '../types';
import { UserOrGroupSelectOption } from './types';

export async function searchUsersGroups(
  search: string
): Promise<UserOrGroupSelectOption[]> {
  const [users, groups] = await Promise.all([
    searchUsers(search),
    searchGroups(search),
  ]);

  return [...groups, ...users];
}

async function searchUsers(search: string): Promise<UserOrGroupSelectOption[]> {
  const { data } = await Api.get<ListingResponse<User>>('/api/users', {
    params: {
      filters: {
        $search: search.trim(),
      },
      withCount: false,
    },
  });

  return data.data.map((user) => ({
    type: 'user',
    label: user.username,
    value: `user-${user.ID}`,
    ID: user.ID,
  }));
}

async function searchGroups(
  search: string
): Promise<UserOrGroupSelectOption[]> {
  const { data } = await Api.get<TicketGroup[]>('/api/ticketing/groups', {
    params: {
      filters: {
        $search: search.trim(),
      },
      fields: ['ID', 'name'],
    },
  });

  return data.map((group) => ({
    type: 'group',
    label: group.name,
    value: `group-${group.ID}`,
    ID: group.ID,
  }));
}
