import { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { format } from 'date-fns';
import { UseMeasureRef } from 'react-use/lib/useMeasure';

import { useCalendarCtx } from '../CalendarCtx';
import { handleDateCellClick } from '../Events/calendar-events';
import { CalendarGridMap, Day } from '../types';
import { useCalendarColors } from '../use-calendar-colors';
import { getWeekGridPosition } from '../Views/Week/utils/week-grid-map-utils';

interface Props {
  measureRef?: UseMeasureRef<HTMLDivElement>;
  day: Day;
  gridMap: CalendarGridMap;
}
export const TallCalendarWholeDayBox = ({
  day,
  gridMap,
  measureRef,
}: Props) => {
  const {
    ID: calendarID,
    view: [view],
  } = useCalendarCtx();

  const pos = useMemo(
    () => getWeekGridPosition(day.key, gridMap),
    [day.key, gridMap]
  );
  const { fill, borders } = useCalendarColors();

  return (
    <Box
      onClick={(e) => {
        const dayF = format(day.date, 'yyyy-MM-dd');
        handleDateCellClick({
          calendarID,
          date: day.date,
          hasHour: false,
          view,
          formattedDay: dayF,
          rawEvent: e,
        });
      }}
      borderRight="1px solid"
      borderTop="1px solid"
      borderColor={borders}
      bg={fill}
      gridColumn={`${pos.column.start} / ${pos.column.end}`}
      gridRow={`${pos.row.start} / ${pos.row.end}`}
      ref={measureRef}
    />
  );
};
