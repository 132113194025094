import { useQuery } from '@tanstack/react-query';
import { AsyncSelect, SingleValue } from 'chakra-react-select';

import { SelectOption } from 'BootQuery/Assets/js/globalTypes';

import { getReportMethod } from '../../Api';
import { loadOptions, reportMethodToOption } from './utils';

interface Props {
  onChange: (newValue: SingleValue<SelectOption<number>>) => void;
  value: SelectOption<number> | number | null;
  isDisabled?: boolean;
}

export const ReportMethodSelect = ({ onChange, value, isDisabled }: Props) => {
  const valueIsNumber = typeof value === 'number';
  const { data: loadedData } = useQuery({
    queryKey: ['reportMethodLoadOne', value],
    queryFn: async () => {
      if (valueIsNumber) {
        const { data } = await getReportMethod(value);

        return reportMethodToOption(data);
      }
      throw Error('Unkown error');
    },
    enabled: valueIsNumber,
  });

  return (
    <AsyncSelect<SelectOption<number>>
      isDisabled={isDisabled}
      size={{ base: 'lg', md: 'sm', lg: 'sm' }}
      useBasicStyles
      value={valueIsNumber ? loadedData : value}
      cacheOptions
      defaultOptions
      onChange={onChange}
      loadOptions={loadOptions}
      selectedOptionColorScheme="brand"
    />
  );
};
