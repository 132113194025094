import { differenceInDays, endOfWeek, format, startOfWeek } from 'date-fns';

import { generateWeeksBetweenDates } from './generate-weeks-between-dates';
import { readPreviousInstance } from './shared-utils';
import { MonthItemsParams, WeekKey, WeeksMap } from './types';

export const handleOneDayEvent = ({
  dates,
  item,
  weekStartsOn,
  weeksMap,
}: MonthItemsParams): WeeksMap => {
  const weekStart = startOfWeek(dates.startDate.rawDate, { weekStartsOn });
  const weekEnd = endOfWeek(dates.endDate.rawDate, { weekStartsOn });
  const formatWeekStart = format(weekStart, 'yyyy-MM-dd');
  const formatWeekEnd = format(weekEnd, 'yyyy-MM-dd');
  const weekKey: WeekKey = `${formatWeekStart}/${formatWeekEnd}`;

  const { items: previousItems } = readPreviousInstance(weeksMap, weekKey);

  return {
    ...weeksMap,
    // we don't have to calculate the duration here we know it's 0 (one day)
    [weekKey]: { items: [...previousItems, { ...item, duration: 0 }] },
  };
};

export const handleMultipleDayEventSameWeek = ({
  dates,
  item,
  weekStartsOn,
  weeksMap,
}: MonthItemsParams) => {
  const weekStart = startOfWeek(dates.startDate.rawDate, { weekStartsOn });
  const weekEnd = endOfWeek(dates.endDate.rawDate, { weekStartsOn });
  const formatWeekStart = format(weekStart, 'yyyy-MM-dd');
  const formatWeekEnd = format(weekEnd, 'yyyy-MM-dd');
  const weekKey: WeekKey = `${formatWeekStart}/${formatWeekEnd}`;

  const { items: previousItems } = readPreviousInstance(weeksMap, weekKey);
  const duration = differenceInDays(
    dates.endDate.rawDate,
    dates.startDate.rawDate
  );

  return {
    ...weeksMap,
    [weekKey]: {
      items: [
        ...previousItems,
        {
          ...item,
          duration,
        },
      ],
    },
  };
};

export const handleMultipleDayEventDifferentWeek = ({
  dates,
  item,
  weekStartsOn,
  weeksMap,
}: MonthItemsParams) => {
  const weeksBetween = generateWeeksBetweenDates(
    dates.startDate.rawDate,
    dates.endDate.rawDate,
    weekStartsOn
  );

  return weeksBetween.reduce<WeeksMap>(
    (all, { startDate: weekStart, endDate: weekEnd }, weekIdx) => {
      const isFirstWeek = weekIdx === 0;
      const isLastWeek = weekIdx === weeksBetween.length - 1;
      const weekKey: WeekKey = `${weekStart.formatted}/${weekEnd.formatted}`;
      const { items: previousItems } = readPreviousInstance(weeksMap, weekKey);

      const eventStart = isFirstWeek
        ? dates.startDate.rawDate
        : weekStart.rawDate;
      const eventEnd = isLastWeek ? dates.endDate.rawDate : weekEnd.rawDate;

      const duration = differenceInDays(eventEnd, eventStart);

      return {
        ...all,
        [weekKey]: {
          items: [
            ...previousItems,
            {
              ...item,
              startDate: eventStart,
              endDate: eventEnd,
              duration,
              splitInfo: {
                instanceOf: item,
                numberOfChunksSplitInto: weeksBetween.length,
              },
            },
          ],
        },
      };
    },
    weeksMap
  );
};
