import { ReactElement, useMemo } from 'react';
import { addDays, addMinutes } from 'date-fns';

import { DateCellClickEvent } from '@bq/components/Calendar';

import {
  AppointmentEventsProvider,
  useAppointmentEvents,
} from '../Appointment/AppointmentEventsProvider';
import { AppointmentFormModal } from '../Appointment/AppointmentFormModal';

export const AppointmentCreateModalContent = ({
  date,
  hasHour,
  topOffsetPercent,
}: DateCellClickEvent): ReactElement => {
  const handlers = useAppointmentEvents();

  const calcRelativeStart = useMemo(() => {
    if (hasHour) {
      const hourPercent = topOffsetPercent / 100;
      const minutes = 60 * hourPercent;
      const roundMinutes = roundToNearest15(minutes);

      return addMinutes(date, roundMinutes);
    }

    return date;
  }, [date, hasHour, topOffsetPercent]);

  const calcRelativeEnd = useMemo(() => {
    if (hasHour) {
      return addMinutes(calcRelativeStart, 30);
    }

    return addDays(calcRelativeStart, 1);
  }, [calcRelativeStart, hasHour]);

  return (
    <AppointmentEventsProvider {...handlers}>
      <AppointmentFormModal
        mode="create"
        defaultValues={{ startAt: calcRelativeStart, endAt: calcRelativeEnd }}
      />
    </AppointmentEventsProvider>
  );
};

const roundToNearest15 = (minutes: number) => {
  return Math.round(minutes / 15) * 15;
};
