import { ReactElement, useCallback, useMemo } from 'react';
import { Box, Button, Flex, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { defaultDisplayModeOptions } from '@bq/components/TableMenu/default-display-mode';
import { useLoadCallListSettings } from 'app/Modules/Telephony/Assets/components/CallList';
import { CallListSettingsProvider } from 'app/Modules/Telephony/Assets/components/CallList/CallListSettingsContext';
import { AddButton } from 'BootQuery/Assets/components/AddButton';
import {
  FilterExpression,
  useFilterExpression,
} from 'BootQuery/Assets/components/FilterBar';
import { ListHeading } from 'BootQuery/Assets/components/ListHeading';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import {
  DisplayMode,
  TableDensity,
} from 'BootQuery/Assets/components/TableMenu';
import { useQueryFilters } from 'BootQuery/Assets/js/use-filters';
import { useScrollContainerRef } from 'BootQuery/Assets/js/use-scroll-container-ref';
import { useUserSetting } from 'BootQuery/Assets/js/user-settings';

import { CustomEventModal, useCustomEventModal } from '../CustomEventModal';
import { Event } from '../types';
import { useEvents } from '../use-events/use-events';
import { useOverviews } from '../use-overviews';
import { useSelectedOverview } from '../use-selected-overview';
import { EventCard } from './EventCard';
import { DeleteCustomEvent, EventListContext } from './EventListContext';
import { EventsTable } from './EventsTable';
import { filterTypes } from './filter-types';

export const EventList = (): ReactElement => {
  const { t } = useTranslation('Events');
  const { data: overviews } = useOverviews();
  const overview = useSelectedOverview(overviews ?? []);
  const [displayMode, setDisplayMode] = useDisplayMode();
  const [density, setDensity] = useDensity();
  const [filterValues, setFilterValues, search, setSearch] =
    useQueryFilters(filterTypes);
  const { data: filters } = useFilterExpression(filterTypes, filterValues);
  const containerRef = useScrollContainerRef();
  const eventQuery = useEvents(
    {
      filters: {
        ...useKVFilters(filters, search),
        ...(overview?.filters ?? {}),
      },
    },
    containerRef
  );
  const { events, refetch, fetchMore, isFetching, hasMore } = eventQuery;
  const { data: callListSettings } = useLoadCallListSettings();

  const onAdded = useCallback(() => {
    refetch();
  }, [refetch]);
  const { addCustomEvent, editCustomEvent, modalProps } = useCustomEventModal({
    onAdded,
  });

  const deleteCustomEvent: DeleteCustomEvent = useCallback(() => {
    console.log('deleteEvent');
  }, []);

  const add = useCallback(() => {
    addCustomEvent({});
  }, [addCustomEvent]);

  const addEvent = (event: Event) => {
    /* TODODO */
  };

  return (
    <EventListContext.Provider
      value={{
        eventQuery,
        addCustomEvent,
        editCustomEvent,
        deleteCustomEvent,
        addEvent,
        refetch,
      }}
    >
      <VStack alignItems="stretch" spacing={6}>
        <Box my={9} px={5}>
          <ListHeading
            noPagination
            filterProps={{
              filterTypes,
              filters: filterValues,
              setFilters: setFilterValues,
              search,
              setSearch,
            }}
            menuProps={{
              displayMode: {
                value: displayMode,
                onChange: setDisplayMode,
                options: defaultDisplayModeOptions,
              },
              density: { value: density, onChange: setDensity },
            }}
            Actions={<AddButton variant="outline" onClick={add} />}
          />
        </Box>
        <VStack alignItems="stretch" ref={containerRef}>
          {events && callListSettings !== undefined ? (
            <CallListSettingsProvider {...callListSettings}>
              {displayMode === 'card' ? (
                events.map((event, idx) => <EventCard key={idx} {...event} />)
              ) : (
                <EventsTable events={events} size={density} />
              )}
              {hasMore && (
                <Flex justifyContent="center">
                  <Button isLoading={isFetching} onClick={fetchMore}>
                    {t('global:load_more')}
                  </Button>
                </Flex>
              )}
            </CallListSettingsProvider>
          ) : (
            <LoadingPage />
          )}
        </VStack>
        <CustomEventModal {...modalProps} />
      </VStack>
    </EventListContext.Provider>
  );
};

type KV = Record<string, unknown>;
function useKVFilters(
  filters: FilterExpression[] | undefined,
  search = ''
): KV {
  return useMemo(() => {
    const init = search ? { $search: search } : {};
    if (!filters) {
      return init;
    }

    return { ...init, ...getKVFilters(filters) };
  }, [filters, search]);
}

function getKVFilters(filters: FilterExpression[]): KV {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return filters.reduce((prev: KV, filter: any) => {
    if (!filter) {
      return prev;
    }

    const objFilter = '$and' in filter ? getKVFilters(filter.$and) : filter;

    return { ...prev, ...objFilter };
  }, {} as KV);
}

function useDisplayMode(defaultMode: DisplayMode = 'table') {
  return useUserSetting<DisplayMode>('Events.displayMode', defaultMode, false);
}

function useDensity(defaultVal: TableDensity = 'md') {
  return useUserSetting('Events.density', defaultVal, false);
}
