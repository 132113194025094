import { useMemo } from 'react';
import { format } from 'date-fns';

import { CalendarGridMap } from '@bq/components/Calendar/types';

import { useCalendarGridPos } from './calendar-grid-pos';

export const useCalendarGridDateMap = (currentDates: Date[]) => {
  const calcPos = useCalendarGridPos();
  const gridDateMap = useMemo(() => {
    return currentDates.reduce<CalendarGridMap>((all, current, idx) => {
      const formattedDate = format(current, 'yyyy-MM-dd');
      const calculatedPosition = calcPos(idx);

      return { ...all, [formattedDate]: calculatedPosition };
    }, {});
  }, [calcPos, currentDates]);

  return gridDateMap;
};
