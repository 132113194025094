import { ReactElement } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { TicketForm } from '../../types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';
import { TicketLinks } from '../Links/TicketLinks';

export const LinksField = (): ReactElement => {
  const { viewOnly } = useTicketMutationContext();
  const { control } = useFormContext<TicketForm>();
  const links = useController({
    control,
    name: 'links',
    rules: {
      validate: {
        itemNotNull: (v) => {
          const addIsNullOrTempID = v.add.some((item) => {
            return (
              item === null ||
              typeof item?.otherTicket?.ID === 'string' ||
              typeof item?.otherTicket?.ID === 'undefined'
            );
          });
          const delIsNullOrTempID = v.del.some((item) => {
            return (
              item === undefined ||
              typeof item === 'string' ||
              typeof item === 'undefined'
            );
          });

          return !(addIsNullOrTempID || delIsNullOrTempID);
        },
      },
    },
  });

  return <TicketLinks {...links} isDisabled={viewOnly} />;
};
