import { ModalBody, ModalContent, ModalHeader } from '@chakra-ui/react';

import { TicketingProvider } from 'app/Modules/Ticketing/Assets';

import { TypeSelectHeader } from './TypeSelectHeader';
import { TicketModalTypeSelectBody } from './TypeSelectModalBody';

export const TypeSelectModalContent = () => {
  return (
    <TicketingProvider>
      <ModalContent>
        <ModalHeader p={0}>
          <TypeSelectHeader />
        </ModalHeader>
        <ModalBody>
          <TicketModalTypeSelectBody />
        </ModalBody>
      </ModalContent>
    </TicketingProvider>
  );
};
