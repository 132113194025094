import { ReactElement } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { TicketForm } from '../../types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';
import { Shares } from '../Shares/Shares';

export const SharesField = (): ReactElement => {
  const { viewOnly } = useTicketMutationContext();
  const { control } = useFormContext<TicketForm>();
  const shares = useController({ control, name: 'shares' });

  return <Shares {...shares} isDisabled={viewOnly} />;
};
