import { ReactElement, SetStateAction, Suspense } from 'react';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Switch,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaClone } from 'react-icons/fa6';

import { findItem } from '@bq/components/dnd-util';
import { IconButton } from '@bq/components/IconButton';
import { LoadingPage } from '@bq/components/LoadingPage';
import { applySetState } from 'app/assets/js/apply-set-state';

import { FilterEditor } from '../../FilterBar/FilterEditor';
import { FilterTypes } from '../../FilterBar/types';
import { GroupVisibilityMap, IOverviewEditorItem } from '../types';
import { GroupVisibilitySettings } from './GroupVisibilitySettings';
import { useOverviewEditorContext } from './OverviewEditorContext';

interface OverviewSettingsProps {
  filterTypes: FilterTypes;
}

export const OverviewSettings = ({
  filterTypes,
}: OverviewSettingsProps): ReactElement => {
  const { t } = useTranslation();

  const {
    selectedId,
    withGroupVisibility,
    modifyItem,
    duplicateItem,
    dragItems,
  } = useOverviewEditorContext();

  const foundSelected = selectedId ? findItem(dragItems, selectedId) : null;
  const selected = foundSelected?.item?.content;
  if (!selectedId || !selected) {
    return <h1>{t('global:overviews.select_overview_to_edit')}</h1>;
  }

  if (selected.type === 'root') {
    throw new Error('Tried to render root item as OverviewSettings');
  }

  const set = (changes: Partial<IOverviewEditorItem>) => {
    modifyItem(selectedId, (prev) => {
      if (prev.content.type === 'root') {
        throw new Error('Tried to modify root item');
      }

      return { ...prev, content: { ...prev.content, ...changes } };
    });
  };
  const setGroupVisibility = (change: SetStateAction<GroupVisibilityMap>) => {
    set({
      groupVisibility: applySetState(selected.groupVisibility ?? {}, change),
    });
  };
  const duplicate = () => {
    duplicateItem(selectedId);
  };

  return (
    <Flex direction="column" w="full" height="full" overflow="hidden">
      <VStack
        borderBottomWidth="thin"
        marginBottom="2"
        paddingRight="4"
        flex="0 1 auto"
      >
        <HStack width="full" alignItems="end">
          <FormControl id="overview-title">
            <Flex alignContent="center">
              <FormLabel
                display="flex"
                alignItems="center"
                justifyContent="end"
                w="20%"
                mb={0}
              >
                {t('global:name')}:
              </FormLabel>
              <Box w="80%">
                <Input
                  value={selected.title}
                  onChange={(ev) => set({ title: ev.target.value })}
                />
              </Box>
            </Flex>
          </FormControl>
          <IconButton
            label={t('global:duplicate')}
            icon={<FaClone />}
            onClick={duplicate}
          />
        </HStack>
        <FormControl display="flex" alignContent="center" id="overview-visible">
          <FormLabel w="20%" textAlign="right">
            {t('global:overviews.visible')}:
          </FormLabel>
          <Box w="80%" h="10">
            <Switch
              isChecked={selected.visible}
              onChange={(ev) => set({ visible: ev.target.checked })}
            />
          </Box>
        </FormControl>
      </VStack>
      <Flex direction="column" overflowY="auto" flex="1 1 auto">
        <Suspense fallback={<LoadingPage />}>
          {withGroupVisibility && (
            <>
              <Box px="4">
                <GroupVisibilitySettings
                  value={selected.groupVisibility ?? {}}
                  onChange={setGroupVisibility}
                />
              </Box>
              <Divider />
            </>
          )}
          <Box px="4">
            <FormLabel fontWeight="bold">
              {t('global:overviews.filters')}:{' '}
            </FormLabel>
            <FilterEditor
              filterTypes={filterTypes}
              value={selected.filters}
              onChange={(filters) => set({ filters })}
            />
          </Box>
        </Suspense>
      </Flex>
    </Flex>
  );
};
