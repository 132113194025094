import { format, Locale } from 'date-fns';

import { Hour } from '@bq/components/Calendar/types';

export const generateLocalizedHours = (
  locale: Locale,
  startHour = 0
): Hour[] => {
  const now = new Date();

  // Ensure the startHour is within 0 to 23 range
  const adjustedStartHour = Math.max(0, Math.min(23, startHour));

  return Array.from({ length: 24 }, (_, i) => {
    // Calculate the correct hour, wrapping around if it exceeds 23
    const hour = (adjustedStartHour + i) % 24;
    const dayShift = Math.floor((adjustedStartHour + i) / 24);

    const dateWithHour = new Date(now.setHours(hour, 0, 0, 0));

    const localizedHour = format(dateWithHour, 'p', { locale });
    const key = format(dateWithHour, 'HH:mm'); // 24-hour format
    const numericalValue = dateWithHour.getHours();

    return { localizedHour, key, dayShift, numericalValue };
  });
};
