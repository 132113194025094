import { ValidCalendarItemsSplitByDay } from '@bq/components/Calendar/types';

export const sortDays = (
  items: ValidCalendarItemsSplitByDay
): ValidCalendarItemsSplitByDay => {
  return Object.keys(items)
    .sort()
    .reduce((acc, key) => {
      acc[key] = items[key];

      return acc;
    }, {} as ValidCalendarItemsSplitByDay);
};
