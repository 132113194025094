import { useState } from 'react';

import { Api } from 'BootQuery/Assets/js/api';

import { Ticket } from '../../types';
import { TicketWatchStar } from './TicketWatchStar';

interface followProps {
  watch: boolean | undefined;
  ticketID: number;
}

export const TicketEditFollow = ({ watch, ticketID }: followProps) => {
  const [watchTicket, setWatchTicket] = useState(watch);

  const toggleWatchTicket = async (): Promise<void> => {
    await Api.put<Ticket>(
      `/api/ticketing/tickets/${ticketID}/watch`,
      !watchTicket
    );

    setWatchTicket(!watchTicket);
  };

  return (
    <TicketWatchStar
      watchTicket={watchTicket}
      toggleWatchTicket={toggleWatchTicket}
    />
  );
};
