import { ReactElement } from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { add as addToDate } from 'date-fns';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ChakraDateInput } from '@bq/components/ChakraDateInput/ChakraDateInput';
import { parseToDate } from 'BootQuery/Assets/js/dateUtils';
import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { TicketForm } from '../../types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';

export const ResolveUntilField = (): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { viewOnly } = useTicketMutationContext();
  const { control, getValues } = useFormContext<TicketForm>();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({ control, name: 'resolveUntil' });
  const priority = useWatch({ control, name: 'priority' });

  useChangeEffect(
    priority?.ID,
    () => {
      const createdAt = parseToDate(getValues().createdAt || new Date());

      if (priority.resolutionInterval) {
        const resolveUntil = addToDate(createdAt, {
          seconds: priority.resolutionInterval,
        });
        onChange(resolveUntil.toISOString());
      } else {
        onChange(null);
      }
    },
    []
  );

  return (
    <FormControl isInvalid={fieldState.invalid}>
      <FormLabel fontWeight="bold">
        {t('Ticketing:form.resolve_until')}:
      </FormLabel>
      <ChakraDateInput
        showTime
        isDisabled={viewOnly}
        size={['lg', 'lg', 'sm']}
        value={value}
        onChange={onChange}
      />
      {fieldState.error && (
        <FormErrorMessage>
          {fieldState.error.message || fieldState.error.type}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
