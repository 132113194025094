import { chakraComponents, SingleValueProps } from 'chakra-react-select';

import { AgentSelectOption } from './types';

export const CurrentUserValue = (
  props: SingleValueProps<AgentSelectOption>
) => {
  const { label } = props.data;

  return (
    <chakraComponents.SingleValue {...props}>
      {label}
    </chakraComponents.SingleValue>
  );
};
