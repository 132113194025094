import { TicketWithDependencies } from '../../TicketTable/Dependencies';
import { Ticket } from '../../types';
import { TicketDependecyMutation } from './types';

export const treeQueryMutations = ({
  deps,
  mutation,
  currentParentID,
  searchingParent,
}: {
  deps: TicketWithDependencies[];
  mutation: TicketDependecyMutation;
  currentParentID?: number | null;
  searchingParent?: number | null;
}): TicketWithDependencies[] => {
  const { action, data } = mutation;
  const mutateChildren = (ticket: TicketWithDependencies) => ({
    ...ticket,
    dependencies: treeQueryMutations({
      deps: ticket.dependencies ?? [],
      mutation,
      currentParentID: ticket.ID,
      searchingParent,
    }),
  });
  switch (action) {
    case 'unlink':
      /** We delete only instance that has a specific parent */
      return deps
        .filter((item) => {
          return !(
            (item.ID === data.ID && currentParentID === searchingParent) ||
            (item.ID === data.ID && currentParentID === null)
          );
        })
        .map(mutateChildren);
    case 'delete':
      /** We delete all instances */
      return deps.filter((item) => !(item.ID === data.ID)).map(mutateChildren);
    case 'insert':
      /** We want to insert at a specific parent */
      if (
        currentParentID === searchingParent ||
        (currentParentID === null && searchingParent === null)
      ) {
        return [
          ...deps,
          {
            ...(data as unknown as Ticket),
            dependencies: data.dependencies ?? [],
          },
        ].map(mutateChildren);
      }

      return deps.map(mutateChildren);
    /** EXTREMLY IMPORTANT NOTE */
    /** TO AVOID ADDITIONAL COMPLICATIONS */
    /** IF YOU DON'T PASS DEPENDENCIES, OLD ONES WILL BE USED */
    /** DELETE IS COVERED BY OTHER CASES, SO PASSING EMPTY DEPS TO DELETE WONT DO */
    /** WHAT YOU EXPECT */
    case 'update':
      return deps
        .map((item) => {
          if (item.ID === data.ID) {
            return {
              ...item,
              ...(data as unknown as Ticket),
              dependencies: data.dependencies
                ? data.dependencies
                : item.dependencies,
            };
          }

          return item;
        })
        .map(mutateChildren);
    default:
      return deps.map(mutateChildren);
  }
};
