import { useUserSetting } from 'BootQuery/Assets/js/user-settings';

import { CalendarView } from './types';

export function useCurrentCalendarView(
  defaultMode: CalendarView = 'month',
  uniqueID: string | undefined = undefined
) {
  return useUserSetting<CalendarView>(
    uniqueID ? `Calendar-${uniqueID}.view` : 'Calendar.view',
    defaultMode,
    false
  );
}
