import {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useContext,
} from 'react';

interface ScrollRefCtxVal {
  scrollRef: MutableRefObject<HTMLDivElement | null>;
}
export const ScrollRefCtx = createContext<ScrollRefCtxVal | null>(null);

export const ScrollRefProvider = ({
  children,
  scrollRef,
}: PropsWithChildren<ScrollRefCtxVal>) => {
  return (
    <ScrollRefCtx.Provider value={{ scrollRef }}>
      {children}
    </ScrollRefCtx.Provider>
  );
};

export const useScrollRefCtx = () => {
  const usedCtx = useContext(ScrollRefCtx);
  if (!usedCtx) {
    throw Error('Missing ScrollRefCtx');
  }

  return usedCtx;
};
