import { Box, HStack, Text } from '@chakra-ui/react';

import { CellProps } from '@bq/components/Table';

import { CustomerContactDisplay } from '../../../TicketingContactDisplay/CustomerContactDisplay';
import { iconForTicketingContactType } from '../../../TicketingForm/Contact/utils';
import { Ticket } from '../../../types';
import { getContactName } from '../../../utils';

export const TicketContactCell = (props: CellProps<Ticket>) => {
  if (props.row.contact) {
    const { contact } = props.row.contact;
    const Icon = iconForTicketingContactType(contact.type);

    return (
      <HStack rounded="sm" w="full">
        <Box w="fit-content">{contact.type !== 'manual' && <Icon />}</Box>
        {contact.type === 'manual' ? (
          <Text>{contact.manualContact}</Text>
        ) : (
          <CustomerContactDisplay
            valueFontWeight="normal"
            contactID={contact.ID}
            contactType={contact.type}
            contactName={getContactName(contact)}
          />
        )}
      </HStack>
    );
  }

  return <>-</>;
};
