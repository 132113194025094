import { ReactElement, useCallback, useMemo } from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TicketForm } from '../../types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';
import { PriorityOption, PrioritySelect } from '../Priority';

export const PriorityField = (): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { viewOnly } = useTicketMutationContext();
  const { control } = useFormContext<TicketForm>();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({
    control,
    name: 'priority',
    rules: {
      required: true,
    },
  });

  const priorityValue = useMemo(() => {
    if (!value) {
      return null;
    }

    return {
      value: value.ID,
      label: value.name,
      resolutionInterval: value.resolutionInterval,
      responseInterval: value.responseInterval,
    };
  }, [value]);

  const handleChange = useCallback(
    (newVal: PriorityOption | null) => {
      if (!newVal) {
        console.warn('Priority should not be clearable');

        return;
      }

      onChange({
        ID: newVal.value,
        name: newVal.label,
        resolutionInterval: newVal.resolutionInterval,
        responseInterval: newVal.responseInterval,
      });
    },
    [onChange]
  );

  return (
    <FormControl isInvalid={fieldState.invalid}>
      <FormLabel fontWeight="bold">{t('Ticketing:form.priority')}:</FormLabel>
      <PrioritySelect
        isDisabled={viewOnly}
        value={priorityValue}
        onChange={handleChange}
      />
      {fieldState.error && (
        <FormErrorMessage>
          {fieldState.error.message || fieldState.error.type}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
