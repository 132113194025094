import {
  useDefaultContact,
  usePreloadedEvents,
  useReportMethod,
} from 'app/Modules/Ticketing/Assets';
import {
  CreateParams,
  Ticket,
  TicketPriority,
  TicketType,
} from 'app/Modules/Ticketing/Assets/components/types';
import { useDefaultDeadline } from 'app/Modules/Ticketing/Assets/components/use-default-deadline';
import { useDefaultGroup } from 'app/Modules/Ticketing/Assets/components/use-default-group';
import { useDefaultTitle } from 'app/Modules/Ticketing/Assets/components/use-default-title';

interface Props {
  createParams?: CreateParams | undefined;
  ticketType: TicketType | undefined;
  priorities: TicketPriority[];
}

interface TicketDefaults extends Partial<Ticket> {
  isLoading: boolean;
}

export const useTicketDefaults = ({
  createParams,
  ticketType,
  priorities,
}: Props): TicketDefaults => {
  const defaultGroup = useDefaultGroup(ticketType);
  const defaultEvents = usePreloadedEvents(createParams);
  const defaultTitle = useDefaultTitle();
  const defaultCustomer = useDefaultContact(createParams);
  const defaultReportMethod = useReportMethod(createParams);
  const defaultPriority = priorities[0];
  const defaultRespondUntil = useDefaultDeadline(defaultPriority, 'response');
  const defaultResolveUntil = useDefaultDeadline(defaultPriority, 'resolution');

  return {
    ticketType,
    title: defaultTitle.data ?? '',
    assignedUser: {
      ID: window.Bootstrap.bootquery.session.userID,
      username: window.Bootstrap.bootquery.session.username,
      person: null,
    },
    group: defaultGroup,
    state: ticketType?.states[0]?.state,
    priority: defaultPriority,
    respondUntil: defaultRespondUntil?.toISOString() ?? null,
    resolveUntil: defaultResolveUntil?.toISOString() ?? null,
    reportMethod: defaultReportMethod,
    events: defaultEvents,
    customer: defaultCustomer.data?.customer ?? undefined,
    isLoading: defaultCustomer.isLoading || defaultTitle.isLoading,
    contact: defaultCustomer.data?.contact
      ? { contact: defaultCustomer.data.contact }
      : null,
  };
};
