import { Heading, ModalCloseButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb, Breadcrumbs } from '@bq/components/Breadcrumbs';
import { LayoutHeader } from '@bq/components/Layout';

export const TypeSelectHeader = () => {
  const { t } = useTranslation('Ticketing');

  return (
    <LayoutHeader borderTopRadius="5px" justifyContent="flex-end">
      <Heading p={0} m={0} size="sm" flex="1 1 auto">
        {t('Ticketing:ticket_types')}
      </Heading>
      <Breadcrumbs fontSize="sm">
        <Breadcrumb to="/ticketing/list">
          {t('Ticketing:ticketing_title')}
        </Breadcrumb>
        <Breadcrumb to="/ticketing/create">
          {t('Ticketing:ticket_types')}
        </Breadcrumb>
      </Breadcrumbs>
      <ModalCloseButton pos="static" />
    </LayoutHeader>
  );
};
