import { useMemo } from 'react';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useCalendarCtx } from '@bq/components/Calendar/CalendarCtx';

export const TodayButton = () => {
  const today = useMemo(() => new Date(), []);
  const {
    currentDate: [, setCurrentDate],
  } = useCalendarCtx();

  const { t } = useTranslation('global');

  return (
    <Button
      colorScheme="brand"
      onClick={() => {
        setCurrentDate(today);
      }}
      size="sm"
    >
      {t('global:today')}
    </Button>
  );
};
