import { ReactElement } from 'react';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useDialerContext } from './DialerContext';
import { formatSoftphoneError } from './format-softphone-error';
import { deviceStatusColor, deviceStatusLabel } from './labels-colors';
import { PauseStateDisplay } from './PauseStateDisplay';
import { SoftphoneStateDisplay } from './SoftphoneStateDisplay';
import { dialerStore, getCurrentPause } from './store/pbx';

export const StateDisplay = (): ReactElement => {
  const { t } = useTranslation('Telephony');
  const device = dialerStore((state) => state.currentDevice);
  const currentPause = dialerStore(getCurrentPause);
  const { error } = useDialerContext();

  if (error) {
    return (
      <Text
        as="div"
        color="red.400"
        textAlign="center"
        flexGrow={1}
        fontSize="sm"
      >
        {formatSoftphoneError(error).title}
      </Text>
    );
  }

  if (!device) {
    return (
      <Text as="div" color="gray.400" textAlign="center" flexGrow={1}>
        {t('Telephony:no_devices')}
      </Text>
    );
  }

  const protocol = device.pbxTransport?.protocol ?? null;
  if (protocol && ['ws', 'wss'].includes(protocol)) {
    return <SoftphoneStateDisplay />;
  }

  if (device.currentState === 'online' && currentPause !== null) {
    return <PauseStateDisplay pause={currentPause.name} />;
  }

  return (
    <Text
      as="div"
      color={deviceStatusColor(device.currentState)}
      textAlign="center"
      flexGrow={1}
    >
      {deviceStatusLabel(device.currentState)}
    </Text>
  );
};
