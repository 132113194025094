import { ReactElement } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TicketForm } from '../../types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';

export const TitleField = (): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { viewOnly } = useTicketMutationContext();
  const { register, formState } = useFormContext<TicketForm>();

  return (
    <FormControl isInvalid={!!formState.errors.title}>
      <FormLabel fontWeight="bold">{t('Ticketing:form.title')}:</FormLabel>
      <Input
        size={['lg', 'lg', 'sm']}
        {...register('title', { required: true })}
        isDisabled={viewOnly}
      />
      {formState.errors.title?.message && (
        <FormErrorMessage>{formState.errors.title.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};
