import { CalendarProvider } from './CalendarCtx';
import { CalendarProps } from './types';
import { DayView } from './Views/Day/DayView';
import { MonthView } from './Views/Month/MonthView';
import { WeekView } from './Views/Week/WeekView';

export const Calendar = (props: CalendarProps) => {
  const [view] = props.view;

  return (
    <CalendarProvider {...props}>
      {view === 'month' && <MonthView />}
      {view === 'week' && <WeekView />}
      {view === 'day' && <DayView />}
    </CalendarProvider>
  );
};
