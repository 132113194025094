import { format, isWithinInterval } from 'date-fns';

// Both of this utils try to discard the minutes and round down hours
export const getWeekGridKeyFromDate = (date: Date) => {
  const formattedDate = format(date, 'yyyy-MM-dd/HH');

  return `${formattedDate}:00`;
};

export const getWeekGridKeyFromKeyString = (key: string) => {
  const [date, time] = key.split('/');
  // This _minutes is intentional to show insight into key

  if (time) {
    const [hour, _minutes] = time.split(':');

    return `${date}/${hour}:00`;
  }

  return date;
};

export const isSameShiftedDay = (
  dateLeft: Date,
  dateRight: Date,
  shiftHours: number
) => {
  const shiftDayStart = startOfDayShifted(dateLeft, shiftHours);
  const shiftDayEnd = endOfDayShifted(dateLeft, shiftHours);

  return (
    isWithinInterval(dateLeft, { start: shiftDayStart, end: shiftDayEnd }) &&
    isWithinInterval(dateRight, { start: shiftDayStart, end: shiftDayEnd })
  );
};
export const startOfDayShifted = (date: Date, shiftHours = 0) => {
  const currentHours = date.getHours();
  const dayOffset = currentHours < shiftHours ? -1 : 0;
  // Construct a new day to reset min, sec and msec to 0
  const shifted = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + dayOffset,
    shiftHours
  );

  return shifted;
};

export const endOfDayShifted = (date: Date, shiftHours = 0) => {
  const currentHours = date.getHours();
  const dayOffset = currentHours >= shiftHours ? 1 : 0;
  // Construct a new day to reset min, sec and msec to 0
  const shifted = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + dayOffset,
    shiftHours
  );
  shifted.setMilliseconds(-1);

  return shifted;
};

export const comparePercents = (a: string, b: string): number => {
  const numA = parseFloat(a);
  const numB = parseFloat(b);

  return numA - numB; // Returns a negative value if a < b, 0 if equal, positive if a > b
};
