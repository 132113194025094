import { Api } from 'BootQuery/Assets/js/api';

export async function getTitleForEvent(
  eventType: string,
  eventID: number
): Promise<string | null> {
  const { data: title } = await Api.get(
    `/api/ticketing/titleForEvent/${eventType}/${eventID}`
  );

  return title;
}
