import { FaCalendar } from 'react-icons/fa';

import { CalendarItem } from '@bq/components/Calendar';
import { isLongerThan24Hours } from 'BootQuery/Assets/js/dateUtils';

import { AppointmentCalendarEvent } from './types';

export const appointmentToCalendarEvent = (
  event: AppointmentCalendarEvent
): CalendarItem => {
  const sd = new Date(event.startDate);
  const ed = new Date(event.endDate);

  return {
    ID: `${event.type}-${event.id}`,
    title: event.data.title,
    endDate: ed,
    startDate: sd,
    type: 'appointment',
    isWholeDayEvent: isLongerThan24Hours(sd, ed),
    additionalProps: { ...event.data },
    icon: <FaCalendar />,
  };
};
