import { useCallback } from 'react';

import {
  useAddQueryWork,
  useRemoveQueryWork,
  useUpdateQueryWork,
} from './query-utils';
import {
  useOnTicketWorkCreated,
  useOnTicketWorkDeleted,
  useOnTicketWorkUpdated,
} from './ticket-work-events';

/**
 * A hook that returns nothing, listens to ticket work events and updates
 * the query state.
 *
 * This should only be called once, so it's purposely not put in useTicketWorks.
 * Currently resides in `QuickWorkTimer`, but that's ugly
 */
export function useAutoupdateWorks(ticketID: number): void {
  const updateQueryWork = useUpdateQueryWork(ticketID);
  const addQueryWork = useAddQueryWork(ticketID);
  const removeQueryWork = useRemoveQueryWork(ticketID);

  useOnTicketWorkCreated(
    ticketID,
    useCallback(
      (work) => {
        addQueryWork(work);
      },
      [addQueryWork]
    )
  );
  useOnTicketWorkUpdated(
    ticketID,
    useCallback(
      (work) => {
        updateQueryWork(work.ID, work);
      },
      [updateQueryWork]
    )
  );
  useOnTicketWorkDeleted(
    ticketID,
    useCallback(
      (work) => {
        removeQueryWork(work.ID);
      },
      [removeQueryWork]
    )
  );
}
