import { useMemo } from 'react';
import { ButtonGroup } from '@chakra-ui/react';
import { addWeeks, startOfWeek, subWeeks } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import { useCalendarCtx } from '@bq/components/Calendar/CalendarCtx';
import { IconButton } from '@bq/components/IconButton';

export const WeekControls = () => {
  const {
    currentDate: [date, setDate],
    firstDayOfWeek: [firstDayOfWeek],
  } = useCalendarCtx();
  const firstDayNextWeek = useMemo(
    () => startOfWeek(addWeeks(date, 1), { weekStartsOn: firstDayOfWeek }),
    [date, firstDayOfWeek]
  );
  const firstDayPrevWeek = useMemo(
    () => startOfWeek(subWeeks(date, 1), { weekStartsOn: firstDayOfWeek }),
    [date, firstDayOfWeek]
  );
  const { t } = useTranslation('global');

  return (
    <ButtonGroup isAttached variant="ghost">
      <IconButton
        onClick={() => {
          setDate(firstDayPrevWeek);
        }}
        icon={<FaArrowLeft />}
        label={t('global:pagination.previous')}
      />
      <IconButton
        onClick={() => {
          setDate(firstDayNextWeek);
        }}
        icon={<FaArrowRight />}
        label={t('global:pagination.next')}
      />
    </ButtonGroup>
  );
};
