import { memo, useMemo } from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';

import { OBJ } from 'BootQuery/Assets/js/globalTypes';

import { BulkAction, useTableSelection } from '../TableSelection';

interface Props<T extends OBJ> {
  action: BulkAction<T>;
}
const BulkActionStandardButton = <T extends OBJ>({ action }: Props<T>) => {
  const tableSelectionCtx = useTableSelection<T>();
  const parsedSelection = useMemo(() => {
    return action.parseSelection !== undefined
      ? action.parseSelection(tableSelectionCtx.selection)
      : tableSelectionCtx.selection;
  }, [action, tableSelectionCtx.selection]);
  const allowed = useMemo(
    () => action.isAllowed(parsedSelection),
    [action, parsedSelection]
  );

  return (
    <Tooltip
      key={action.name}
      hasArrow
      label={
        !allowed && action.disabledTooltip ? action.disabledTooltip : undefined
      }
      shouldWrapChildren
    >
      <IconButton
        aria-label="action.name"
        onClick={async () => {
          if (allowed) {
            await action.handler({
              ...tableSelectionCtx,
              selection: parsedSelection,
              rawSelection: tableSelectionCtx.selection,
            });
          }
        }}
        isDisabled={!allowed}
        icon={action.icon}
        size="sm"
      />
    </Tooltip>
  );
};

const BulkActionStandardButtonMemo = memo(
  BulkActionStandardButton
) as typeof BulkActionStandardButton;

export { BulkActionStandardButtonMemo as BulkActionStandardButton };
