import { Button, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaSave, FaTimes } from 'react-icons/fa';

import { useTicketMutationContext } from 'app/Modules/Ticketing/Assets';

import { useModalInstance } from '../UseModal/ModalInstanceContext';
import { TicketModalContentProps } from './types';

export const TicketModalFooter = (props: TicketModalContentProps) => {
  const { t } = useTranslation('');
  const { closeWithNoCallback } = useModalInstance();
  const { formState } = useTicketMutationContext();

  return (
    <HStack justifyContent="flex-end">
      {formState === 'error' && (
        <Text as="span" color="red.500">
          {t('global:save_error')}
        </Text>
      )}
      {formState === 'saved' && (
        <Text as="span" color="green.500">
          {t('global:save_success')}
        </Text>
      )}
      <Button
        colorScheme="red"
        variant="outline"
        leftIcon={<FaTimes />}
        onClick={() => {
          closeWithNoCallback();
        }}
      >
        {t('Ticketing:save_actions.undo_changes')}
      </Button>
      <Button
        colorScheme="brand"
        isLoading={formState === 'saving'}
        leftIcon={<FaSave />}
        type="submit"
      >
        {t('global:save')}
      </Button>
    </HStack>
  );
};
