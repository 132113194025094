import { Box, HStack } from '@chakra-ui/react';

import { useCalendarCtx } from '@bq/components/Calendar/CalendarCtx';
import { CalendarMenu } from '@bq/components/Calendar/SharedComponents/CalendarMenu';
import { TodayButton } from '@bq/components/Calendar/SharedComponents/TodayButton';
import { useGetDay } from '@bq/components/Calendar/utils';
import { useHeaderColor } from '@bq/components/use-header-color';

import { DayControls } from './DayControls';

export const DayHeader = () => {
  const {
    currentDate: [currentDate],
    locale,
  } = useCalendarCtx();

  const dayInfo = useGetDay(currentDate, locale);
  const headerColor = useHeaderColor();

  return (
    <HStack
      flex="0 0 40px"
      w="full"
      bg={headerColor}
      borderBottom="3px solid"
      borderColor="brand.600"
      px={4}
      zIndex={3}
      top={0}
      pos="sticky"
    >
      <HStack flex="1 1 auto">
        <TodayButton />

        <DayControls />

        <Box fontWeight="bold" fontSize="xl">
          {dayInfo}
        </Box>
      </HStack>
      <CalendarMenu />
    </HStack>
  );
};
