import { Box, HStack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  StaticFormProvider,
  StaticGeneratedForm,
} from '@bq/components/FormEditor';
import { LoadingPage } from '@bq/components/LoadingPage';

import { TicketWork } from '../../types';
import { useWorkFormDefinition } from '../TicketWorkForm/use-work-form-definition';

interface Props {
  work: TicketWork;
}

export const TicketWorkItemDetails = ({ work }: Props) => {
  const { t } = useTranslation();
  const customFields = useWorkFormDefinition();

  return (
    <VStack w="full" alignItems="left">
      <HStack>
        <Box fontWeight="bold">{`${t('Phonebook:user')}: `}</Box>
        <Box>
          {work.createdByUser?.person
            ? work.createdByUser?.person.fullName
            : (work.createdByUser?.username ?? '-')}
        </Box>
      </HStack>
      <HStack>
        <Box fontWeight="bold">{`${t('Ticketing:work_at')}: `}</Box>
        <Box>{work.workAt.toLocaleDateString() ?? '--'}</Box>
      </HStack>
      <HStack>
        <Box fontWeight="bold">{`${t('Ticketing:last_work_at')}: `}</Box>
        <Box>{work.lastWorkAt.toLocaleDateString() ?? '--'}</Box>
      </HStack>
      <HStack>
        <Box m={0} px={0} py={0}>
          <StaticFormProvider formData={work.data}>
            {customFields ? (
              <StaticGeneratedForm fields={customFields} />
            ) : (
              <LoadingPage />
            )}
          </StaticFormProvider>
        </Box>
      </HStack>
    </VStack>
  );
};
