import { ReactElement, useCallback, useMemo } from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TicketForm, TicketState } from '../../types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';
import { TicketStateSelect } from '../State/TicketStateSelect';
import { TicketStateSelectOption } from '../State/types';

export const StateField = (): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { viewOnly } = useTicketMutationContext();
  const { control } = useFormContext<TicketForm>();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({
    control,
    name: 'state',
    rules: {
      required: {
        message: t('global:form_errors.required_field'),
        value: true,
      },
    },
  });

  const stateValue = useMemo(() => {
    if (!value) {
      return null;
    }

    return stateToOption(value);
  }, [value]);

  const handleChange = useCallback(
    (newVal: TicketStateSelectOption | null) => {
      if (!newVal) {
        console.warn('State should not be clearable');

        return;
      }

      onChange(optionToState(newVal));
    },
    [onChange]
  );

  const ticketType = useWatch({
    control,
    name: 'ticketType',
  });

  return (
    <FormControl isInvalid={fieldState.invalid}>
      <FormLabel fontWeight="bold">{t('Ticketing:form.state')}:</FormLabel>
      <TicketStateSelect
        isDisabled={viewOnly}
        type={ticketType}
        value={stateValue}
        onChange={handleChange}
      />
      {fieldState.error && (
        <FormErrorMessage>
          {fieldState.error.message || fieldState.error.type}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

function stateToOption(state: TicketState): TicketStateSelectOption {
  return {
    value: state.ID,
    label: state.name,
    color: state.color,
  };
}

function optionToState(option: TicketStateSelectOption): TicketState {
  return {
    ID: option.value,
    name: option.label,
    color: option.color,
  };
}
