import { Box, chakra, Grid, VStack } from '@chakra-ui/react';

export const TallCalendarContainer = chakra(VStack, {
  baseStyle: { h: 'full' },
});

export const TallCalendarLayout = chakra(Grid, {
  baseStyle: {
    flex: '1 1 auto',
    w: 'full',
    gridTemplateColumns: '1fr 11fr',
    m: 0,
  },
});

export const TallCalendarGridContainer = chakra(Grid, {
  baseStyle: {
    gridTemplateColumns: '1fr 12fr',
    w: 'full',
    flex: '1 1 auto',
  },
});

export const TallCalendarTableEmptyCorner = chakra(Box, {
  baseStyle: {
    _dark: {
      bg: '#393939',
    },
    bg: '#F5F5F5',
  },
});

export const TallCalendarMainContainer = chakra(VStack, {
  baseStyle: {
    h: 'full',
    w: 'full',
    m: 0,
    pos: 'relative',
  },
});
export const TallCalendarMainLayout = chakra(Grid, {
  baseStyle: {
    gridTemplateColumns: '1fr 19fr',
    w: 'full',
    flex: '1 1 auto',
  },
});

export const TallCalendarHourTrack = chakra(Grid);

export const TallCalendarHour = chakra(Box, {
  baseStyle: {
    _dark: {
      bg: '#393939',
    },
    bg: '#F5F5F5',
    w: 'full',
    fontWeight: 'bold',
    fontSize: 'xs',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'start',
    pr: 1,
  },
});

export const TallCalendarItemGrid = chakra(Grid, {
  m: 0,
  w: 'full',
  flex: '1 1 auto',
  border: '1px solid',
});
