import { useCallback } from 'react';

import { useModal } from '@bq/components/UseModal';

import { ItemClickEvent } from '../types';
import { CalendarEventProviderMap } from './use-event-type-providers';

export const useCalendarActionHookInvoker = (
  calendarEventProviderMap: CalendarEventProviderMap
) => {
  const { addModal } = useModal();

  // TODO: POPOVER INVOKER

  const findDefinition = useCallback(
    (type: keyof CalendarEventProviderMap) => {
      return calendarEventProviderMap?.[type] ?? null;
    },
    [calendarEventProviderMap]
  );

  return useCallback(
    (action: ItemClickEvent) => {
      const definition = findDefinition(action.item.type);
      if (!definition) {
        throw Error('Definition for the calendar action was not found');
      }

      switch (definition.action.type) {
        case 'modal':
          addModal({
            callback: definition.action.callback,
            children: <definition.action.content {...action} />,
            componentProps: definition.action.componentProps,
          });
          break;
        case 'function':
          definition.action.handler(action);
          break;
        default:
          throw Error('Unknown action type for calendar action');
      }
    },
    [addModal, findDefinition]
  );
};
