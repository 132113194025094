import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUndo } from 'react-icons/fa';

import { IconButton } from '@bq/components/IconButton';

interface Props {
  shareID: string;
  onUndo: (shareID: string) => void;
}

export const ShareChangeIndicator = ({
  shareID,
  onUndo,
}: Props): ReactElement => {
  const { t } = useTranslation('Ticketing');

  const undo = useCallback(() => {
    onUndo(shareID);
  }, [shareID, onUndo]);

  return (
    <IconButton
      label={t('global:undo_changes')}
      size="sm"
      variant="ghost"
      icon={<FaUndo />}
      onClick={undo}
    />
  );
};
