import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AsyncSelect } from 'chakra-react-select';
import { SingleValue } from 'react-select';

import { CurrentUserOption } from './CurrentUserOption';
import { CurrentUserValue } from './CurrentUserValue';
import { AgentSelectOption } from './types';
import { loadAgents, loadAgentsMultiGroup, loadValueFromID } from './utils';

interface Props {
  onChange: (newValue: SingleValue<AgentSelectOption>) => void;
  value: AgentSelectOption | number | null;
  groupID: number[] | number | undefined;
  isDisabled?: boolean;
}
export const AgentSelect = ({
  onChange,
  value = null,
  groupID,
  isDisabled,
}: Props) => {
  const valueIsNumber = typeof value === 'number';
  const groupIDIsArray = Array.isArray(groupID);
  const { data: loadedData } = useQuery({
    queryKey: ['agentSelectLoadValueFromID', value],
    queryFn: async () => {
      if (valueIsNumber) {
        const data = await loadValueFromID(value);

        return {
          label: data.person?.fullName ?? data.username,
          value: data.ID,
          type: 'user',
        } as AgentSelectOption;
      }
      throw Error('Unkown error');
    },
    enabled: valueIsNumber && !groupID,
  });
  const parsedValue = useMemo(() => {
    if (valueIsNumber) {
      return loadedData;
    }

    return value;
  }, [loadedData, value, valueIsNumber]);

  return (
    <AsyncSelect<AgentSelectOption>
      key={JSON.stringify(groupID)}
      isClearable
      isDisabled={!groupID || isDisabled}
      useBasicStyles
      size={{ base: 'lg', md: 'sm', lg: 'sm' }}
      value={parsedValue}
      components={{
        Option: CurrentUserOption,
        SingleValue: CurrentUserValue,
      }}
      cacheOptions
      defaultOptions
      onChange={(newVal) => {
        if (newVal?.value !== parsedValue?.value) {
          onChange(newVal);
        }
      }}
      loadOptions={(search) => {
        if (groupIDIsArray) {
          return loadAgentsMultiGroup(search, groupID);
        }
        if (!groupIDIsArray && groupID !== undefined) {
          return loadAgents(search, groupID);
        }

        return undefined;
      }}
      selectedOptionColorScheme="brand"
    />
  );
};
