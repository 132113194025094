import { Api } from 'BootQuery/Assets/js/api';
import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import { User } from '../TicketingForm/Agent/types';

export const getGroupUsers = async (
  groupID: number[],
  params: GetListingParams = {}
) => {
  const { data } = await Api.get<User[] | undefined>(
    '/api/ticketing/groupsUsers',
    {
      params: { ...params, groups: groupID },
    }
  );

  return data;
};
