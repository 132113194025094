import { ReactElement, useCallback, useMemo } from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ContactItem, ContactSelect } from '@bq/components/FormFields';

import { TicketForm } from '../../types';
import { parseTicketContactToContactItem } from '../../utils';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';

export const CustomerField = (): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { viewOnly } = useTicketMutationContext();
  const { control } = useFormContext<TicketForm>();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({ control, name: 'customer' });

  const customerValue = useMemo(() => {
    return parseTicketContactToContactItem(value);
  }, [value]);

  const handleChange = useCallback(
    (newVal: ContactItem | null) => {
      // People are retarded
      if (newVal?.type === 'person' && 'name' in newVal) {
        onChange({ ...newVal, fullName: newVal.name });
      } else {
        onChange(newVal);
      }
    },
    [onChange]
  );

  return (
    <FormControl isInvalid={fieldState.invalid}>
      <FormLabel fontWeight="bold">{t('Ticketing:form.customer')}:</FormLabel>
      <ContactSelect
        createMode="saveOnApiModal"
        hasDetails={true}
        isClearable
        isMulti={false}
        value={customerValue}
        onChange={handleChange}
        isDisabled={viewOnly}
        allowedTypes={{
          company: true,
          companyLocation: false,
          person: true,
          user: false,
          companyDepartment: false,
        }}
      />
      {fieldState.error && (
        <FormErrorMessage>
          {fieldState.error.message || fieldState.error.type}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
