import { MouseEvent } from 'react';

export const caluculateClickHeight = (e: MouseEvent<HTMLDivElement>) => {
  const target = e.currentTarget;
  const height = target.offsetHeight;
  const clickY = e.nativeEvent.offsetY;
  const clickPercent = (clickY / height) * 100;

  return Math.max(0, clickPercent);
};
