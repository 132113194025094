import { EventProviderMap } from 'app/Modules/Events/Assets/components';

import { useCalendarTypeSelectModal } from '../TypeSelectModal/use-calendar-type-select-modal';
import { useCalendarActionHookInvoker } from './use-calendar-action-hook-invoker';
import { useCalendarEventTransformator } from './use-calendar-events-transformator';
import { useCalendarEventProviderMap } from './use-event-type-providers';

export const useCalendarHandlers = (eventProviderMap: EventProviderMap) => {
  const { calendarEventProviderMap, numberOfItems } =
    useCalendarEventProviderMap(eventProviderMap);
  const itemClickHandler = useCalendarActionHookInvoker(
    calendarEventProviderMap
  );
  const eventsToItems = useCalendarEventTransformator(calendarEventProviderMap);
  const dateClickHandler = useCalendarTypeSelectModal(calendarEventProviderMap);

  return {
    numberOfItems,
    itemClickHandler,
    eventsToItems,
    calendarEventProviderMap,
    dateClickHandler,
  };
};
