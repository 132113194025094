import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
} from '@chakra-ui/react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { TicketMultiSelect } from '../TicketMultiSelect/TicketMultiSelect';
import { AddOtherTicketAsDepForm } from './AddOtherTicketAsDep';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  ticketID?: number | null;
  excludedTickets?: number | number[];
  onSubmit: (data: number[]) => void;
}
export const AddOtherTicketAsDepFormContent = ({
  isOpen,
  onClose,
  ticketID,
  excludedTickets,
  onSubmit,
}: Props) => {
  const { t } = useTranslation('Ticketing');
  const { control, handleSubmit } = useForm<AddOtherTicketAsDepForm>();
  const {
    field: { value, onChange },
  } = useController<AddOtherTicketAsDepForm, 'deps'>({
    control,
    name: 'deps',
  });

  return (
    <Portal>
      <Modal isOpen={isOpen} onClose={onClose}>
        <form
          style={{ width: '100%' }}
          id={`insert-deps-${ticketID ?? 'new'}`}
          data-ignore-form
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
            handleSubmit(async (data) => {
              if (data.deps.length === 0) {
                return;
              }

              const depIds = data.deps.map((item) => item.ID);
              onClose();
              onSubmit(depIds);
            })(event);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('Ticketing:add_subticket')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <TicketMultiSelect
                excludedTicketIds={excludedTickets}
                onChange={(value) => {
                  onChange(value);
                }}
                value={value}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                rightIcon={<FaTimes />}
                colorScheme="red"
                variant="outline"
                mr={3}
                onClick={onClose}
              >
                {t('global:cancel')}
              </Button>
              <Button
                type="submit"
                colorScheme="brand"
                rightIcon={<FaCheck />}
                variant="outline"
              >
                {t('global:save')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Portal>
  );
};
