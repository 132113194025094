import {
  AsyncSelect,
  chakraComponents,
  ChakraStylesConfig,
  GroupBase,
  SelectComponentsConfig,
} from 'chakra-react-select';
import { FaUser, FaUsers } from 'react-icons/fa';

import { selectStyles as baseSelectStyles } from '@bq/components/Select';

import { UserOrGroupSelectOption } from './types';
import { searchUsersGroups } from './util';

interface Props {
  onChange: (newValue: UserOrGroupSelectOption | null) => void;
  value: UserOrGroupSelectOption | null;
  isDisabled?: boolean;
}

export const UserOrGroupSelect = ({ onChange, value, isDisabled }: Props) => (
  <AsyncSelect<UserOrGroupSelectOption>
    isDisabled={isDisabled}
    useBasicStyles
    size={{ base: 'lg', md: 'sm', lg: 'sm' }}
    cacheOptions
    defaultOptions
    onChange={onChange}
    value={value}
    loadOptions={searchUsersGroups}
    selectedOptionColorScheme="brand"
    chakraStyles={chakraStyles}
    components={components}
  />
);

type OptionType = UserOrGroupSelectOption;
type GroupType = GroupBase<OptionType>;

type ComponentsConfig = SelectComponentsConfig<OptionType, false, GroupType>;
const components: ComponentsConfig = {
  Option: ({ children, ...props }) => (
    <chakraComponents.Option {...props}>
      {props.data.type === 'user' ? <FaUser /> : <FaUsers />}
      &nbsp;
      {children}
    </chakraComponents.Option>
  ),
  SingleValue: ({ children, ...props }) => (
    <chakraComponents.SingleValue {...props}>
      {props.data.type === 'user' ? <FaUser /> : <FaUsers />}
      &nbsp;
      {children}
    </chakraComponents.SingleValue>
  ),
};

type StylesConfig = ChakraStylesConfig<OptionType, false, GroupType>;
const chakraStyles: StylesConfig = {
  ...baseSelectStyles,
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
  }),
};
