import { useMemo } from 'react';

import { useCalendarCtx } from './CalendarCtx';
import { getDayName } from './utils';

export const useGenerateHeader = (dates: Date[]) => {
  const { locale } = useCalendarCtx();

  return useMemo(() => {
    return dates.map((item) => getDayName(item, locale));
  }, [dates, locale]);
};
