import { MouseEventHandler, useCallback } from 'react';

import { useTicketModal } from '@bq/components/TicketModal';

import { CallbackTicket } from '../TicketingForm/Dependencies/types';
import { TicketType } from '../types';

interface Props {
  type: TicketType;
  modalOnly?: boolean;
  submitCallback?: (data: CallbackTicket) => void;
  parentID?: number;
}

export const useCreateHandleClick = ({
  type,
  modalOnly,
  submitCallback,
  parentID,
}: Props): MouseEventHandler => {
  const { createTicketModal } = useTicketModal();

  return useCallback(
    (ev) => {
      if (ev.shiftKey || modalOnly) {
        ev.preventDefault();
        createTicketModal({
          ticketType: type,
          closeCallback: submitCallback,
          defaults: { parentID },
        });
      }
    },
    [createTicketModal, modalOnly, submitCallback, type, parentID]
  );
};
