import { Fragment, ReactElement } from 'react';
import { Button, Center, Text } from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useListingSettings } from '@bq/components/ListingSettings';
import { useHeadingProps } from '@bq/components/ListingSettings/use-heading-props';
import { defaultVisibleColumns } from 'app/Modules/Ticketing/Assets/components/TicketTable/Columns/defaults';
import { Card, CardBody } from 'BootQuery/Assets/components/Card';
import { FieldValue } from 'BootQuery/Assets/components/FormEditor';
import { ListHeading } from 'BootQuery/Assets/components/ListHeading';
import { ListWrapper } from 'BootQuery/Assets/components/ListWrapper';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import {
  CardBulkActionMenu,
  TableSelectionProvider,
} from 'BootQuery/Assets/components/Table';
import { listingHasNextPage } from 'BootQuery/Assets/js/utils';

import { phonebookDisplayModeOptions } from '../CompanyEmployeeList/display-settings';
import { PhonebookListContext } from '../PhonebookListContext';
import { CompanyApiData } from '../types';
import { useSelectedOverview } from '../use-selected-overview';
import { Actions } from './Actions';
import { columns, useSplitColumnsFeatures } from './columns';
import { useCompanyBulkActions } from './company-list-bulk-actions';
import { CompanyCard } from './CompanyCard';
import { filterTypes } from './filter-types';
import { getCardCompanies } from './get-companies';

interface Props {
  customFields?: FieldValue[];
}

export const MobileCompanyCardList = ({
  customFields,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const overview = useSelectedOverview('people');

  const listingSettings = useListingSettings<CompanyApiData>({
    listingName: 'Phonebook.CompanyList',
    viewName: 'mobile',
    filterTypes,
    columns: columns(),
    otherFilters: overview?.filters ?? [],
    defaults: { visibleColumns: defaultVisibleColumns },
    customFields,
  });

  const { columnsToShow, limit, filters, search, page } = listingSettings;

  const { columns: customColumns, features } =
    useSplitColumnsFeatures(columnsToShow);

  const { data, refetch, isLoading, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      queryKey: ['Phonebook.CompanyList.mobile', filters, search, limit],
      queryFn: async ({ pageParam = 1 }) => {
        const { data, meta } = await getCardCompanies({
          filters,
          limit,
          page: pageParam,
        });

        return {
          data,
          nextCursor: pageParam + 1,
          hasNextPage: listingHasNextPage(pageParam, limit, meta.count),
        };
      },

      getNextPageParam: (lastPage) => {
        if (lastPage.hasNextPage) {
          return lastPage.nextCursor;
        }

        return undefined;
      },
      initialPageParam: 1,
    });

  const bulkActions = useCompanyBulkActions(refetch);

  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    displayModeOptions: phonebookDisplayModeOptions,
    count: null,
  });

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <PhonebookListContext.Provider value={{ refetch }}>
      <TableSelectionProvider<CompanyApiData>
        page={page}
        idColumn="ID"
        selectAll={async () => {
          const { data } = await getCardCompanies({
            fields: [
              'ID',
              'name',
              [
                'phoneNumbers',
                ['phoneNumber.phoneNumberE164', 'numberType.ID'],
              ],
            ],
            page: 1,
            limit: 99999,
            filters,
          });

          return data;
        }}
        selectPage={() => []}
      >
        <ListWrapper spacing="5" px="3" py="3">
          <Card>
            <CardBody>
              <ListHeading
                {...headingProps}
                menuProps={undefined}
                noPagination
                Actions={<Actions />}
              />
            </CardBody>
          </Card>
          {data.pages.map((page, idx) => (
            <Fragment key={idx}>
              {page.data.map((company) => (
                <CompanyCard
                  key={company.ID}
                  company={company}
                  customColumns={customColumns}
                  features={features}
                />
              ))}
            </Fragment>
          ))}

          {hasNextPage && (
            <Button
              isLoading={isLoading}
              onClick={() => {
                fetchNextPage();
              }}
              colorScheme="blue"
            >
              {t('global:load_more')}
            </Button>
          )}
          {!hasNextPage && (
            <Center pt="2" m={0} mb={4}>
              <Text textAlign="center" fontWeight="bold" fontSize="xl" m={0}>
                {`${t('global:no_more_results')}...`}
              </Text>
            </Center>
          )}
        </ListWrapper>
        <CardBulkActionMenu<CompanyApiData> actionGroups={bulkActions} />
      </TableSelectionProvider>
    </PhonebookListContext.Provider>
  );
};
