import {
  ApiTicketShare,
  CreateTicketShare,
  ModifyTicketShare,
  TicketPostContact,
  TicketShare,
} from '../types';
import { SelectCustomOption } from './Contact/type';

export function contactForBackend(
  contact: SelectCustomOption | undefined
): TicketPostContact | null | undefined {
  if (typeof contact === 'undefined') {
    return undefined;
  }
  if (contact === null) {
    return null;
  }

  if (contact.type === 'manual') {
    if ('$new' in contact && contact.$new !== undefined) {
      return { type: 'manual', value: contact.$new };
    }

    return { type: 'manual', value: contact.value.toString() };
  }

  if (typeof contact.value !== 'number') {
    throw new Error(
      `Expected number for contact ID, got ${JSON.stringify(contact.value)}`
    );
  }

  return { type: contact.type, value: contact.value };
}

export function shareForBackendCreate(share: TicketShare): CreateTicketShare {
  if (share.shareWith.type === 'user') {
    return {
      type: 'user',
      userID: share.shareWith.ID,
      canWrite: share.canWrite,
    };
  }

  // group
  return {
    type: 'group',
    ticketGroupID: share.shareWith.ID,
    canWrite: share.canWrite,
  };
}

export function shareForBackendUpdate(
  share: Partial<TicketShare>
): ModifyTicketShare {
  if (share.shareWith?.type === 'user') {
    return {
      type: 'user',
      userID: share.shareWith?.ID,
      canWrite: share?.canWrite,
    };
  }
  if (share.shareWith?.type === 'group') {
    return {
      type: 'group',
      ticketGroupID: share.shareWith?.ID,
      canWrite: share?.canWrite,
    };
  }

  throw new Error('Unknown share type');
}

export function shareFromBackend(share: ApiTicketShare): TicketShare {
  if (share.type === 'user') {
    return {
      ID: share.ID,
      shareWith: {
        label: share.user.username,
        value: `user-${share.user.ID}`,
        type: 'user',
        ID: share.user.ID,
      },
      canWrite: share.canWrite,
    };
  }

  return {
    ID: share.ID,
    shareWith: {
      label: share.ticketGroup.name,
      value: `group-${share.ticketGroup.ID}`,
      type: 'group',
      ID: share.ticketGroup.ID,
    },
    canWrite: share.canWrite,
  };
}
