import { memo } from 'react';
import {
  BoxProps,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  Checkbox,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';

import { Card } from '@bq/components/Card';
import { useIsMobile } from '@bq/components/use-is-mobile';

import { GenericBulkAction, useTableSelection } from '../TableSelection';
import { OBJ } from '../types';
import { BulkActionStandardButton } from './BulkActionStandardButton';
import { BulkActionTextButton } from './BulkActionTextButton';
import { CardBulkActionsMobile } from './CardBulkActionsMobile';

interface ActionGroup<T extends OBJ> {
  actions?: GenericBulkAction<T>[];
  styleProps: ButtonGroupProps;
}
interface Props<T extends OBJ> {
  actionGroups?: ActionGroup<T>[];
  showDefaultsSelectionMenu?: boolean;
  cardProps?: BoxProps;
}

export const CardBulkActionMenu = <T extends OBJ>({
  actionGroups,
  showDefaultsSelectionMenu = true,
  cardProps,
}: Props<T>) => {
  const {
    selection,
    clearSelected,
    selectPage,
    selectAll,
    allSelected,
    isSelecting,
  } = useTableSelection<T>();
  const { t } = useTranslation();

  const headerColor = useColorModeValue('#F5F5F5', '#393939');
  const isMobile = useIsMobile();
  if (selection.length === 0 || actionGroups === undefined) {
    return <></>;
  }

  return (
    <Card
      pos="sticky"
      zIndex={3}
      bottom={0}
      w="full"
      p={3}
      shadow="base"
      bg={headerColor}
      {...cardProps}
    >
      <HStack w="full">
        <HStack w="full">
          <Checkbox
            colorScheme="brand"
            mb={0}
            zIndex={6}
            isIndeterminate={!allSelected}
            isChecked={true}
            onChange={(_e) => {
              clearSelected();
            }}
          />
          <Menu>
            <Button
              pt={1}
              size="sm"
              variant="ghost"
              rightIcon={<FaChevronDown />}
              as={MenuButton}
            />
            <MenuList zIndex={6}>
              {showDefaultsSelectionMenu && (
                <MenuOptionGroup type="radio">
                  {selectPage && (
                    <MenuItemOption
                      value="allPage"
                      onClick={() => {
                        selectPage();
                      }}
                    >
                      {t('global:bulk_actions.select_all_on_page')}
                    </MenuItemOption>
                  )}
                  {selectAll && (
                    <MenuItemOption
                      value="allFilter"
                      onClick={() => {
                        selectAll();
                      }}
                    >
                      {t('global:bulk_actions.select_all_that_match')}
                    </MenuItemOption>
                  )}
                </MenuOptionGroup>
              )}
            </MenuList>
          </Menu>
          <Text whiteSpace="nowrap">{`${t('global:selected')} ${
            selection.length
          } ${t('items')}`}</Text>
        </HStack>
        {!isSelecting && !isMobile && (
          <HStack pl={3} pr={8}>
            {actionGroups.map((group, idx) => {
              const { actions } = group;

              return (
                <ButtonGroup
                  px={7}
                  isAttached
                  key={idx}
                  color="brand.font"
                  isDisabled={isSelecting}
                  {...group.styleProps}
                >
                  {actions?.map((action) => {
                    if (action.displayText === true) {
                      return (
                        <BulkActionTextButton
                          key={action.name}
                          action={action}
                        />
                      );
                    }

                    return (
                      <BulkActionStandardButton
                        action={action}
                        key={action.name}
                      />
                    );
                  })}
                </ButtonGroup>
              );
            })}
          </HStack>
        )}

        <CardBulkActionsMobile
          isSelecting={isSelecting}
          actionGroups={actionGroups}
        />

        {isSelecting && <Spinner />}
      </HStack>
    </Card>
  );
};

const MobileBulkActionConfirmButtonMemo = memo(
  BulkActionTextButton
) as typeof BulkActionTextButton;

export { MobileBulkActionConfirmButtonMemo as BulkActionTextButton };
