import { CalendarItemSplitted } from '@bq/components/Calendar/types';
import { areDatesWithinIntervalMinutes } from '@bq/components/Calendar/utils';
import { parseToDate } from 'BootQuery/Assets/js/dateUtils';

export const sortItemsInArray = (items: CalendarItemSplitted[]) => {
  return items.sort((a, b) => {
    const dateA = parseToDate(a.startDate);
    const dateB = parseToDate(b.startDate);

    // Compare dates using date-fns
    if (areDatesWithinIntervalMinutes(dateA, dateB, 15)) {
      // If the dates are the same, sort by duration (longer first)
      return b.duration - a.duration;
    }

    // Sort by date (ignoring time)
    return dateA.getTime() < dateB.getTime() ? -1 : 1;
  });
};
