import { addHours, differenceInMinutes, startOfHour } from 'date-fns';

export const calculateEventHeight = (startTime: Date, endTime: Date) => {
  const roundedStartTime = startOfHour(startTime);

  const roundedEndTime = addHours(startOfHour(endTime), 1);

  const totalEventDurationMinutes = differenceInMinutes(
    roundedEndTime,
    roundedStartTime
  );

  const startCutoffMinutes = differenceInMinutes(startTime, roundedStartTime);
  const endCutoffMinutes = differenceInMinutes(roundedEndTime, endTime);

  const usableEventDurationMinutes =
    totalEventDurationMinutes - (startCutoffMinutes + endCutoffMinutes);

  const usablePercent =
    (usableEventDurationMinutes / totalEventDurationMinutes) * 100;

  return usablePercent;
};
