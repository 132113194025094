import { useQuery } from '@tanstack/react-query';

import {
  getTicketWithDependers,
  TicketModalCreateParams,
} from 'app/Modules/Ticketing/Assets';

import { LoadingModal } from '../UseModal/LoadingModal';
import { TicketModalContent } from './TicketModalContent';

export const EditTicketModalContent = ({
  ID,
  createParams,
}: {
  ID: number;
  createParams?: TicketModalCreateParams;
}) => {
  const { data: rawTicket } = useQuery({
    queryKey: ['loadTicket', ID],
    queryFn: async () => {
      if (!ID) {
        return undefined;
      }
      const data = await getTicketWithDependers(ID);

      return data;
    },
    enabled: ID !== null,
  });

  if (!rawTicket) {
    return <LoadingModal />;
  } /** TO DO: change the API call to return full ticket type */

  /** Temp fix: slug is always returned and we load other info from context */
  return (
    <TicketModalContent
      mode="edit"
      data={rawTicket}
      ID={ID}
      ticketType={rawTicket.ticketType.slug}
      createParams={createParams}
    />
  );
};
