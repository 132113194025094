import { Box, IconButton, VStack } from '@chakra-ui/react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

import { useHeaderColor } from '@bq/components/use-header-color';

import { useCalendarCtx } from '../CalendarCtx';
import { useTz } from '../utils';

export const TimezoneBox = () => {
  const { tzName, tzOffset } = useTz();
  const {
    disclosureState: { onToggle, isOpen },
  } = useCalendarCtx();
  const headerBg = useHeaderColor();

  return (
    <VStack
      w="full"
      h="full"
      fontWeight="bold"
      fontSize="xs"
      display="flex"
      justifyContent="start"
      alignItems="end"
      p="2"
      alignSelf="start"
      textAlign="end"
      bg={headerBg}
    >
      <Box>{tzName}</Box>
      <Box>{tzOffset}</Box>
      <IconButton
        aria-label=""
        onClick={() => {
          onToggle();
        }}
        icon={isOpen ? <FaChevronRight /> : <FaChevronDown />}
        variant="ghost"
      />
    </VStack>
  );
};
