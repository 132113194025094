import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPause, FaPlay } from 'react-icons/fa';

import { IconButton, IconButtonProps } from '@bq/components/IconButton';

interface Props extends Omit<IconButtonProps, 'label' | 'icon'> {
  isStarted: boolean;
  onStart?: () => void;
  onStop?: () => void;
  stopLabel?: string;
  startLabel?: string;
  disabledLabel?: string;
}

export function StartStopButton({
  isStarted,
  onStart,
  onStop,
  stopLabel,
  startLabel,
  disabledLabel,
  ...btnProps
}: Props): ReactElement {
  const { t } = useTranslation('global');
  const disabledOverrideLabel = btnProps.isDisabled ? disabledLabel : undefined;

  if (isStarted) {
    return (
      <IconButton
        size="sm"
        rounded="full"
        variant="solid"
        icon={<FaPause />}
        label={disabledOverrideLabel ?? stopLabel ?? t('global:stop')}
        onClick={(e) => {
          // stopPropagination() stops triggering accordion on button click
          e.stopPropagation();
          onStop?.();
        }}
        {...btnProps}
      />
    );
  }

  return (
    <IconButton
      size="sm"
      rounded="full"
      variant="solid"
      icon={<FaPlay />}
      label={disabledOverrideLabel ?? startLabel ?? t('global:start')}
      onClick={(e) => {
        // stopPropagination() stops triggering accordion on button click
        e.stopPropagation();
        onStart?.();
      }}
      {...btnProps}
    />
  );
}
