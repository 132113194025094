import { sortBy } from 'lodash-es';

import { TicketWork } from '../../types';
import { useTicketWorks } from '../use-ticket-works';

interface UseCurrentWorkResult {
  isLoaded: boolean;
  currentWork: TicketWork | null;
}

interface Props {
  ticketID: number;
}

export function useCurrentWork({ ticketID }: Props): UseCurrentWorkResult {
  const { works } = useTicketWorks(ticketID);

  if (!works.data) {
    return { isLoaded: false, currentWork: null };
  }

  const currentWork = findCurrentWork(works.data);

  return { isLoaded: true, currentWork };
}

function findCurrentWork(works: TicketWork[] | undefined): TicketWork | null {
  if (!works) {
    return null;
  }

  const currentUserID = window.Bootstrap.bootquery.session.userID;
  const currentWorks = works.filter(
    (work) => work.createdByUser?.ID === currentUserID
  );

  const inProgress = currentWorks.find((work) => work.inProgress);
  if (inProgress) {
    return inProgress;
  }

  const byLastActivity = sortBy(currentWorks, 'lastWorkAt');

  return byLastActivity.at(-1) ?? null;
}
