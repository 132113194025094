import { useMemo } from 'react';

import { useTicketingContext } from './TicketingContext';
import { TicketGroup, TicketType } from './types';
import { useOwnGroups } from './use-own-groups';

/**
 * Returns the default ticket group that should be used when creating a new ticket.
 *
 * The group is determined in the following order:
 * 1. Default group for the ticket type that will be created
 * 2. First group in the list of groups user is a member of
 * 3. First group in list of all groups
 */
export function useDefaultGroup(
  ticketType?: TicketType | null
): TicketGroup | undefined {
  const { groups } = useTicketingContext();
  const ownGroups = useOwnGroups();

  const defaultForType = useMemo(() => {
    return groups.find((item) => {
      return item.ID === ticketType?.defaultTicketGroupID;
    });
  }, [groups, ticketType?.defaultTicketGroupID]);

  return defaultForType || ownGroups[0] || groups[0];
}
