import {
  useIsMutating,
  useMutation,
  UseMutationResult,
} from '@tanstack/react-query';

import {
  deleteTicketWork,
  startTicketWork,
  stopTicketWork,
  updateTicketWork,
} from '../Api';
import { TicketWork } from '../types';
import { useRemoveQueryWork, useUpdateQueryWork } from './query-utils';
import { CreateWorkRequest } from './types';
import { useTicketWorks } from './use-ticket-works';
import { getElapsedSeconds } from './util';

interface UseWorkResult {
  work: TicketWork;
  start: UseMutationResult<TicketWork, unknown, void>;
  stop: UseMutationResult<TicketWork, unknown, void>;
  update: UseMutationResult<TicketWork, unknown, Partial<CreateWorkRequest>>;
  remove: UseMutationResult<number, unknown, void>;
  isMutating: boolean;
}

export function useTicketWork(ticketID: number, workID: number): UseWorkResult {
  const { works } = useTicketWorks(ticketID);

  const work = works.data?.find((work) => work.ID === workID);
  if (!work) {
    throw new Error(`Work ${workID} not found`);
  }

  const updateQueryWork = useUpdateQueryWork(ticketID);
  const removeQueryWork = useRemoveQueryWork(ticketID);

  const mutationKey = ['Ticketing.work', workID];

  const start = useMutation({
    mutationKey,
    mutationFn: () => startTicketWork(ticketID, workID),
    onSuccess: (work) => updateQueryWork(work.ID, work),
  });

  const stop = useMutation({
    mutationKey,
    mutationFn: () => stopTicketWork(ticketID, workID),
    onSuccess: (work) => updateQueryWork(work.ID, work),
    onMutate: () => {
      // Oportunistically stop the timer so it doesn't count while stopping,
      // updates the stored duration to current value.
      // This reduces skipping counter back after server confirms stop.
      updateQueryWork(workID, (work) => {
        const newDurationSecs = Math.floor(getElapsedSeconds(work));

        return {
          ...work,
          inProgress: false,
          pendingTimeSince: null,
          durationSecs: newDurationSecs,
        };
      });
    },
  });

  const remove = useMutation({
    mutationKey,
    mutationFn: async () => {
      await deleteTicketWork(ticketID, workID);

      return workID;
    },
    onSuccess: removeQueryWork,
  });

  const update = useMutation({
    mutationKey,
    mutationFn: (changes: Partial<CreateWorkRequest>) => {
      return updateTicketWork(ticketID, workID, changes);
    },
    onSuccess: (work) => updateQueryWork(work.ID, work),
  });

  const isMutating = useIsMutating({ mutationKey }) > 0;

  return {
    work,
    start,
    stop,
    update,
    remove,
    isMutating,
  };
}
