import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  useColorModeValue,
} from '@chakra-ui/react';

import {
  TicketFormBody,
  TicketingProvider,
} from 'app/Modules/Ticketing/Assets';

import { TicketFormWrapper } from './TicketFormWrapper';
import { TicketModalFooter } from './TicketModalFooter';
import { TicketModalHeader } from './TicketModalHeader';
import { TicketModalContentProps } from './types';

export const TicketModalContent = (props: TicketModalContentProps) => {
  const headerColor = useColorModeValue('#F5F5F5', '#393939');

  return (
    <TicketingProvider>
      <TicketFormWrapper {...props}>
        <ModalHeader bg={headerColor} p={0}>
          <TicketModalHeader {...props} />
        </ModalHeader>
        <ModalBody
          m={0}
          p={0}
          overflow="hidden"
          display="flex"
          flexDirection="column"
          w="full"
        >
          <TicketFormBody />
        </ModalBody>
        <ModalFooter>
          <TicketModalFooter {...props} />
        </ModalFooter>
      </TicketFormWrapper>
    </TicketingProvider>
  );
};
