import { useMemo } from 'react';
import { add } from 'date-fns';

import { TicketPriority } from './types';

type DeadlineType = 'resolution' | 'response';

export function useDefaultDeadline(
  priority: TicketPriority | null,
  deadlineType: DeadlineType,
  createdAt?: Date
): Date | null {
  return useMemo(() => {
    const countFrom = createdAt ?? new Date();
    if (deadlineType === 'resolution' && priority?.resolutionInterval) {
      return add(countFrom, { seconds: priority.resolutionInterval });
    }
    if (deadlineType === 'response' && priority?.responseInterval) {
      return add(countFrom, { seconds: priority.responseInterval });
    }

    return null;
  }, [priority, deadlineType, createdAt]);
}
