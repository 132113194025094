import { ReactNode } from 'react';
import {
  Box,
  Button,
  HStack,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaTimes } from 'react-icons/fa';

interface Props {
  icon?: ReactNode;
  results?: string[];
  onConfirm?: () => void;
  onDecline?: () => void;
  children: ReactNode;
}
export const ConfirmationModal = ({
  results,
  onConfirm,
  children,
  onDecline,
  icon,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('');

  return (
    <>
      <Portal>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader as={HStack} spacing={6}>
              <Box>{icon}</Box>
              <Box>{`${t('global:confirm_action')}`}</Box>
            </ModalHeader>
            <ModalBody>
              <Box fontWeight="bold" fontSize="md">
                {t('global:this_will_result_in')}:
              </Box>
              <UnorderedList>
                {results?.map((item) => <ListItem key={item}>{item}</ListItem>)}
              </UnorderedList>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="outline"
                colorScheme="red"
                mr={3}
                onClick={() => {
                  onClose();
                  if (onDecline) {
                    onDecline();
                  }
                }}
                leftIcon={<FaTimes />}
              >
                {t('global:cancel')}
              </Button>
              <Button
                variant="outline"
                leftIcon={<FaCheck />}
                colorScheme="green"
                onClick={() => {
                  onClose();
                  if (onConfirm) {
                    onConfirm();
                  }
                }}
              >
                {t('global:submit')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Portal>
      <Box
        onClick={() => {
          onOpen();
        }}
      >
        {children}
      </Box>
    </>
  );
};
