import { eachDayOfInterval, format } from 'date-fns';
import { intersection } from 'lodash-es';

import { DateWithFormatted } from '@bq/components/Calendar/types';

export const findAndReserveAvaliableSpace = (
  startDate: DateWithFormatted,
  endDate: DateWithFormatted,
  weekSpacesMap: Record<string, number[]>
): { avaliableSpace: number; updatedMap: Record<string, number[]> } => {
  if (startDate.formatted === endDate.formatted) {
    const findArray = weekSpacesMap[startDate.formatted];
    const readFirst = findArray[0] ?? null;

    if (readFirst !== null) {
      const result = {
        ...weekSpacesMap,
        [startDate.formatted]: findArray.filter((item) => item !== readFirst),
      };

      return { avaliableSpace: readFirst, updatedMap: result };
    }

    return { avaliableSpace: readFirst, updatedMap: weekSpacesMap };
  }

  const days = eachDayOfInterval({
    start: startDate.rawDate,
    end: endDate.rawDate,
  });

  const findArrays = days.map((day) => {
    const formattedDay = format(day, 'yyyy-MM-dd');
    const findArray = weekSpacesMap[formattedDay];

    return findArray ?? [];
  });

  const intersectArrays = intersection(...findArrays);
  const readFirst = intersectArrays[0] ?? null;

  if (readFirst !== null) {
    const result = days.reduce<Record<string, number[]>>((all, day) => {
      const formattedDay = format(day, 'yyyy-MM-dd');
      const findArray = weekSpacesMap[formattedDay];

      return {
        ...all,
        [formattedDay]: findArray.filter((item) => item !== readFirst),
      };
    }, weekSpacesMap);

    return { avaliableSpace: readFirst, updatedMap: result };
  }

  return { avaliableSpace: readFirst, updatedMap: weekSpacesMap };
};
