import { useCallback, useEffect } from 'react';

import { sendStoppedWatchingBeacon } from './send-stopped-watching-beacon';

/**
 * Tells the server that we stopped watching a ticket on window unload.
 * This mode uses the beacon api because we can't queue a request on window
 * unload otherwise.
 */
export function useUnloadNotifier(): void {
  const notify = useCallback(() => {
    sendStoppedWatchingBeacon();
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', notify);

    return () => {
      window.removeEventListener('beforeunload', notify);
    };
  }, [notify]);
}
