import { useCallback, useEffect, useRef } from 'react';

import { sendOpenTicket } from './send-open-ticket';
import { PollerInfo, UpdateWatchers } from './types';

/**
 * Notifies the server which ticket we are currently looking at.
 *
 * It needs to do this periodically, because the server expects to
 * be notified that we're still here at least every 120 seconds.
 * This is to prevent stuck watchers when one is unable to notify the
 * server that it stopped watching. For example when internet is disconnected,
 * browser crash, power outage, etc...
 */
export function usePollOpenTicket(
  ticketID: number | null,
  updateWatcherState: UpdateWatchers
): void {
  const pollerRef = useRef<PollerInfo | null>(null);

  const updateWatchers = useCallback(
    async (ticketID: number) => {
      const watchers = await sendOpenTicket(ticketID);
      updateWatcherState((prev) => ({ ...prev, [ticketID]: watchers }));
    },
    [updateWatcherState]
  );

  const stopPolling = useCallback(() => {
    if (pollerRef.current) {
      window.clearInterval(pollerRef.current.intervalHandle);
      pollerRef.current = null;
    }
  }, []);

  const startPolling = useCallback(
    (ticketID: number) => {
      if (pollerRef.current) {
        // Already watching this ticket, nothing to do
        if (pollerRef.current.ticketID === ticketID) {
          return;
        }

        stopPolling(); // Was polling different ticket, stop polling that one
      }

      updateWatchers(ticketID);
      pollerRef.current = {
        ticketID,
        intervalHandle: window.setInterval(() => {
          updateWatchers(ticketID);
        }, 60_000),
      };
    },
    [stopPolling, updateWatchers]
  );

  useEffect(() => {
    if (ticketID) {
      startPolling(ticketID);
    } else {
      stopPolling();
      sendOpenTicket(null);
    }

    return () => {
      stopPolling();
      sendOpenTicket(null);
    };
  }, [ticketID, startPolling, stopPolling]);
}
