import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { SingleValue } from 'chakra-react-select';

import { Select } from '@bq/components/SelectWrappers';

import { getTickets } from './Api';
import { getTicket } from './Api/ticket/ticket';
import { TicketSelectOption as TicketSelectOptionC } from './TicketSelectOption';
import { TicketSelectValue } from './TicketSelectValue';
import {
  TicketBaseInfo,
  TicketOptionData,
  TicketSelectOption,
  TicketSelectOptionAdditonalData,
} from './types';

interface Props {
  onChange: (newValue: SingleValue<TicketBaseInfo>) => void;
  value: TicketBaseInfo | null;
  excludedTicketIds?: number | number[];
  isInvalid?: boolean;
  showAdditionalData?: boolean;
}

export const TicketSelect = ({
  onChange,
  value,
  excludedTicketIds,
  isInvalid,
  showAdditionalData = false,
}: Props) => {
  const { data, isLoading } = useQuery({
    queryKey: ['TicketSelect', value?.ID],
    queryFn: () => {
      if (value?.ID) {
        return getTicket(value.ID);
      }

      return null;
    },
  });

  const internalValue = useTicketSelectValue(data?.data ?? null);

  const handleChange = useCallback(
    (value: SingleValue<TicketSelectOption>) => {
      if (value) {
        onChange({
          ID: value.value,
          title: value.title,
        });
      }
    },
    [onChange]
  );

  return (
    <Select<number, TicketSelectOptionAdditonalData>
      isInvalid={isInvalid}
      isLoading={isLoading}
      size={{ base: 'lg', md: 'sm', lg: 'sm' }}
      value={internalValue}
      onChange={handleChange}
      options={useLoadOptions(excludedTicketIds)}
      components={
        showAdditionalData
          ? {
              Option: TicketSelectOptionC,
              SingleValue: TicketSelectValue,
            }
          : {}
      }
    />
  );
};

const loadOptions = async (
  search: string,
  excludedTicketIds?: number | number[]
): Promise<TicketSelectOption[]> => {
  let extraFilters = {};
  if (excludedTicketIds) {
    const excluded = Array.isArray(excludedTicketIds)
      ? excludedTicketIds
      : [excludedTicketIds];
    if (excluded.length > 0) {
      extraFilters = { 'ID:notInArray': excluded };
    }
  }

  const { data } = await getTickets<TicketOptionData>({
    filters: {
      $search: search,
      ...extraFilters,
    },
    fields: [
      'ID',
      'title',
      ['state', ['ID', 'name', 'color']],
      'customer',
      ['group', ['ID', 'name']],
      ['assignedUser', ['ID', 'username', ['person', ['ID', 'fullName']]]],
    ],
  });

  return data.data.map(ticketToSelectValue);
};

function useLoadOptions(
  excludedTicketIds?: number | number[]
): (search: string) => Promise<TicketSelectOption[]> {
  return useCallback(
    (search: string) => loadOptions(search, excludedTicketIds),
    [excludedTicketIds]
  );
}

const ticketToSelectValue = (ticket: TicketOptionData): TicketSelectOption => ({
  value: ticket.ID,
  title: ticket.title,
  label: `#${ticket.ID} - ${ticket.title}`,
  assignedUser: ticket.assignedUser,
  customer: ticket.customer,
  group: ticket.group,
  state: ticket.state,
});

function useTicketSelectValue(
  ticket: TicketOptionData | null
): TicketSelectOption | null {
  return useMemo((): TicketSelectOption | null => {
    if (!ticket) {
      return null;
    }

    return ticketToSelectValue(ticket);
  }, [ticket]);
}
