import { ContactItem } from '@bq/components/FormFields/Contact/types';
import i18n from 'BootQuery/Assets/js/i18n';

import { SelectCustomOption } from './TicketingForm/Contact/type';
import {
  TicketCompany,
  TicketContact,
  TicketPerson,
  TicketType,
} from './types';

export const parseTicketContactToContactItem = (
  contact?: TicketPerson | TicketCompany | ContactItem | null
): ContactItem | null => {
  if (contact) {
    if (contact.type === 'person') {
      if ('fullName' in contact) {
        return { ID: contact.ID, name: contact.fullName, type: contact.type };
      }

      return contact;
    }

    return contact;
  }

  return null;
};

export const parseSelectCustomOptionToContactItem = (
  contact?: SelectCustomOption | null
): ContactItem | null => {
  if (contact) {
    if (typeof contact?.value === 'string') {
      return { $new: contact.value, type: contact.type };
    }

    return { ID: contact.value, name: contact.label, type: contact.type };
  }

  return null;
};

export function translateReportMethodName(raw: string): string {
  switch (raw) {
    case 'email':
      return i18n.t('Ticketing:report_methods.email');
    case 'phone':
      return i18n.t('Ticketing:report_methods.phone');
    case 'chat':
      return i18n.t('Ticketing:report_methods.chat');
    default:
      console.warn('Unknown system report method: ', raw);

      return raw;
  }
}

export function createTicketLink(
  overviewSlug: string | undefined,
  type: TicketType
) {
  if (overviewSlug) {
    return `/ticketing/create/${overviewSlug}/${type.slug}`;
  }

  return `/ticketing/create/${type.slug}`;
}

export const getContactName = (contact: TicketContact) => {
  if (contact.type === 'company') {
    return contact.name;
  }
  if (contact.type === 'person') {
    return contact.fullName;
  }
  if (contact.type === 'companyLocation') {
    return contact.name;
  }
  if (contact.type === 'companyDepartment') {
    return contact.name;
  }
  if (contact.type === 'manual') {
    return contact.manualContact;
  }

  // Use the square bracked syntax to make TS happy,
  // otherwise it complains about property not existing
  //
  // eslint-disable-next-line dot-notation
  throw Error(`Unknown contact type ${contact['type']}`);
};
