import { ReactElement } from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { fixedChakraFactory } from '@bq/components/fixed-chakra-factory';

interface Props extends Omit<TextProps, 'title'> {
  workTitle: string | null;
}

export const WorkTitle = ({ workTitle, ...textProps }: Props): ReactElement => {
  const { t } = useTranslation('Ticketing');

  if (!workTitle) {
    return (
      <TitleText as="span" color="gray" {...textProps}>
        ({t('global:untitled')})
      </TitleText>
    );
  }

  return (
    <TitleText as="span" {...textProps}>
      {workTitle}
    </TitleText>
  );
};

const TitleText = fixedChakraFactory(Text, {
  baseStyle: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});
