import { ReactElement } from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ChakraDateInput } from '@bq/components/ChakraDateInput/ChakraDateInput';

import { TicketForm } from '../../types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';

export const ScheduledForField = (): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { viewOnly } = useTicketMutationContext();
  const { control } = useFormContext<TicketForm>();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({ control, name: 'scheduledFor' });

  return (
    <FormControl isInvalid={fieldState.invalid}>
      <FormLabel fontWeight="bold">
        {t('Ticketing:form.scheduled_for')}:
      </FormLabel>
      <ChakraDateInput
        showTime
        isDisabled={viewOnly}
        size={['lg', 'lg', 'sm']}
        value={value}
        onChange={onChange}
      />
      {fieldState.error && (
        <FormErrorMessage>
          {fieldState.error.message || fieldState.error.type}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
