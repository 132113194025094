import { useCallback } from 'react';

import { TicketWithDependencies } from '../../TicketTable/Dependencies';
import { useDependencyEvents } from './dependencies-events';
import { TicketDependecyMutation, TicketDependencyQueryKey } from './types';
import { useTicketFormMutation, useTicketQueryMutation } from './utils';

interface Props {
  form?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (...data: any) => void;
    defaults?: TicketWithDependencies[];
  };
  query?: { queryKey: TicketDependencyQueryKey };
  ticketID: number | null;
}
export const useTicketTreeMutation = ({ form, query, ticketID }: Props) => {
  const queryMutation = useTicketQueryMutation(query?.queryKey);
  const formMutation = useTicketFormMutation(form?.onChange, form?.defaults);

  const onSaveQuery = useCallback(
    (
      mutation: TicketDependecyMutation,
      searchingParent: number | null,
      currentParentID: number | null
    ) => {
      queryMutation({
        mutation,
        currentParentID,
        searchingParent,
      });
    },
    [queryMutation]
  );

  const onSaveState = useCallback(
    (
      mutation: TicketDependecyMutation,
      searchingParent: number | null,
      currentParentID: number | null
    ) => {
      formMutation({ mutation, currentParentID, searchingParent });
    },
    [formMutation]
  );

  useDependencyEvents('onMutation', (data) => {
    onSaveState(data.mutation, data.parentID, ticketID);
    onSaveQuery(data.mutation, data.parentID, ticketID);
  });
};
