import { useCallback } from 'react';

import { CalendarGridPos } from '@bq/components/Calendar/types';

interface CalendarCellDefaults {
  rowOffset: number;
  daysInWeek: number;
}
const defaults: CalendarCellDefaults = {
  rowOffset: 1,
  daysInWeek: 7,
};

export const useCalendarGridPos = (
  options: CalendarCellDefaults = defaults
) => {
  const getRowIdx = useCallback(
    (idx: number) => {
      const trueIdx = idx + 1;
      const calcRowIdx = Math.ceil(trueIdx / options.daysInWeek);
      const idxWithOffset = calcRowIdx + options.rowOffset;
      const idxMax = idxWithOffset + 1;

      return { start: idxWithOffset, end: idxMax };
    },
    [options]
  );
  const getColumnIdx = useCallback(
    (idx: number) => {
      const trueIdx = idx + 1;
      const calcColIdx = trueIdx % options.daysInWeek;
      const idxFix = calcColIdx === 0 ? options.daysInWeek : calcColIdx;
      const idxMax = idxFix + 1;

      return { start: idxFix, end: idxMax };
    },
    [options.daysInWeek]
  );

  return useCallback(
    (idx: number): CalendarGridPos => {
      return { row: getRowIdx(idx), column: getColumnIdx(idx) };
    },
    [getColumnIdx, getRowIdx]
  );
};
