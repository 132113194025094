import { useMemo } from 'react';
import { ButtonGroup } from '@chakra-ui/react';
import { addDays, subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import { useCalendarCtx } from '@bq/components/Calendar/CalendarCtx';
import { IconButton } from '@bq/components/IconButton';

export const DayControls = () => {
  const {
    currentDate: [date, setDate],
  } = useCalendarCtx();
  const nextDay = useMemo(() => addDays(date, 1), [date]);
  const prevDay = useMemo(() => subDays(date, 1), [date]);
  const { t } = useTranslation('global');

  return (
    <ButtonGroup isAttached variant="ghost">
      <IconButton
        onClick={() => {
          setDate(prevDay);
        }}
        icon={<FaArrowLeft />}
        label={t('global:pagination.previous')}
      />
      <IconButton
        onClick={() => {
          setDate(nextDay);
        }}
        icon={<FaArrowRight />}
        label={t('global:pagination.next')}
      />
    </ButtonGroup>
  );
};
