import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getCustomerForEvent } from './Api/customer-for-event';
import { CreateParams, CustomerAndContact } from './types';
import { useCreateParams } from './use-create-params';

export const useDefaultContact = (
  createParams?: CreateParams
): UseQueryResult<CustomerAndContact | null> => {
  const routerParams = useCreateParams();
  const { fromMailThreadID, fromCallID } = createParams ?? routerParams;

  return useQuery({
    queryKey: ['ticketDefaultContact', fromMailThreadID, fromCallID],
    queryFn: () => {
      if (fromMailThreadID) {
        return getCustomerForEvent('mail', fromMailThreadID);
      }
      if (fromCallID) {
        return getCustomerForEvent('call', fromCallID);
      }

      return null;
    },
  });
};
