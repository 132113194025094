import { ReactElement } from 'react';

import { useFormDefinition } from 'BootQuery/Assets/components/FormEditor';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { useScrollContainerRef } from 'BootQuery/Assets/js/use-scroll-container-ref';
import { useLoadUserSettings } from 'BootQuery/Assets/js/user-settings';

import { ScrollRefProvider } from '../ScrollRefCtx';
import { CompanyEmployeeList } from './CompanyEmployeeList';

export const CompanyEmployeeListPage = (): ReactElement => {
  const customFields = useFormDefinition('Phonebook.companyLocation');
  const settingsLoaded = useLoadUserSettings(
    'Phonebook.CompanyEmployeeList',
    {}
  );
  const scrollRef = useScrollContainerRef();

  if (!customFields || !settingsLoaded) {
    return <LoadingPage />;
  }

  return (
    <ScrollRefProvider scrollRef={scrollRef}>
      <CompanyEmployeeList isStandalone customFields={customFields} />
    </ScrollRefProvider>
  );
};
