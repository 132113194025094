import { useCallback } from 'react';

import { useModal } from '@bq/components/UseModal';

import { CalendarEventProviderMap } from '../OutsideUtils/use-event-type-providers';
import { DateCellClickEvent } from '../types';
import { CalendarTypeSelectModal } from './CalendarTypeSelectModal';

export const useCalendarTypeSelectModal = (
  eventTypes: CalendarEventProviderMap
) => {
  const { addModal } = useModal();

  return useCallback(
    (event: DateCellClickEvent) => {
      addModal({
        children: (
          <CalendarTypeSelectModal eventTypes={eventTypes} event={event} />
        ),
      });
    },
    [addModal, eventTypes]
  );
};
