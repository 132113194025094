import { useCallback, useState } from 'react';
import {
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { UseControllerReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { WithChange } from '@bq/components/SettingsCRUD';
import { useCrud } from '@bq/components/SettingsCRUD/use-crud';
import { useIsMobile } from '@bq/components/use-is-mobile';

import { TicketForm, TicketShare } from '../../types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';
import { useSyncCrudField } from '../use-sync-crud-field';
import { ShareModal } from './ShareModal';
import { ShareRow } from './ShareRow';

export const Shares = ({
  field,
  isDisabled,
}: UseControllerReturn<TicketForm, 'shares'> & { isDisabled?: boolean }) => {
  const { t } = useTranslation('Ticketing');
  const isMobile = useIsMobile();

  const { saveID } = useTicketMutationContext();
  const [items, modify, modifications, clearModifications] = useCrud({
    items: field.value.defaults,
  });

  const [selected, setSelected] = useState<WithChange<TicketShare> | null>(
    null
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  useSyncCrudField({ saveID, field, modifications, clearModifications });

  const onShareAdd = useCallback(() => {
    setSelected(null);
    onOpen();
  }, [onOpen, setSelected]);
  const onShareEdit = useCallback(
    (shareID: string) => {
      const share = items.find((share) => share.ID === shareID);
      if (!share) {
        throw new Error('Tried to edit a share that does not exist');
      }

      setSelected(share);
      onOpen();
    },
    [setSelected, onOpen, items]
  );
  const onShareDelete = useCallback(
    (shareID: string) => {
      modify.del(shareID);
    },
    [modify]
  );
  const onShareUndo = useCallback(
    (shareID: string) => {
      modify.undo(shareID);
    },
    [modify]
  );

  return (
    <FormControl mb={2}>
      <HStack>
        <FormLabel w="full">{t('Ticketing:shares')}</FormLabel>
        {!isDisabled && (
          <IconButton
            aria-label={t('global:add')}
            size="sm"
            variant="ghost"
            colorScheme="green"
            icon={isMobile ? <FaPlus size={25} /> : <FaPlus />}
            onClick={onShareAdd}
          />
        )}
      </HStack>
      <VStack>
        {items.map((share, idx) => (
          <ShareRow
            key={share.ID ?? idx}
            share={share}
            onShareEdit={onShareEdit}
            onShareDelete={onShareDelete}
            onShareUndo={onShareUndo}
            isDisabled={isDisabled}
          />
        ))}
      </VStack>
      {isOpen && (
        <ShareModal
          isOpen={isOpen}
          onClose={onClose}
          selected={selected}
          onChange={(data) => {
            if (data.change === 'upd') {
              modify.upd({ ...data });
            }
            if (data.change === 'add') {
              modify.add({ ...data });
            }
          }}
        />
      )}
    </FormControl>
  );
};
