import { ReactElement, ReactNode } from 'react';

export interface ConditionalWrapperProps {
  shouldWrap: boolean;
  render: (children: ReactNode) => ReactElement;
  children: Exclude<ReactNode, undefined | null>;
}

export const ConditionalWrapper = ({
  shouldWrap,
  render,
  children,
}: ConditionalWrapperProps): ReactElement => {
  if (shouldWrap) {
    return render(children);
  }

  return <>{children}</>;
};
