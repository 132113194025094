import { ReactElement } from 'react';

import { ItemClickEvent } from '@bq/components/Calendar';
import { EditTicketModalContent } from '@bq/components/TicketModal/EditTicketModal';

import { Ticket } from './types';

export const TicketCalendarModalContent = (
  props: ItemClickEvent<Ticket>
): ReactElement => {
  return <EditTicketModalContent ID={props.item.additionalProps.ID} />;
};
