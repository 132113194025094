import { Box, FormControl, FormLabel } from '@chakra-ui/react';

import { useRefreshDate } from '@bq/components/use-refresh-date';

interface Props {
  date: string | Date;
  label: string;
}
export const TicketingDescriptiveDate = ({ date, label }: Props) => {
  const { descriptiveDate } = useRefreshDate(date);

  return (
    <FormControl mb="2">
      <FormLabel fontWeight="bold">{label}</FormLabel>
      <Box>{descriptiveDate}</Box>
    </FormControl>
  );
};
