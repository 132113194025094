import { createContext, PropsWithChildren, Provider, useContext } from 'react';

import { TabControlContextProps } from './types';

const TabControlContext = createContext<TabControlContextProps | null>(null);

export const TabControlProvider = <T extends string>({
  setTab,
  tab,
  tabIndex,
  children,
}: TabControlContextProps<T> & PropsWithChildren) => {
  const Provider =
    TabControlContext.Provider as Provider<TabControlContextProps<T> | null>;

  return <Provider value={{ setTab, tab, tabIndex }}>{children}</Provider>;
};

export const useTabControlContext = <T extends string>() => {
  const ctx = useContext(TabControlContext);
  if (!ctx) {
    throw Error('Missing TabControlContext');
  }

  return ctx as unknown as TabControlContextProps<T>;
};
