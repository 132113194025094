import { ticketingEventListener } from '../ticketing-event-listener';

export function sendStoppedWatchingBeacon(): void {
  if (typeof navigator.sendBeacon !== 'function') {
    console.warn(
      'Beacon API not available, not notifying about currently open ticket'
    );
  }

  const token = ticketingEventListener().socketToken;

  // This should be application/json. It's text here because chrome is
  // currently retarded and throws some CORS errors if we try to
  // send json...
  const headers = { type: 'text/plain; charset=UTF-8' };
  const reqBody = JSON.stringify({ token, ticketID: null });
  const beaconData = new Blob([reqBody], headers);

  navigator.sendBeacon('/api/ticketing/currentlyOpenTicket', beaconData);
}
