import { ReactElement } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SimpleContactDisplay } from '@bq/components/ContactDisplay/SimpleContactDisplay';

import { TicketForm } from '../../types';

export const CreatedByDisplay = (): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { control } = useFormContext<TicketForm>();
  const createdByUser = useWatch({ control, name: 'createdByUser' });

  return (
    <FormControl>
      <FormLabel fontWeight="bold">{t('Ticketing:form.creator')}:</FormLabel>
      {createdByUser ? (
        <SimpleContactDisplay
          zIndex={0}
          contactID={createdByUser.ID}
          contactType="user"
          contactName={createdByUser.person?.fullName ?? createdByUser.username}
          fontSize="md"
        />
      ) : (
        <>-</>
      )}
    </FormControl>
  );
};
