import { ReactElement } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { DateInput } from '@bq/components/DateInput';

import { IWorkFormFields } from './types';

export const WorkAtField = (): ReactElement => {
  const { control } = useFormContext<Partial<IWorkFormFields>>();

  const { field } = useController({
    control,
    name: 'workAt',
  });

  return (
    <DateInput
      mode="single"
      value={field.value ?? null}
      onChange={field.onChange}
      onRemove={() => field.onChange(null)}
      showTime
    />
  );
};
