import { Box, TabPanel, TabPanels } from '@chakra-ui/react';

import {
  FieldValue,
  GeneratedForm,
  StaticGeneratedForm,
} from '@bq/components/FormEditor';
import { LoadingPage } from '@bq/components/LoadingPage';
import { handleScroll } from '@bq/components/scroll-events';
import { useFullFormType } from '@bq/components/TicketModal/utils';
import { useIsMobile } from '@bq/components/use-is-mobile';
import { useScrollContainerRef } from 'BootQuery/Assets/js/use-scroll-container-ref';

import { TicketEvents } from '../../TicketEvents';
import { TicketWorks } from '../../TicketWorks';
import { CreateTicketEvents } from '../CreateTicketEvents/CreateTicketEvents';
import { CreateTicketDependencies } from '../Dependencies/CreateTicketDependencies';
import { DependenciesTable } from '../Dependencies/DependenciesTable';
import { EssentialFields } from '../EssentialFields';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';

interface Props {
  customFields: FieldValue[] | null;
  mode: 'edit' | 'create';
  ticketID: number | null;
  saveID?: string;
}
export const TicketFormBodyTabs = ({
  customFields,
  mode,
  ticketID,
  saveID,
}: Props) => {
  const isMobile = useIsMobile();
  const type = useFullFormType();
  const { viewOnly } = useTicketMutationContext();
  const containerRef = useScrollContainerRef();

  return (
    <Box
      ref={containerRef}
      h="full"
      px={{ base: 0, lg: 6 }}
      py={{ base: 0, lg: 4 }}
      w="full"
      overflow="auto"
      onScroll={handleScroll}
    >
      {
        <TabPanels>
          {isMobile && (
            <TabPanel p={0} m={0}>
              <EssentialFields />
            </TabPanel>
          )}
          <TabPanel>
            {!customFields && <LoadingPage />}
            {viewOnly && customFields && (
              <StaticGeneratedForm fields={customFields} />
            )}
            {!viewOnly && customFields && (
              <GeneratedForm fields={customFields} />
            )}
          </TabPanel>
          <TabPanel>
            {mode === 'edit' ? (
              <TicketEvents
                containerRef={containerRef}
                ticketID={ticketID}
                saveID={saveID}
                viewOnly={viewOnly}
              />
            ) : (
              <CreateTicketEvents
                isDisabled={viewOnly}
                containerRef={containerRef}
              />
            )}
          </TabPanel>
          {type.canHaveDependencies && (
            <>
              {mode === 'edit' && ticketID ? (
                <TabPanel>
                  <DependenciesTable ticketID={ticketID} />
                </TabPanel>
              ) : (
                <TabPanel>
                  <CreateTicketDependencies />
                </TabPanel>
              )}
            </>
          )}
          {type.canHaveWorks && ticketID && (
            <TabPanel>
              <TicketWorks
                ticketID={ticketID}
                saveID={saveID}
                viewOnly={viewOnly}
              />
            </TabPanel>
          )}
        </TabPanels>
      }
    </Box>
  );
};
