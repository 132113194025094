import { useTranslation } from 'react-i18next';
import { FaExchangeAlt, FaTrashAlt } from 'react-icons/fa';

import { EventMenuItem } from 'app/Modules/Events/Assets/components/types';
import { CallEvent } from 'app/Modules/Telephony/Assets/components/CallEvent';

import { pickTicket } from '../../js/pick-ticket';
import { addEventToTicket } from '../Api';
import { UseEventChangesResult } from '../use-event-changes';
import { useTicketId } from '../use-ticket-id';
import { useCanDelete } from './use-can-delete';

export const useCallEventMenus = (
  eventChanges: UseEventChangesResult
): EventMenuItem[] => {
  const { t } = useTranslation('Ticketing');
  const currentTicketID = useTicketId();

  if (!useCanDelete()) {
    return [];
  }

  return [
    {
      label: t('Ticketing:move_to_another_ticket'),
      onClick(ev: CallEvent) {
        pickTicket(
          (ticket) => {
            addEventToTicket(ticket.ID, { type: 'call', callID: ev.data.ID });
            eventChanges.removeTicketEvent({
              type: 'call',
              callID: ev.data.ID,
            });
          },
          {
            title: t('Ticketing:move_to_another_ticket'),
            notTicketId: currentTicketID ?? undefined,
          }
        );
      },
      icon: FaExchangeAlt,
    } as EventMenuItem,
    {
      label: t('global:delete'),
      onClick(ev: CallEvent) {
        eventChanges.removeTicketEvent({ type: 'call', callID: ev.data.ID });
      },
      icon: FaTrashAlt,
      color: 'red',
    } as EventMenuItem,
  ];
};
