import { FaCalendar } from 'react-icons/fa';

import { ModuleEventTypeProviders } from 'app/Modules/Events/Assets/components';
import i18n from 'BootQuery/Assets/js/i18n';

import { IAppointment } from '../Appointment/schema';
import { appointmentToCalendarEvent } from './appointment-to-calendar-event';
import { AppointmentColumnEvent } from './AppointmentColumnEvent';
import { AppointmentColumnSubject } from './AppointmentColumnSubject';
import { AppointmentColumnType } from './AppointmentColumnType';
import { AppointmentColumnUser } from './AppointmentColumnUser';
import { AppointmentCreateModalContent } from './AppointmentCreateEventModalContent';
import { AppointmentEventCard } from './AppointmentEventCard';
import { AppointmentModalContent } from './AppointmentModalContent';

export const appointmentEventProvider: ModuleEventTypeProviders = {
  appointment: {
    components: {
      Card: AppointmentEventCard,
    },
    columns: {
      type: { Cell: AppointmentColumnType },
      event: { Cell: AppointmentColumnEvent },
      user: { Cell: AppointmentColumnUser },
      contact: { Cell: AppointmentColumnSubject },
    },
    calendar: {
      info: {
        icon: <FaCalendar />,
        name: () => i18n.t('Events:appointment'),
        createLabel: () => i18n.t('Events:create_appointment'),
      },
      transformFn: appointmentToCalendarEvent,
      action: {
        type: 'modal',
        callback: (data: IAppointment) => {
          // TODO: optional THIS
        },
        content: AppointmentModalContent,
        componentProps: {
          modal: {
            size: '2xl',
          },
        },
      },
      dateCellAction: {
        type: 'modal',
        callback: (data: IAppointment) => {
          // TODO: optional THIS
        },
        content: AppointmentCreateModalContent,
        componentProps: {
          modal: {
            size: '2xl',
          },
        },
      },
    },
  },
};
