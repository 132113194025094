import { useMemo } from 'react';
import { Box, VStack } from '@chakra-ui/react';

import { useHeaderColor } from '@bq/components/use-header-color';

import { useCalendarCtx } from '../CalendarCtx';
import { Day } from '../types';
import { useCalendarColors } from '../use-calendar-colors';
import { getDayName, getDayNumber } from '../utils';

interface Props {
  day: Day;
  idx: number;
}

export const TallCalendarItemHeader = ({ day, idx }: Props) => {
  const { locale } = useCalendarCtx();
  const dayNo = useMemo(
    () => getDayNumber(day.date, locale),
    [day.date, locale]
  );
  const dayName = useMemo(
    () => getDayName(day.date, locale),
    [day.date, locale]
  );
  const headerColor = useHeaderColor();
  const { borders } = useCalendarColors();

  return (
    <VStack
      borderRight="1px solid"
      borderRightColor={borders}
      borderBottom="3px solid"
      borderBottomColor="brand.600"
      zIndex={3} // -arbitrary very high zindex, other zindexes are calculated-
      // edit 03.12.2024. - from testing this index shouldn't really be more than 3
      // because all items layouted are 2, while bg is 1
      // please update this if you find some different conclusion
      top="40px"
      pos="sticky"
      bg={headerColor}
      gridColumn={`${idx + 1} / ${idx + 2}`}
      gridRow="1/2"
    >
      <Box fontSize="sm">{dayName}</Box>
      <Box fontWeight="bold" fontSize="lg">
        {dayNo}
      </Box>
    </VStack>
  );
};
