import { ReactElement } from 'react';
import { VStack } from '@chakra-ui/react';

import { NoDataMessage } from '@bq/components/NoDataMessage';

import { TicketWork } from '../types';
import { TicketWorkItem } from './TicketWorkItem';

interface Props {
  ticketID: number;
  works: TicketWork[];
}

export const TicketWorkItems = ({ ticketID, works }: Props): ReactElement => {
  if (works.length === 0) {
    return <NoDataMessage />;
  }

  return (
    <VStack w="full">
      {works.map((work) => (
        <TicketWorkItem key={work.ID} ticketID={ticketID} workID={work.ID} />
      ))}
    </VStack>
  );
};
