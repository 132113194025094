import { useMemo } from 'react';

const clipPathRight =
  'polygon( 0% 0%, calc(100% - 0.4em) 0%, 100% 50%, calc(100% - 0.4em) 100%, 0% 100%)';
const clipPathLeft =
  'polygon(100% 0%, calc(0% + 0.4em) 0%, 0% 50%, calc(0% + 0.4em) 100%, 100% 100%)';
const clipPathBothSides =
  'polygon(0% 0%, calc(100% - 0.4em) 0%, 100% 50%, calc(100% - 0.4em) 100%, 0% 100%, ' +
  '100% 100%, calc(0% + 0.4em) 100%, 0% 50%, calc(0% + 0.4em) 0%)';

export const useGetClipPath = (
  overflowsLeft: boolean,
  overflowsRight: boolean
) => {
  return useMemo(() => {
    if (overflowsLeft && !overflowsRight) {
      return clipPathLeft;
    }
    if (!overflowsLeft && overflowsRight) {
      return clipPathRight;
    }
    if (overflowsLeft && overflowsRight) {
      return clipPathBothSides;
    }

    return 'none';
  }, [overflowsLeft, overflowsRight]);
};
