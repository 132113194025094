import { PropsWithChildren, ReactElement, useCallback } from 'react';

import { useEventList } from 'app/Modules/Events/Assets/components/EventList/EventListContext';
import {
  MailEditorsProvider,
  MailEvent,
  ReplyHandler,
  SentHandler,
  useMailEditors,
} from 'app/Modules/Mails/Assets/components/EventTypes';
import { Api } from 'BootQuery/Assets/js/api';

import { useTicketingEvent } from '../js/ticketing-event-bus';
import { useEventChanges } from './use-event-changes';
import { useFormatSubject } from './use-format-subject';
import { useSelectedGroup } from './use-selected-group';
import { useSetStatusAfterReply } from './use-set-status-after-reply';

export const TicketMailEditorsProvider = ({
  children,
}: PropsWithChildren): ReactElement => {
  const handleReply = useHandleReply();
  const handleMailSent = useHandleMailSend();

  return (
    <MailEditorsProvider onSent={handleMailSent} onReply={handleReply}>
      <NewEditorHandler />
      {children}
    </MailEditorsProvider>
  );
};

function useHandleReply(): ReplyHandler {
  const selectedGroup = useSelectedGroup();
  const formatSubject = useFormatSubject();

  return useCallback(
    (replyType, message, actions) => {
      actions.replyMail(replyType, message, {
        subject: formatSubject(message.subject),
        defaultAccountID: selectedGroup?.defaultEmailAccountID,
      });
    },
    [formatSubject, selectedGroup]
  );
}

function useHandleMailSend(): SentHandler {
  const { addEvent, removeEvent } = useEventList();
  if (!removeEvent) {
    throw new Error('removeEvent is not defined');
  }
  const { addTicketEvent } = useEventChanges(removeEvent);

  const addMail = useCallback(
    (mailID: number) => {
      Api.get<MailEvent>(`/api/events/events/mail/${mailID}`).then(
        ({ data }) => {
          addEvent(data);
          addTicketEvent({ type: 'mail', mailID: data.data.rootID });
        }
      );
    },
    [addEvent, addTicketEvent]
  );

  const setStatusAfterReply = useSetStatusAfterReply();

  return useCallback(
    (mailID) => {
      addMail(mailID);
      setStatusAfterReply(mailID);
    },
    [addMail, setStatusAfterReply]
  );
}

const NewEditorHandler = (): ReactElement => {
  const { newMail } = useMailEditors();

  useTicketingEvent('mail/newEditor', newMail);

  return <></>;
};
