import { useController, useFormContext } from 'react-hook-form';

import { TicketForm } from '../../types';
import { TicketWatchStar } from './TicketWatchStar';

export const TicketCreateFollow = () => {
  const { control } = useFormContext<TicketForm>();
  const {
    field: { onChange, value },
  } = useController<TicketForm, 'watch'>({
    control,
    name: 'watch',
  });

  const toggleWatchTicket = () => {
    return onChange(!value);
  };

  return (
    <TicketWatchStar
      watchTicket={value}
      toggleWatchTicket={toggleWatchTicket}
    />
  );
};
