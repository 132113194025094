import { SetStateAction, useCallback } from 'react';

import {
  addItem,
  findItem,
  removeItem as removeDndItem,
  replaceItem,
} from '@bq/components/dnd-util';
import { uniqid } from 'app/assets/js/tsutil';

import { OverviewDragItem, RootItem } from './types';
import { isItem, itemToDragItem, resetIds } from './util';

interface Props {
  setItems: React.Dispatch<SetStateAction<OverviewDragItem[]>>;
}

interface OverviewMutations {
  addOverview: () => void;
  addGroup: () => void;
  duplicateItem: (id: string | number) => void;
  modifyItem: (
    id: string | number,
    modify: SetStateAction<OverviewDragItem>
  ) => void;
  removeItem: (id: string | number) => void;
}

export function useOverviewMutations({ setItems }: Props): OverviewMutations {
  const addNewItem = useCallback(
    (type: 'item' | 'group') => {
      const title = type === 'group' ? 'New group' : 'New overview';
      const newItem = {
        id: uniqid(),
        type,
        title,
        slug: `$new-${uniqid()}`,
        overviews: type === 'group' ? [] : undefined,
        filters: [],
        global: true,
        visible: true,
      };

      setItems((prev) => {
        const root = prev[0] as RootItem;

        return [
          {
            ...root,
            children: [...root.children, itemToDragItem(newItem)],
          },
        ];
      });
    },
    [setItems]
  );
  const addOverview = useCallback(() => addNewItem('item'), [addNewItem]);
  const addGroup = useCallback(() => addNewItem('group'), [addNewItem]);

  const removeItem = useCallback(
    (id: string | number) => {
      setItems((prev) => removeDndItem(prev, id));
    },
    [setItems]
  );
  const modifyItem = useCallback(
    (id: string | number, modify: SetStateAction<OverviewDragItem>) => {
      setItems((prev) => replaceItem(prev, id, modify));
    },
    [setItems]
  );

  const duplicateItem = useCallback(
    (id: string | number) => {
      setItems((prev) => {
        const result = findItem(prev, id);
        if (!result) {
          throw new Error(`Unable to find item to duplicate "${id}"`);
        }

        const { item } = result;
        if (!isItem(item)) {
          throw new Error('Tried to duplicate root item');
        }

        const newItem = resetIds(item);
        const parentId = result.path.at(-1) ?? null;

        return addItem(prev, newItem, { container: parentId });
      });
    },
    [setItems]
  );

  return {
    addOverview,
    addGroup,
    modifyItem,
    removeItem,
    duplicateItem,
  };
}
