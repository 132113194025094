import { useSearchParams } from 'react-router-dom';

import { CreateParams } from './types';

export function useCreateParams(): CreateParams {
  const [params] = useSearchParams();
  const fromMailThreadID = params.get('fromMailThreadID');
  const fromCallID = params.get('fromCallID');
  const fromChatID = params.get('fromChatID');

  if (fromMailThreadID) {
    return { fromMailThreadID: parseInt(fromMailThreadID, 10) };
  }
  if (fromCallID) {
    return { fromCallID: parseInt(fromCallID, 10) };
  }
  if (fromChatID) {
    return { fromChatID: parseInt(fromChatID, 10) };
  }

  return {};
}
