import { Box, VStack } from '@chakra-ui/react';

import { useHeaderColor } from '@bq/components/use-header-color';

interface Props {
  day: string;
  col: string;
  row: string;
}
export const MonthDayHeader = ({ day, col, row }: Props) => {
  const headerbg = useHeaderColor();

  return (
    <VStack
      fontWeight="bold"
      bg={headerbg}
      alignItems="center"
      justify="center"
      p={0}
      m={0}
      spacing={0}
      borderBottom="3px solid"
      borderColor="brand.600"
      position="relative"
      gridColumn={col}
      gridRow={row}
    >
      <Box>{day}</Box>
    </VStack>
  );
};
