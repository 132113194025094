import {
  groupOverlayingItems,
  sortInLayers,
  toItems,
} from './group-overlaying-items';
import { sortDays } from './sort-days';
import { sortEventsInDays } from './sort-events-in-days';
import { splitEventsIntoCalendarDays } from './split-event-into-calendar-days';
import { PopulateWeekCalendarParams } from './types';

export const getWeekItems = ({
  gridMap,
  items,
  range,
  hourShift,
}: PopulateWeekCalendarParams) => {
  const { fixedDurationEvents, wholeDayEvents } = splitEventsIntoCalendarDays(
    items,
    range,
    hourShift
  );
  // We first sort keys
  const sortedDays = sortDays(fixedDurationEvents);
  // Then we sort values for that key, the result is days with sorted items by starting hours
  const sortedItems = sortEventsInDays(sortedDays);

  const timeslots = groupOverlayingItems(sortedItems);
  const layers = sortInLayers(timeslots, sortedItems);
  const layoutedItems = toItems(layers, gridMap);

  return { fixedDurationEvents: layoutedItems, wholeDayEvents };
};
