import { useEffect } from 'react';
import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from '@tanstack/react-query';

import { UseInfiniteScrollQueryOptions } from './types';
import { useInfiniteScrollGuard } from './use-infinite-scroll-guard';

export const useInfiniteScrollQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = InfiniteData<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = unknown,
>({
  containerRef,
  minimalOverflow = 200,
  shouldStopTryingAfter = 6,
  ...opts
}: UseInfiniteScrollQueryOptions<
  TQueryFnData,
  TError,
  TData,
  TQueryFnData,
  TQueryKey,
  TPageParam
>): UseInfiniteQueryResult<TData, TError> => {
  const { fetchMoreIfNeeded } = useInfiniteScrollGuard(
    opts.queryKey,
    containerRef,
    {
      minimalOverflow,
      shouldStopTryingAfter,
    }
  );
  const result = useInfiniteQuery(opts);

  const { status, hasNextPage, fetchNextPage, fetchStatus } = result;

  useEffect(() => {
    if (status === 'success' && fetchStatus === 'idle') {
      fetchMoreIfNeeded(hasNextPage, fetchNextPage);
    }
  }, [status, fetchStatus, fetchMoreIfNeeded, fetchNextPage, hasNextPage]);

  return result;
};
