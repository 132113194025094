import { useCallback } from 'react';

import { Ticket, TicketType } from 'app/Modules/Ticketing/Assets';

import { useModal } from '../UseModal/use-modal';
import { EditTicketModalContent } from './EditTicketModal';
import { TicketModalContent } from './TicketModalContent';
import {
  CallTicketModal,
  CreateTicketModal,
  CreateTicketNoTypeModal,
  EditTicketModal,
} from './types';
import { TypeSelectModalContent } from './TypeSelect/TypeSelectModalContent';

export const useTicketModal = () => {
  const { addModal } = useModal();
  const createTicketModal: CreateTicketModal = useCallback(
    ({ closeCallback, ticketType, defaults, createParams }) => {
      addModal<Ticket>({
        callback: (data) => {
          closeCallback?.(data);
        },
        componentProps: { modal: { size: '6xl', scrollBehavior: 'inside' } },
        children: (
          <TicketModalContent
            ticketType={ticketType}
            mode="create"
            data={defaults}
            createParams={createParams}
          />
        ),
      });
    },
    [addModal]
  );
  const editTicketModal: EditTicketModal = useCallback(
    ({ closeCallback, ID, createParams }) => {
      addModal<Ticket>({
        callback: (data) => {
          closeCallback(data);
        },
        componentProps: { modal: { size: '6xl', scrollBehavior: 'inside' } },
        children: (
          <EditTicketModalContent ID={ID} createParams={createParams} />
        ),
      });
    },
    [addModal]
  );
  const ticketTypeModal: CreateTicketNoTypeModal = useCallback(
    ({ closeCallback, createParams, defaults }) => {
      addModal<TicketType>({
        callback: (data) => {
          createTicketModal({
            closeCallback,
            createParams,
            defaults,
            ticketType: data,
          });
        },
        componentProps: { modal: { size: '6xl', scrollBehavior: 'inside' } },
        children: <TypeSelectModalContent />,
      });
    },
    [addModal, createTicketModal]
  );

  /**
   * This is used to create a ticket edit/create modal, attach it to a button
   * and thats it.
   * @param mode - choose between creating a new ticket or editing a new ticket
   * @param closeCallback - data is provided on form submit that you can use
   * @param createParams - provide sources from which the ticket was created
   * so it can be linked to them
   * @param ID - provide this to edit modal to know which ticket we are editing
   * @param ticketType - optional if provided will instanly open ticket
   * form for creating a ticket, otherwise will show the ticket type select form
   */
  const callTicketModal: CallTicketModal = useCallback(
    (props) => {
      if (props.mode === 'edit') {
        editTicketModal(props);
      }
      if (props.mode === 'create') {
        if ('ticketType' in props && props.ticketType !== undefined) {
          createTicketModal(props);
        } else {
          ticketTypeModal(props);
        }
      }
    },
    [createTicketModal, editTicketModal, ticketTypeModal]
  );

  return {
    createTicketModal,
    editTicketModal,
    ticketTypeModal,
    callTicketModal,
  };
};
