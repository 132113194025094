import { MutableRefObject, ReactElement, useCallback } from 'react';
import { Box, Spinner, VStack } from '@chakra-ui/react';

import {
  CustomEventItem,
  EventMenusProvider,
} from 'app/Modules/Events/Assets/components';
import { AppointmentEventsProvider } from 'app/Modules/Events/Assets/components/Appointment';
import { IAppointment } from 'app/Modules/Events/Assets/components/Appointment/schema';
import {
  CustomEventModal,
  useCustomEventModal,
} from 'app/Modules/Events/Assets/components/CustomEventModal';
import { CustomEventsProvider } from 'app/Modules/Events/Assets/components/CustomEventsContext';
import {
  DeleteCustomEvent,
  EventListContext,
} from 'app/Modules/Events/Assets/components/EventList/EventListContext';
import { PhonebookFormsProvider } from 'app/Modules/Phonebook/Assets/components/ModalForms';
import {
  CallListSettingsProvider,
  useLoadCallListSettings,
} from 'app/Modules/Telephony/Assets/components/CallList';
import { PageWithPlayer } from 'app/Modules/Telephony/Assets/components/CallList/RecordingPlayer';
import { ContactModalProvider } from 'app/Modules/Telephony/Assets/components/ContactModal';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { Api } from 'BootQuery/Assets/js/api';
import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { useTicketingEvent } from '../js/ticketing-event-bus';
import { TicketEventsSelector } from '../js/types';
import { AddEventButton } from './AddEventButton';
import { useTicketEventMenus } from './event-menus';
import { TicketEventList } from './TicketEventList';
import { TicketMailEditorsProvider } from './TicketMailEditorsProvider';
import { useEventChanges } from './use-event-changes';
import { useTicketEvents } from './use-ticket-events';

interface Props {
  ticketID: number | null;
  fromMailThreadID?: number;
  fromCallID?: number;
  fromChatID?: number;
  saveID?: string;
  viewOnly?: boolean;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const TicketEvents = (props: Props): ReactElement => {
  const { ticketID, viewOnly, containerRef } = props;
  const eventSelector = getEventSelector(props);
  const eventQuery = useTicketEvents(eventSelector, containerRef);
  const { events, refetch, isFetching, addEvent, removeEvent, removeQuery } =
    eventQuery;
  const eventChanges = useEventChanges(removeEvent);
  const { data: callListSettings } = useLoadCallListSettings();

  useChangeEffect(
    props.saveID,
    () => {
      if (props.saveID !== undefined) {
        removeQuery();
        refetch();
      }
    },
    [removeQuery, refetch]
  );

  const onAdded = useCallback(
    async (eventID: number) => {
      const { data } = await Api.get<CustomEventItem>(
        `/api/events/events/custom/${eventID}`
      );
      addEvent(data);
      eventChanges.addTicketEvent({ type: 'custom', eventID });
    },
    [addEvent, eventChanges]
  );
  const { addCustomEvent, editCustomEvent, modalProps } = useCustomEventModal({
    onAdded,
  });

  const deleteCustomEvent: DeleteCustomEvent = useCallback(
    (eventID) => {
      removeEvent('custom', eventID.toString());
      eventChanges.removeTicketEvent({ type: 'custom', eventID });
    },
    [removeEvent, eventChanges]
  );

  useTicketingEvent(
    'call/new',
    useCallback(
      (call) => {
        console.log('Got a new call!', call);
        addEvent({
          type: 'call',
          id: call.ID.toString(),
          timestamp: new Date(call.startAt),
          data: call,
        });
      },
      [addEvent]
    )
  );

  const handleAppointmentAdded = useCallback(
    (appointment: IAppointment) => {
      console.log('New appointment', appointment);
      addEvent({
        type: 'appointment',
        id: appointment.ID.toString(),
        timestamp: new Date(appointment.startAt),
        data: appointment,
      });
      eventChanges.addTicketEvent({
        type: 'appointment',
        appointmentID: appointment.ID,
      });
    },
    [addEvent, eventChanges]
  );
  const handleAppointmentRemoved = useCallback(
    (appointmentID: number) => {
      removeEvent('appointment', appointmentID.toString());
      eventChanges.removeTicketEvent({ type: 'appointment', appointmentID });
    },
    [removeEvent, eventChanges]
  );
  const handleAppointmentModified = useCallback(
    (appointment: IAppointment) => {
      removeEvent('appointment', appointment.ID.toString());
      addEvent({
        type: 'appointment',
        id: appointment.ID.toString(),
        timestamp: new Date(appointment.startAt),
        data: appointment,
      });
    },
    [addEvent, removeEvent]
  );

  const menus = useTicketEventMenus(eventChanges);

  if (!events || !callListSettings) {
    return <LoadingPage />;
  }

  return (
    <EventListContext.Provider
      value={{
        addCustomEvent,
        editCustomEvent,
        deleteCustomEvent,
        addEvent,
        removeEvent,
        refetch,
        eventQuery,
        viewOnly,
      }}
    >
      <PageWithPlayer>
        <CallListSettingsProvider {...callListSettings}>
          <TicketMailEditorsProvider>
            <PhonebookFormsProvider>
              <ContactModalProvider>
                <EventMenusProvider menus={menus}>
                  <CustomEventsProvider>
                    <AppointmentEventsProvider
                      onAdd={handleAppointmentAdded}
                      onDelete={handleAppointmentRemoved}
                      onModify={handleAppointmentModified}
                    >
                      <VStack h="full" spacing="5" alignItems="stretch">
                        {!viewOnly && (
                          <Box marginLeft="auto">
                            <AddEventButton
                              ticketID={ticketID}
                              ticketTitle="Test"
                            />
                          </Box>
                        )}
                        <TicketEventList events={events} />
                        {isFetching && <Spinner />}
                      </VStack>
                    </AppointmentEventsProvider>
                    <CustomEventModal {...modalProps} />
                  </CustomEventsProvider>
                </EventMenusProvider>
              </ContactModalProvider>
            </PhonebookFormsProvider>
          </TicketMailEditorsProvider>
        </CallListSettingsProvider>
      </PageWithPlayer>
    </EventListContext.Provider>
  );
};

const getEventSelector = ({
  ticketID,
  fromMailThreadID,
  fromCallID,
  fromChatID,
}: Props): TicketEventsSelector => {
  if (ticketID) {
    return ticketID;
  }
  if (fromMailThreadID) {
    return { fromMailThreadID };
  }

  if (fromCallID) {
    return { fromCallID };
  }

  if (fromChatID) {
    return { fromChatID };
  }

  console.warn('Unrecognised events selector');

  return -1;
};
