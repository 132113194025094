import { Api } from 'BootQuery/Assets/js/api';

import { getTicketAgents } from '../../Api';
import { getGroupUsers } from '../../Api/groups-users';
import { AgentSelect, User } from './types';

export const loadAgents = async (search: string, groupID: number) => {
  const data = await getTicketAgents(groupID, {
    filters: {
      $search: search,
    },
  });

  return agentsToFilterVal(data);
};

export const loadAgentsMultiGroup = async (
  search: string,
  groupID: number[]
) => {
  const data = await getGroupUsers(groupID, {
    filters: {
      $search: search,
    },
  });

  return agentsToFilterVal(data ?? []);
};

const agentsToFilterVal = (users: User[]): AgentSelect[] => {
  return users.map((user) => ({
    type: 'user',
    value: user.ID,
    label: user.person?.fullName ?? user.username,
  }));
};

export const loadValueFromID = async (ID: number) => {
  const data = await Api.get<User>(`/api/users/${ID}`);

  return data.data;
};
