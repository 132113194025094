import { ReactElement } from 'react';

import { useTicketWork } from '../use-ticket-work';
import { QuickWorkTimerContent } from './QuickWorkTimerContent';

interface Props {
  ticketID: number;
  workID: number;
}

export const ExistingWorkQuickTimer = ({
  ticketID,
  workID,
}: Props): ReactElement => {
  const { work, start, stop, isMutating } = useTicketWork(ticketID, workID);

  return (
    <QuickWorkTimerContent
      title={work.title}
      inProgress={work.inProgress}
      durationSecs={work.durationSecs}
      pendingTimeSince={work.pendingTimeSince}
      onStart={start.mutate}
      onStop={stop.mutate}
      isLoading={isMutating}
    />
  );
};
