import { useState } from 'react';

import { PerTicketWatchers } from './types';
import { usePollOpenTicket } from './use-poll-open-ticket';
import { useRealTimeWatchers } from './use-real-time-watchers';
import { useUnloadNotifier } from './use-unload-notifier';
import { useWatcherList } from './use-watcher-list';

/**
 * Ensures the server is notified which ticket is currently open
 * and returns a real-time list of others watching that ticket.
 *
 * It polls the server periodically (look at docs of usePollOpenTicket for more info)
 * and handles closing the tab/refreshing/closing browser to notify that we stopped watching.
 *
 * It also listens to websocket events (see useRealTimeWatchers) to update
 * watchers in real-time
 *
 * @param ticketID Currently open ticketID.
 * @returns List of user IDs watching the current ticket
 */
export function useTicketWatchers(ticketID: number | null): number[] {
  const [watchers, setWatchers] = useState<PerTicketWatchers>({});

  usePollOpenTicket(ticketID, setWatchers);
  useRealTimeWatchers(ticketID, setWatchers);
  useUnloadNotifier();

  return useWatcherList(watchers, ticketID);
}
