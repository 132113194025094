import { MutableRefObject } from 'react';

import {
  useEvents,
  UseEventsResult,
} from 'app/Modules/Events/Assets/components';
import { EventFiltersParam } from 'app/Modules/Events/Assets/components/api';

import { TicketEventsSelector } from '../js/types';

export function useTicketEvents(
  selector: TicketEventsSelector,
  containerRef: MutableRefObject<HTMLDivElement | null>
): UseEventsResult {
  return useEvents(
    {
      filters: filtersForSelector(selector),
    },
    containerRef
  );
}

function filtersForSelector(selector: TicketEventsSelector): EventFiltersParam {
  if (typeof selector === 'string' || typeof selector === 'number') {
    return { ticketID: selector };
  }

  if ('fromCallID' in selector) {
    return { type: 'call', callID: selector.fromCallID };
  }

  if ('fromMailThreadID' in selector) {
    return { type: 'mail', mailRootID: selector.fromMailThreadID };
  }

  if ('fromChatID' in selector) {
    return { type: 'customerChat', customerChatID: selector.fromChatID };
  }

  console.error('Invalid ticket events selector', selector);
  throw new Error('Invalid ticket events selector');
}
