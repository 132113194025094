import { Fragment, memo, ReactElement, useCallback, useMemo } from 'react';
import { Box, Center, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { keepPreviousData } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import { Card, CardBody } from '@bq/components/Card';
import { useConfirmationModal } from '@bq/components/ConfirmationModal/use-confirmation-modal';
import {
  useHeadingProps,
  useListingSettings,
  useSplitColumnsFeatures,
} from '@bq/components/ListingSettings';
import { ListWrapper } from '@bq/components/ListWrapper';
import { useScrollEvents } from '@bq/components/scroll-events';
import { FieldValue } from 'BootQuery/Assets/components/FormEditor';
import { ListHeading } from 'BootQuery/Assets/components/ListHeading';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import {
  CardBulkActionMenu,
  Column,
  TableSelectionProvider,
} from 'BootQuery/Assets/components/Table';
import { useInfiniteScrollQuery } from 'BootQuery/Assets/js/use-infinite-scroll-query';

import { phonebookDisplayModeOptions } from '../CompanyEmployeeList/display-settings';
import { CompanyCardFeatures } from '../CompanyList/types';
import { PhonebookListContext } from '../PhonebookListContext';
import { useScrollRefCtx } from '../ScrollRefCtx';
import { CompanyLocationApiData } from '../types';
import { useSelectedOverview } from '../use-selected-overview';
import { columns, companyLocationsCardFeatures } from './columns';
import {
  deleteItem,
  sendSms,
  sendSmsEmployees,
} from './company-locations-bulk-actions';
import { CompanyLocationCard } from './CompanyLocationCard/CompanyLocationCard';
import { filterTypes } from './filter-types';
import { pickCorrectQuery } from './utils';

interface Props {
  companyID?: number;
  customFields?: FieldValue[];
  cardFeatures?: Partial<CompanyCardFeatures>;
  customColumns?: Column<CompanyLocationApiData>[];
}

const AddButton = memo(({ companyID }: Props): ReactElement => {
  const buttonLink = companyID
    ? `/phonebook/companies/${companyID}/locations/create`
    : '/phonebook/company-locations/create';

  return (
    <Tooltip hasArrow label="Add">
      <IconButton
        as={Link}
        to={buttonLink}
        variant="outline"
        colorScheme="green"
        aria-label="Add"
        icon={<FaPlus />}
      />
    </Tooltip>
  );
});
AddButton.displayName = 'AddButton';

export const MobileCompanyLocationsList = ({
  companyID,
  customFields = [],
  cardFeatures,
}: Props): ReactElement => {
  const overview = useSelectedOverview('companyLocations');
  const confirmModal = useConfirmationModal();
  const listingSettings = useListingSettings<CompanyLocationApiData>({
    listingName: companyID
      ? 'Phonebook.CompanyLocationListEmbed'
      : 'Phonebook.CompanyLocationList',
    viewName: 'card',
    filterTypes,
    columns: columns(),
    otherFilters: overview?.filters ?? [],
    customFields,
  });
  const { t } = useTranslation('Phonebook');
  const { filters, page, limit, columnsToShow } = listingSettings;
  const queryKey = useMemo(
    () => [
      companyID
        ? 'Phonebook.CompanyLocationListEmbed.mobile'
        : 'Phonebook.CompanyLocationList.mobile',
      companyID,
      page,
      limit,
      filters,
    ],
    [companyID, filters, limit, page]
  );
  const { scrollRef } = useScrollRefCtx();
  const { data, refetch, fetchNextPage } = useInfiniteScrollQuery({
    queryKey,
    containerRef: scrollRef,
    queryFn: async ({ pageParam = 1 }) => {
      const data = await pickCorrectQuery(
        { page: pageParam, limit, filters },
        companyID
      );
      const maxPages = Math.ceil((data?.meta?.count ?? 1) / limit);

      const shouldFetchMore = pageParam < maxPages;

      return { ...data, page: pageParam, shouldFetchMore };
    },

    enabled: filters !== undefined,
    placeholderData: keepPreviousData,
    getNextPageParam: (lastPage) => {
      if (lastPage.shouldFetchMore) {
        return lastPage.page + 1;
      }

      return null;
    },
    initialPageParam: 1,
  });

  const noPages = data?.pages.length ?? 1;
  const shouldFetchMore = data?.pages[noPages - 1]?.shouldFetchMore;
  const navigate = useNavigate();
  const bulkActions = useMemo(
    () => [
      {
        actions: [sendSms(navigate), sendSmsEmployees(navigate)],
        styleProps: { colorScheme: 'brand', size: 'sm' },
      },
      {
        actions: [deleteItem(refetch, confirmModal)],
        styleProps: {
          colorScheme: 'red',
          variant: 'outline',
          style: {
            marginLeft: 'auto',
          },
        },
      },
    ],

    [navigate, refetch, confirmModal]
  );

  useScrollEvents(
    'scrollBottom',
    useCallback(() => {
      fetchNextPage();
    }, [fetchNextPage])
  );

  const { columns: customColumns, features: customFeatures } =
    useSplitColumnsFeatures(columnsToShow, companyLocationsCardFeatures);
  const headingProps = useHeadingProps({
    displayModeOptions: phonebookDisplayModeOptions,
    listingProps: listingSettings,
    count: null,
    noPagination: true,
  });

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <PhonebookListContext.Provider value={{ refetch }}>
      <TableSelectionProvider<CompanyLocationApiData>
        page={1}
        idColumn="ID"
        selectPage={() => data?.pages[page]?.data ?? []}
        selectAll={async () => {
          const { data } = await pickCorrectQuery(
            {
              page: 1,
              limit: 99999,
              filters,
              fields: [
                'ID',
                'name',
                'phoneNumbers.phoneNumber.phoneNumberE164',
              ],
            },
            companyID
          );

          return data;
        }}
      >
        <ListWrapper w="full" spacing="5" px="3" py="3">
          <Card>
            <CardBody>
              <ListHeading
                {...headingProps}
                menuProps={{
                  ...headingProps.menuProps,
                  displayMode: undefined,
                  density: undefined,
                }}
                Actions={<AddButton companyID={companyID} />}
              />
            </CardBody>
          </Card>

          {data?.pages.map((page, idx) => (
            <Fragment key={idx}>
              {page?.data.map((company) => (
                <CompanyLocationCard
                  key={company.ID}
                  location={company}
                  companyID={companyID}
                  features={{ ...customFeatures, ...cardFeatures }}
                  customColumns={customColumns}
                />
              ))}
            </Fragment>
          ))}
          {!shouldFetchMore && (
            <Center pt="2" m={0} mb={4}>
              <Text
                color="GrayText"
                textAlign="center"
                fontWeight="bold"
                fontSize="xl"
                m={0}
              >
                {`${t('global:no_more_results')}...`}
              </Text>
            </Center>
          )}
        </ListWrapper>
        <Box
          display="flex"
          w="full"
          left="0"
          position="fixed"
          bottom="75px"
          zIndex="4"
        >
          <CardBulkActionMenu<CompanyLocationApiData>
            actionGroups={bulkActions}
          />
        </Box>
      </TableSelectionProvider>
    </PhonebookListContext.Provider>
  );
};
