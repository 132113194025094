import { ReactElement, useCallback } from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  FaCalendarCheck,
  FaChevronDown,
  FaEnvelope,
  FaPhone,
  FaPlus,
} from 'react-icons/fa';

import { useCreatableCustomEventTypes } from 'app/Modules/Events/Assets/components';
import { useAppointmentFormModal } from 'app/Modules/Events/Assets/components/Appointment';
import { useEventList } from 'app/Modules/Events/Assets/components/EventList/EventListContext';
import { CustomEventType } from 'app/Modules/Events/Assets/components/types';
import { useMailEditors } from 'app/Modules/Mails/Assets/components/EventTypes';
import { WithSeparator } from 'BootQuery/Assets/components/WithSeparator';

import { AddCallModal } from './AddCallModal';

interface Props {
  ticketID: number | null;
  ticketTitle: string;
}

export const AddEventButton = (ticket: Props): ReactElement => {
  const { ticketID } = ticket;
  const { t } = useTranslation('Ticketing');
  const visibleCustomTypes = useCreatableCustomEventTypes();
  const { newMail } = useMailEditors();
  const { addCustomEvent } = useEventList();
  const openAppointmentModal = useAppointmentFormModal();
  const {
    isOpen: callModalOpen,
    onOpen: openCallModal,
    onClose: closeCallModal,
  } = useDisclosure();

  const add = (type: CustomEventType) => {
    addCustomEvent({
      type: {
        value: type.ID.toString(),
        label: type.name,
      },
      tickets: customEventTicketLinks(ticketID ? ticket : null),
    });
  };

  const addMail = useCallback(() => {
    newMail();
  }, [newMail]);

  const addAppointment = useCallback(() => {
    openAppointmentModal({ mode: 'create' });
  }, [openAppointmentModal]);

  return (
    <>
      <Menu isLazy>
        <MenuButton
          mb="10px"
          as={Button}
          colorScheme="green"
          rightIcon={<FaChevronDown />}
        >
          <FaPlus style={{ display: 'inline' }} />
          &nbsp;
          {t('global:add')}
        </MenuButton>
        <MenuList>
          <WithSeparator separator={<MenuDivider />}>
            <>
              <MenuItem onClick={addMail}>
                <FaEnvelope style={{ display: 'inline' }} />
                &nbsp; {t('Ticketing:mail')}
              </MenuItem>
              <MenuItem onClick={openCallModal}>
                <FaPhone style={{ display: 'inline' }} />
                &nbsp; {t('Ticketing:call')}
              </MenuItem>
              <MenuItem onClick={addAppointment}>
                <FaCalendarCheck style={{ display: 'inline' }} />
                &nbsp; {t('Events:appointment')}
              </MenuItem>
            </>
            {visibleCustomTypes.length > 0 && (
              <>
                {visibleCustomTypes.map((type) => (
                  <MenuItem key={`event-${type.ID}`} onClick={() => add(type)}>
                    {type.name}
                  </MenuItem>
                ))}
              </>
            )}
          </WithSeparator>
        </MenuList>
      </Menu>
      <AddCallModal isOpen={callModalOpen} onClose={closeCallModal} />
    </>
  );
};

function customEventTicketLinks(ticket: Props | null) {
  if (!ticket || !ticket.ticketID) {
    return undefined;
  }

  return [
    {
      ticket: {
        ID: ticket.ticketID,
        name: ticket.ticketTitle,
      },
    },
  ];
}
