import { Button, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaLink } from 'react-icons/fa';

import { AddOtherTicketAsDepFormContent } from './AddOtherTicketAsDepForm';

export interface AddOtherTicketAsDepForm {
  deps: { ID: number; title: string }[];
}

interface Props {
  excludedTickets?: number | number[];
  ticketID?: number | null;
  onSubmit: (data: number[]) => void;
}
export const AddOtherTicketAsDep = ({
  excludedTickets,
  ticketID,
  onSubmit,
}: Props) => {
  const { t } = useTranslation('Ticketing');
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme="green" rightIcon={<FaLink />}>
        {t('Ticketing:add_subticket')}
      </Button>
      {isOpen && (
        <AddOtherTicketAsDepFormContent
          isOpen={isOpen}
          onClose={onClose}
          ticketID={ticketID}
          excludedTickets={excludedTickets}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
