import { useMemo } from 'react';

import { Ticket } from '../types';
import { TicketWithDependencies, useDependencies } from './Dependencies';
import { TicketRow } from './types';

export function useTicketsWithDependencies(tickets?: Ticket[]): TicketRow[] {
  const { expandedTickets, dependencyQueries } = useDependencies();

  return useMemo(() => {
    if (!tickets) {
      return [];
    }

    return tickets.reduce((prev, ticket) => {
      const isExpanded = expandedTickets[ticket.ID] ?? false;
      const deps = dependencyQueries[ticket.ID]?.data;
      if (isExpanded && deps) {
        return [
          ...prev,
          { ...ticket, hasNext: true },
          ...expandDependencies(deps),
        ];
      }

      return [...prev, ticket];
    }, [] as Ticket[]);
  }, [dependencyQueries, expandedTickets, tickets]);
}
export function expandDependencies(
  deps: TicketWithDependencies[],
  level = 0,
  parentID: number | undefined = undefined
): TicketRow[] {
  return deps.reduce(
    (prev, dep, idx) => [
      ...prev,
      {
        ...dep,
        level,
        hasNextDep: idx < deps.length - 1,
        hasChildren: dep.dependencies.length > 0,
        parentID,
      },
      ...expandDependencies(dep.dependencies, level + 1, dep.ID),
    ],
    [] as TicketRow[]
  );
}
