import { useMemo } from 'react';

import { useTicketingContext } from './TicketingContext';
import { TicketGroup } from './types';

export function useOwnGroups(): TicketGroup[] {
  const { groups, ownGroupsIds } = useTicketingContext();

  return useMemo(() => {
    return groups
      .filter((group) => ownGroupsIds.includes(group.ID))
      .sort((groupA, groupB) => {
        const idxA = ownGroupsIds.indexOf(groupA.ID);
        const idxB = ownGroupsIds.indexOf(groupB.ID);

        return Math.sign(idxA - idxB);
      });
  }, [groups, ownGroupsIds]);
}
