import { eachHourOfInterval, format } from 'date-fns';

import {
  CalendarGridMap,
  CalendarItemSplitted,
  HourItem,
  ValidCalendarItemsSplitByDay,
} from '@bq/components/Calendar/types';
import { uniqid } from 'app/assets/js/tsutil';

import { calculateEventHeight } from './calculate-event-height';
import { calculateEventOffset } from './calculate-event-offset';
import { sortItemsInArray } from './sort-items-in-array';
import {
  Layer,
  LayerInfo,
  Layers,
  TimeslotDayMap,
  TimeslotInfo,
  TimeslotLayoutResult,
  Timeslots,
} from './types';
import { getWeekGridPositionFromDate } from './week-grid-map-utils';

export const groupOverlayingItems = (events: ValidCalendarItemsSplitByDay) => {
  return Object.entries(events).reduce<TimeslotDayMap>((all, current) => {
    const [key, items] = current;
    let alreadyLayouted: TimeslotLayoutResult = {};

    const newItems = items.reduce<Timeslots>((all, current, itemIdx) => {
      const hours = eachHourOfInterval({
        start: current.startDate,
        end: current.endDate,
      });

      const slots = hours.reduce<Timeslots>((timeslots, hour) => {
        const wasAlreadyLayouted =
          alreadyLayouted[current.ID as keyof TimeslotLayoutResult];
        const hourKey = format(hour, 'HH:mm');
        const allPrev = timeslots[hourKey] ?? [];

        if (wasAlreadyLayouted) {
          return {
            ...timeslots,
            [hourKey]: [...allPrev, wasAlreadyLayouted],
          };
        }

        const itemBefore = allPrev[allPrev.length - 1];

        const layer = getLayer(itemBefore, hourKey);

        const item = {
          layer: layer.l,
          startPos: hourKey,
          layerUUID: layer.uuid,
          dayArrayIdx: itemIdx,
        };

        alreadyLayouted = { ...alreadyLayouted, [current.ID]: item };

        return {
          ...timeslots,
          [hourKey]: [...allPrev, item],
        };
      }, all);

      return { ...all, ...slots };
    }, {});

    return { ...all, [key]: { timeslots: newItems, result: alreadyLayouted } };
  }, {});
};

export const sortInLayers = (
  result: TimeslotDayMap,
  items: ValidCalendarItemsSplitByDay
) => {
  return Object.entries(result).reduce<Layers>((all, [key, { result }]) => {
    return {
      ...all,
      ...Object.values(result).reduce<Layers>((all, current: TimeslotInfo) => {
        const prev: Layer = all[current.layerUUID] ?? {
          level: current.layer,
          items: [],
        };

        const readItem = items[key][current.dayArrayIdx];

        return {
          ...all,
          [current.layerUUID]: {
            ...prev,
            items: [...prev.items, readItem],
            layerStartAt: current.startPos,
          },
        };
      }, {}),
    };
  }, {});
};

export const toItems = (layers: Layers, gridMap: CalendarGridMap) => {
  const toArray = Object.values(layers).sort((a, b) => {
    const [aHours, aMinutes] = a.layerStartAt.split(':').map(Number);
    const [bHours, bMinutes] = b.layerStartAt.split(':').map(Number);

    if (aHours !== bHours) {
      return aHours - bHours;
    }

    return aMinutes - bMinutes;
  });

  return toArray.reduce<HourItem[]>((all, current) => {
    const sortedItems = sortItemsInArray(current.items);
    const items = sortedItems.map((item, idx) => {
      const layoutProps = calcLayout(
        item,
        gridMap,
        current.level,
        current.items.length,
        idx
      );

      const itemWithLayoutProps: HourItem = {
        ...item,
        ...layoutProps,
        level: current.level,
        calendarItemType: 'item',
      };

      return itemWithLayoutProps;
    });

    return [...all, ...items];
  }, []);
};

const getLayer = (
  itemBefore: TimeslotInfo | undefined,
  currentKey: string
): LayerInfo => {
  if (itemBefore) {
    if (itemBefore.startPos === currentKey) {
      return { l: itemBefore.layer, uuid: itemBefore.layerUUID };
    }
    const uuid = uniqid();

    return { l: itemBefore.layer + 1, uuid };
  }
  const uuid = uniqid();

  return { l: 0, uuid };
};

const calcLayout = (
  item: CalendarItemSplitted,
  gridMap: CalendarGridMap,
  layer: number,
  numberOfItemsInLayer: number,
  order: number
) => {
  const maxWidth = 93;
  const indent = 3;
  const startLeftOffset = layer * indent;
  const cutoff = layer > 1 ? startLeftOffset * 2 : startLeftOffset;
  const remainingWidth = maxWidth - cutoff;
  const itemWidth = remainingWidth / numberOfItemsInLayer;
  const previousInstancesReservedSpace = order * itemWidth;
  const trueOffset = previousInstancesReservedSpace + startLeftOffset;
  const height = calculateEventHeight(item.startDate, item.endDate);
  const offset = calculateEventOffset(item.startDate);
  const startPos = getWeekGridPositionFromDate(item.startDate, gridMap);
  const endPos = getWeekGridPositionFromDate(item.endDate, gridMap);

  return {
    width: `${itemWidth}%`,
    leftOffset: `${trueOffset}%`,
    height: `${height}%`,
    topOffset: offset,
    row: { start: startPos.row.start, end: endPos.row.end },
    column: { start: startPos.column.start, end: endPos.column.end },
  };
};
