import { useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';

import { CalendarGridMap } from '../types';
import { getWeekGridPositionFromDate } from '../Views/Week/utils/week-grid-map-utils';

interface Props {
  gridMap: CalendarGridMap;
}
export const NowIndicator = ({ gridMap }: Props) => {
  const now = useMemo(() => new Date(), []);
  const pos = useMemo(
    () => getWeekGridPositionFromDate(now, gridMap),
    [gridMap, now]
  );

  return (
    <HStack
      color="brand.600"
      gridColumn={`${pos.column.start} / ${pos.column.end}`}
      gridRow={`${pos.row.start} / ${pos.row.end}`}
      w="full"
      spacing={0}
    >
      <Box>
        <FaCircle />
      </Box>
      <Box h="2px" bg="brand.600" w="full" />
    </HStack>
  );
};
