import { ReactElement } from 'react';
import { DragOverlay } from '@dnd-kit/core';

import { useActiveItem } from '@bq/components/DndContainers';

import { OverviewEditorItem } from './OverviewEditorItem';
import { OverviewDragItemContent } from './types';

export const OverviewEditorDragOverlay = (): ReactElement => {
  const activeItem = useActiveItem<OverviewDragItemContent>();

  return (
    <DragOverlay>
      {activeItem && <OverviewEditorItem {...activeItem.item} />}
    </DragOverlay>
  );
};
