import { useTicketWork } from '../use-ticket-work';
import { TicketWorkItemContent } from './TicketWorkItemContent';

interface Props {
  ticketID: number;
  workID: number;
}

export const TicketWorkItem = ({ ticketID, workID }: Props) => {
  const { work, start, stop, update, remove, isMutating } = useTicketWork(
    ticketID,
    workID
  );

  return (
    <TicketWorkItemContent
      ticketID={ticketID}
      isLoading={isMutating}
      onStart={start.mutate}
      onStop={stop.mutate}
      onUpdate={update.mutate}
      onDelete={remove.mutate}
      work={work}
    />
  );
};
