import { SetStateAction, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { applySetState } from 'app/assets/js/apply-set-state';

import { TicketWork } from '../types';

export type WorksChangerFn = (prev: TicketWork[]) => TicketWork[];
export type MutateQueryWorksResult = (updater: WorksChangerFn) => void;
export type UpdateWorkFn = (
  updateID: number,
  updated: SetStateAction<TicketWork>
) => void;
export type AddWorkFn = (updated: TicketWork) => void;
export type DelWorkFn = (workID: number) => void;

export function useSetQueryWorks(ticketID: number): MutateQueryWorksResult {
  const queryClient = useQueryClient();

  return useCallback(
    (updater: WorksChangerFn) => {
      queryClient.setQueryData<TicketWork[]>(
        ['Ticketing.ticketWorks', ticketID],
        (prev) => {
          if (!prev) {
            return prev;
          }

          return updater(prev);
        }
      );
    },
    [ticketID, queryClient]
  );
}

export function useUpdateQueryWork(ticketID: number): UpdateWorkFn {
  const setWorks = useSetQueryWorks(ticketID);

  return useCallback(
    (updatedID: number, updated: SetStateAction<TicketWork>) => {
      setWorks((prev) => {
        return prev.map((work) => {
          if (work.ID === updatedID) {
            return applySetState(work, updated);
          }

          return work;
        });
      });
    },
    [setWorks]
  );
}

export function useAddQueryWork(ticketID: number): AddWorkFn {
  const setWorks = useSetQueryWorks(ticketID);

  return useCallback(
    (newWork: TicketWork) => {
      setWorks((prev) => {
        const exists = prev.find((work) => work.ID === newWork.ID);
        if (exists) {
          return prev;
        }

        return [...prev, newWork];
      });
    },
    [setWorks]
  );
}

export function useRemoveQueryWork(ticketID: number): DelWorkFn {
  const setWorks = useSetQueryWorks(ticketID);

  return useCallback(
    (workID: number) => {
      setWorks((prev) => prev.filter((work) => work.ID !== workID));
    },
    [setWorks]
  );
}
