import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTicketingContext } from './TicketingContext';
import { TicketForm } from './types';

type OnReply = (sentID: number) => void;

export function useSetStatusAfterReply(): OnReply {
  const ticketing = useTicketingContext();
  const formMethods = useFormContext<TicketForm>();

  return useCallback(() => {
    const groupID = formMethods.getValues('group.ID');
    const typeID = formMethods.getValues('ticketType.ID');
    const group = ticketing.groups.find((group) => group.ID === groupID);
    const type = ticketing.types.find((type) => type.ID === typeID);

    if (type && group?.stateAfterAgentReply) {
      const state = type.states.find(
        (state) => state.state.ID === group.stateAfterAgentReply
      );

      console.log('Updating state: ', state);

      if (state) {
        formMethods.setValue('state', state.state);
      }
    }
  }, [formMethods, ticketing.groups, ticketing.types]);
}
