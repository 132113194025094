import { ReactElement } from 'react';
import {
  Divider,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DurationInput } from '@bq/components/DurationInput';
import { GeneratedForm } from '@bq/components/FormEditor';
import { LoadingPage } from '@bq/components/LoadingPage';

import { IWorkFormFields } from './types';
import { useWorkFormDefinition } from './use-work-form-definition';
import { WorkAtField } from './WorkAtField';

export const WorkFormFields = (): ReactElement => {
  const { t } = useTranslation('Ticketing');

  const customFields = useWorkFormDefinition();

  const {
    register,
    formState: { errors },
  } = useFormContext<Partial<IWorkFormFields>>();

  return (
    <VStack spacing={4} w="full" alignItems="stretch">
      <FormControl isInvalid={!!errors.title}>
        <FormLabel htmlFor="title">{t('global:title')}</FormLabel>
        <Input
          id="title"
          {...register('title', { required: true })}
          isInvalid={!!errors.title}
          placeholder={t('global:title')}
        />
      </FormControl>
      <FormControl isInvalid={!!errors.workAt}>
        <FormLabel>{t('Ticketing:work_at')}</FormLabel>
        <WorkAtField />
      </FormControl>
      <FormControl isInvalid={!!errors.duration}>
        <FormLabel htmlFor="workDuration">
          {t('Ticketing:work_duration_secs')}
        </FormLabel>
        <DurationInput
          register={register}
          id="workDuration"
          fieldName="duration"
        />
      </FormControl>
      <Divider />
      {customFields ? (
        <GeneratedForm prefix="data" fields={customFields} />
      ) : (
        <LoadingPage />
      )}
    </VStack>
  );
};
