import { Box, HStack, Tag, Text } from '@chakra-ui/react';
import {
  chakraComponents,
  GroupBase,
  SingleValueProps,
} from 'chakra-react-select';

import { TicketSelectOption } from './types';

export const TicketSelectValue = (
  props: SingleValueProps<
    TicketSelectOption,
    false,
    GroupBase<TicketSelectOption>
  >
) => {
  const {
    data: { state, label },
  } = props;

  return (
    <chakraComponents.SingleValue {...props}>
      <HStack w="full">
        <Tag bg={state.color} size="sm" flex="0 0 auto">
          {state.name}
        </Tag>
        <Box fontWeight="bold" flex="1 1 auto" minWidth={0}>
          {/* This minW is very important fixes text overflow on firefox */}
          {/* https://stackoverflow.com/questions/26465745/ellipsis-in-flexbox-container */}
          <Text
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            m={0}
          >
            {label}
          </Text>
        </Box>
      </HStack>
    </chakraComponents.SingleValue>
  );
};
