import { useCallback, useRef } from 'react';

import { useSocketEvents } from 'BootQuery/Assets/js/use-socket-events';

import { ticketingEventListener } from '../ticketing-event-listener';
import { UpdateWatchers, WatcherMap } from './types';

interface WatchersChangeEvent {
  currentWatchers: WatcherMap;
  ticketID: number;
}

export function useRealTimeWatchers(
  ticketID: number | null,
  updateWatcherState: UpdateWatchers
): void {
  // Store as ref to prevent callback deps from changing
  // This should prevent re-subscribing to events
  const ticketIDRef = useRef<number | null>(ticketID);
  ticketIDRef.current = ticketID;

  const handleWatchersChange = useCallback(
    (data: WatchersChangeEvent) => {
      const currentTicketID = ticketIDRef.current;

      if (!currentTicketID || data.ticketID !== currentTicketID) {
        return;
      }

      updateWatcherState((prev) => ({
        ...prev,
        [data.ticketID]: data.currentWatchers,
      }));
    },
    [updateWatcherState]
  );

  useSocketEvents(
    ticketingEventListener(),
    'ticketWatchers',
    handleWatchersChange
  );
}
