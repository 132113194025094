import { ReactElement, useCallback } from 'react';

import { useTicketWorks } from '../use-ticket-works';
import { QuickWorkTimerContent } from './QuickWorkTimerContent';

interface Props {
  ticketID: number;
}

/**
 * If no works had been added, show a placeholder. Starting the timer will
 * actually create a new work that is initially in progress.
 */
export const PlaceholderWorkQuickTimer = ({
  ticketID,
}: Props): ReactElement => {
  const { createWork } = useTicketWorks(ticketID);

  const doCreateWork = createWork.mutate;
  const handleStart = useCallback(() => {
    doCreateWork({
      title: null,
      inProgress: true,
    });
  }, [doCreateWork]);

  return (
    <QuickWorkTimerContent
      title={null}
      inProgress={false}
      durationSecs={0}
      pendingTimeSince={null}
      onStart={handleStart}
      isLoading={createWork.isPending}
    />
  );
};
