import { SelectOption } from 'BootQuery/Assets/js/globalTypes';

import { getReportMethods } from '../../Api';
import { TicketReportMethod } from '../../types';
import { translateReportMethodName } from '../../utils';

export async function loadOptions(
  search: string
): Promise<SelectOption<number>[]> {
  const data = await getReportMethods({
    filters: {
      $search: search,
    },
  });

  return data.map(reportMethodToOption);
}

export function reportMethodToOption(
  tag: TicketReportMethod
): SelectOption<number> {
  if (tag.isSystem) {
    return {
      label: translateReportMethodName(tag.name),
      value: tag.ID,
    };
  }

  return {
    label: tag.name,
    value: tag.ID,
  };
}
