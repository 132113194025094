import { useColorModeValue } from '@chakra-ui/react';

export const useCalendarColors = () => {
  const fill = useColorModeValue('white', 'brand.backgroundDark');
  const currentMonthColor = useColorModeValue(
    'blackAlpha.800',
    'whiteAlpha.800'
  );
  const notCurrentMonthColor = useColorModeValue(
    'blackAlpha.400',
    'whiteAlpha.400'
  );
  const borders = useColorModeValue('blackAlpha.300', 'whiteAlpha.200');

  return { fill, currentMonthColor, notCurrentMonthColor, borders };
};
