import { useMemo } from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';

import { Portal } from '@bq/components/Portal';

import { useCalendarCtx } from '../CalendarCtx';
import { CalendarView } from '../types';

const allowedViews: string[] = ['day', 'month', 'week'];

export const CalendarMenu = () => {
  const {
    view: [view, setView],
  } = useCalendarCtx();
  const { t } = useTranslation();

  const viewTranslation = useMemo(() => {
    switch (view) {
      case 'month':
        return t('global:time_units.month');
      case 'week':
        return t('global:time_units.week');
      case 'day':
        return t('global:time_units.day');
      default:
        throw Error('Unknown view');
    }
  }, [t, view]);

  // Here we can add more things later

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<FaChevronDown />}
        variant="outline"
        size="sm"
      >
        {viewTranslation}
      </MenuButton>
      <Portal>
        <MenuList minWidth="240px" zIndex={10} border="none">
          <MenuOptionGroup
            title={t('global:view')}
            defaultValue={view}
            type="radio"
            onChange={(val) => {
              if (typeof val === 'string' && allowedViews.includes(val)) {
                setView(val as CalendarView);
              }
            }}
          >
            <MenuItemOption value="month">
              {t('global:time_units.month')}
            </MenuItemOption>
            <MenuItemOption value="week">
              {t('global:time_units.week')}
            </MenuItemOption>
            <MenuItemOption value="day">
              {t('global:time_units.day')}
            </MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  );
};
