import {
  CalendarGridMap,
  CalendarGridPos,
  Day,
} from '@bq/components/Calendar/types';

export const weekGridWithDays = (
  weekGrid: CalendarGridMap,
  week: Day[],
  startIdx = 2
) => {
  const rowStart = startIdx;
  const rowEnd = startIdx + 1;

  const daysGrid = week.reduce<CalendarGridMap>((all, current, idx) => {
    const columnStart = idx + 1;
    const columnEnd = idx + 2;

    const pos: CalendarGridPos = {
      column: { start: columnStart, end: columnEnd },
      row: { start: rowStart, end: rowEnd },
    };

    return { ...all, [current.key]: pos };
  }, {});

  return { ...weekGrid, ...daysGrid };
};
