import { createContext, SetStateAction } from 'react';
import { UniqueIdentifier } from '@dnd-kit/core';

import { makeUseContext } from '@bq/components/make-use-context';

import { OverviewDragItem } from './types';

export interface IOverviewEditorContext {
  editingPersonal: boolean;
  withGroupVisibility: boolean;

  selectedId: string | null;
  setSelectedId: (id: string | null) => void;

  dragItems: OverviewDragItem[];
  removeItem: (id: UniqueIdentifier) => void;
  modifyItem: (
    id: UniqueIdentifier,
    modify: SetStateAction<OverviewDragItem>
  ) => void;
  duplicateItem: (id: string | number) => void;
}

export const OverviewEditorContext =
  createContext<IOverviewEditorContext | null>(null);

export const useOverviewEditorContext = makeUseContext({
  OverviewEditorContext,
});
