import { useMemo } from 'react';
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useHeaderColor } from '@bq/components/use-header-color';
import { useModalInstance } from '@bq/components/UseModal';

import { useCalendarDateCellActionHookInvoker } from '../OutsideUtils/use-calendar-date-cell-click-invoker';
import { CalendarEventProviderMap } from '../OutsideUtils/use-event-type-providers';
import { DateCellClickEvent } from '../types';

interface Props {
  eventTypes: CalendarEventProviderMap;
  event: DateCellClickEvent;
}
export const CalendarTypeSelectModal = ({ eventTypes, event }: Props) => {
  const array = useMemo(() => Object.entries(eventTypes), [eventTypes]);
  const action = useCalendarDateCellActionHookInvoker(eventTypes);
  const { closeWithNoCallback } = useModalInstance();
  const { t } = useTranslation('Events');
  const headerColor = useHeaderColor();

  return (
    <ModalContent>
      <ModalHeader
        bg={headerColor}
        borderBottom="1px solid"
        borderColor="brand.600"
      >
        {t('Events:create_calendar_event')}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody py={6} px={4}>
        <VStack w="full">
          {array.map(([key, value], idx) => {
            const { icon, createLabel } = value.info;
            const text =
              typeof createLabel === 'string' ? createLabel : createLabel();

            return (
              <Button
                w="full"
                key={`${key}-${idx}`}
                leftIcon={icon}
                onClick={() => {
                  action(event, key);
                  closeWithNoCallback();
                }}
              >
                {text}
              </Button>
            );
          })}
        </VStack>
      </ModalBody>
    </ModalContent>
  );
};
