import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { WorkFormData } from './types';
import { WorkFormActions } from './WorkFormActions';
import { WorkFormFields } from './WorkFormFields';
import { WorkFormWrapper } from './WorkFormWrapper';

export const TicketWorkFormModal = (props: WorkFormData) => {
  const { t } = useTranslation('Ticketing');

  return (
    <WorkFormWrapper {...props}>
      <ModalHeader>
        {props.mode === 'create'
          ? t('Ticketing:add_work')
          : t('Ticketing:edit_work')}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <WorkFormFields />
      </ModalBody>
      <ModalFooter>
        <WorkFormActions />
      </ModalFooter>
    </WorkFormWrapper>
  );
};
