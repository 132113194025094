import { Input, InputProps } from '@chakra-ui/react';
import { Control, FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { withMask } from 'use-mask-input';

import { useMaskedRegister } from './use-masked-register';
import { getDurationFormat } from './utils';

interface DurationInputProps<TField extends FieldValues>
  extends Omit<InputProps, 'onChange'> {
  register: UseFormRegister<Partial<TField>>;
  fieldName: Path<Partial<TField>>;
  control?: Control<TField>;
  hasSeconds?: boolean;
}

interface ControlledInputProps<TField extends FieldValues>
  extends Omit<InputProps, 'onChange'> {
  control?: Control<TField>;
  hasSeconds: boolean;
  onChange?: (value: string) => void;
  value: string;
}

export const DurationInput = <TField extends FieldValues>({
  register,
  fieldName,
  hasSeconds = true,
  ...inputProps
}: DurationInputProps<TField>) => {
  const maskedRegister = useMaskedRegister({ register });

  return <Input {...inputProps} {...maskedRegister(fieldName, hasSeconds)} />;
};

export const ControlledDurationInput = <TField extends FieldValues>({
  control,
  onChange,
  value,
  hasSeconds = true,

  ...inputProps
}: ControlledInputProps<TField>) => {
  const durationFormat = getDurationFormat(hasSeconds);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Input
      value={value}
      onChange={handleChange}
      {...inputProps}
      ref={withMask(durationFormat, {
        placeholder: hasSeconds ? 'HH:mm:ss' : 'HH:mm',
        rightAlign: false,
        definitions: {
          5: { validator: '[0-5]' },
        },
      })}
    />
  );
};
