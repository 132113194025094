import { Path, UseFormRegister } from 'react-hook-form';
import { useHookFormMask } from 'use-mask-input';

import { getDurationFormat } from './utils';

interface Props<TField> {
  register: UseFormRegister<Partial<TField>>;
}

export const useMaskedRegister = <TField>({ register }: Props<TField>) => {
  const registerWithMask = useHookFormMask(register);

  return (fieldName: Path<Partial<TField>>, hasSeconds: boolean) => {
    const formatTo = getDurationFormat(hasSeconds);

    return registerWithMask(fieldName, formatTo, {
      placeholder: hasSeconds ? 'HH:mm:ss' : 'HH:mm',
      rightAlign: false,
      definitions: {
        5: { validator: '[0-5]' },
      },
    });
  };
};
