import { ReactElement, useCallback } from 'react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';

import { SelectOption } from '@bq/components/SelectWrappers';

import { TicketForm } from '../../types';
import { useTicketMutationContext } from '../FormWrapper/TicketMutationContext';
import { TagSelect } from '../Tag/TagSelect';

export const TagsField = (): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { viewOnly } = useTicketMutationContext();
  const { control } = useFormContext<TicketForm>();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({ control, name: 'tags' });

  const handleChange = useCallback(
    (newVal: MultiValue<SelectOption>) => {
      onChange(newVal.map((item) => item.label));
    },
    [onChange]
  );

  return (
    <FormControl isInvalid={fieldState.invalid}>
      <FormLabel fontWeight="bold">{t('Ticketing:tags')}:</FormLabel>
      <TagSelect isDisabled={viewOnly} value={value} onChange={handleChange} />
      {fieldState.error && (
        <FormErrorMessage>
          {fieldState.error.message || fieldState.error.type}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
