import { ReactElement, useCallback } from 'react';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';

interface Props {
  shareID: string;
  onEdit: (shareID: string) => void;
  onDelete: (shareID: string) => void;
}

export const ShareEditMenu = ({
  shareID,
  onEdit,
  onDelete,
}: Props): ReactElement => {
  const { t } = useTranslation('Ticketing');

  const edit = useCallback(() => {
    onEdit(shareID);
  }, [shareID, onEdit]);

  const del = useCallback(() => {
    onDelete(shareID);
  }, [shareID, onDelete]);

  return (
    <Menu>
      <MenuButton
        size="sm"
        as={IconButton}
        icon={<GiHamburgerMenu />}
        variant="ghost"
      />
      <MenuList>
        <MenuItem icon={<FaEdit />} onClick={edit}>
          {t('global:edit')}
        </MenuItem>
        <MenuItem icon={<FaTrash />} onClick={del}>
          {t('global:delete')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
