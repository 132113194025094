import { isSameDay } from 'date-fns';

import { CalendarItemSplitted } from '@bq/components/Calendar/types';
import { parseToDate } from 'BootQuery/Assets/js/dateUtils';

import { WeeksMap } from './types';

export const sortItemsInWeeks = (weeksMap: WeeksMap): WeeksMap => {
  Object.values(weeksMap).forEach(
    ({ items }: { items: CalendarItemSplitted[] }) => {
      items.sort((a, b) => {
        const dateA = parseToDate(a.startDate).getTime();
        const dateB = parseToDate(b.startDate).getTime();

        // Compare dates using date-fns
        if (isSameDay(dateA, dateB)) {
          // If the dates are the same, sort by duration (longer first)
          return b.duration - a.duration;
        }

        // Sort by date (ignoring time)
        return dateA < dateB ? -1 : 1;
      });
    }
  );

  return weeksMap;
};
