import mitt from 'mitt';

import { createUseMitt } from 'BootQuery/Assets/js/use-mitt';

import { TicketDependecyMutation } from './types';

export interface DependencyCallback {
  parentID: number | null;
  mutation: TicketDependecyMutation;
}
type DependecyEvents = {
  onMutation: DependencyCallback;
};
export const dependenciesEvents = mitt<DependecyEvents>();

export const useDependencyEvents = createUseMitt(dependenciesEvents);

export const ticketMutationEvent = (data: DependencyCallback) => {
  dependenciesEvents.emit('onMutation', data);
};
