import { ReactElement, useMemo } from 'react';

import { Event } from 'app/Modules/Events/Assets/components/types';
import { useMailEditors } from 'app/Modules/Mails/Assets/components/EventTypes';
import { MailEditorEvent } from 'app/Modules/Mails/Assets/components/EventTypes/MailEditor/MailEditorCard';
import { Timeline } from 'app/Modules/Phonebook/Assets/components/EventList/Timeline';

interface Props {
  events: Event[];
}

export const TicketEventList = ({ events }: Props): ReactElement => {
  const fullEvents = useEventsWithEditors(events);

  return <Timeline id="ticket-events-list" events={fullEvents} />;
};

function useEventsWithEditors(events: Event[]): Event[] {
  const editorsRes = useMailEditors();

  const editors = Object.values(editorsRes.mailEditors).map(
    (editor): MailEditorEvent => ({
      data: editor,
      type: 'mailEditor',
      id: editor.editorID,
      timestamp: editor.createdAt,
    })
  );

  return useMemo(() => [...editors, ...events], [events, editors]);
}
