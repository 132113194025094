import { ReactElement, useCallback } from 'react';
import { Flex, VStack } from '@chakra-ui/react';

import { AddButton } from '@bq/components/AddButton';
import { LoadingPage } from '@bq/components/LoadingPage';
import { useModal } from '@bq/components/UseModal';

import { TicketWorkFormModal } from './TicketWorkForm';
import { useWorkFormDefinition } from './TicketWorkForm/use-work-form-definition';
import { TicketWorkItems } from './TicketWorkItems';
import { useTicketWorks } from './use-ticket-works';
import { WorksTotalDuration } from './WorksTotalDuration';

interface Props {
  ticketID: number;
  saveID?: string;
  viewOnly?: boolean;
}

export const TicketWorks = ({ ticketID }: Props): ReactElement => {
  const {
    works: { data: works },
  } = useTicketWorks(ticketID);
  const workForm = useWorkFormDefinition();

  const { addModal } = useModal();

  const add = useCallback(() => {
    addModal({
      children: <TicketWorkFormModal ticketID={ticketID} mode="create" />,
      preventClose: {
        defaultPreventState: false,
      },
      componentProps: {
        modal: { size: '6xl' },
      },
    });
  }, [addModal, ticketID]);

  if (!works || !workForm) {
    return <LoadingPage />;
  }

  return (
    <VStack>
      <Flex w="full" alignItems="center" justifyContent="space-between">
        <WorksTotalDuration works={works} />
        <AddButton onClick={add} />
      </Flex>
      <TicketWorkItems ticketID={ticketID} works={works} />
    </VStack>
  );
};
