import { ReactElement, useMemo } from 'react';
import {
  Badge,
  Button,
  Popover,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQueries } from '@tanstack/react-query';
import { FaEye } from 'react-icons/fa';

import { FixedPopoverContent } from '@bq/components/FixedPopoverContent';
import { LoadingPage } from '@bq/components/LoadingPage';
import { Api } from 'BootQuery/Assets/js/api';

import { TicketUser } from './types';

interface Props {
  watchers: number[];
}

export const TicketWatchers = ({ watchers }: Props): ReactElement => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button variant="ghost" fontSize="2xl">
          <Badge>{watchers.length}</Badge>
          &nbsp;
          <FaEye style={{ display: 'inline' }} />
        </Button>
      </PopoverTrigger>
      <FixedPopoverContent>
        <WatcherList watchers={watchers} />
      </FixedPopoverContent>
    </Popover>
  );
};

const WatcherList = ({ watchers }: Props): ReactElement => {
  const userRequests = useMemo(() => {
    return watchers.map((userID) => ({
      queryKey: ['ticketWatcherUser', userID],
      queryFn: () => getUser(userID),
    }));
  }, [watchers]);
  const userQueries = useQueries({ queries: userRequests });

  const anyLoading = userQueries.some((query) => query.isLoading);
  if (anyLoading) {
    return <LoadingPage />;
  }

  const users = userQueries.map((query) => {
    if (query.isLoading || !query.data) {
      throw new Error('Users query said "it\'s" done loading, but it lied');
    }

    return query.data;
  });

  return (
    <VStack py="2">
      {users.map((user) => (
        <Text key={user.ID} as="strong" fontWeight="bold">
          {user.person?.fullName ?? user.username ?? '...'}
        </Text>
      ))}
    </VStack>
  );
};

async function getUser(userID: number): Promise<TicketUser> {
  const { data } = await Api.get<TicketUser>(`/api/users/${userID}`);

  return data;
}
