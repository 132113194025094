import { useMemo } from 'react';
import { AsyncCreatableSelect, MultiValue } from 'chakra-react-select';

import { loadOptions } from './utils';

export interface FilterOption {
  label: string;
  value: string;
}
interface Props {
  value: string[] | null;
  onChange: (newValue: MultiValue<FilterOption>) => void;
  isDisabled?: boolean;
}
export const TagSelect = ({ onChange, value, isDisabled }: Props) => {
  const valueToFilOpt: FilterOption[] = useMemo(
    () => value?.map((item) => ({ label: item, value: item })) ?? [],
    [value]
  );

  return (
    <AsyncCreatableSelect<FilterOption, true>
      isDisabled={isDisabled}
      useBasicStyles
      size={{ base: 'lg', md: 'sm', lg: 'sm' }}
      value={valueToFilOpt}
      isMulti={true}
      cacheOptions
      defaultOptions
      onChange={(newVal) => {
        onChange(newVal);
      }}
      loadOptions={loadOptions}
      selectedOptionColorScheme="brand"
    />
  );
};
