import { differenceInDays, format, isSameDay } from 'date-fns';

import {
  CalendarItemSplitted,
  PopulateMonthCalendarParams,
} from '@bq/components/Calendar/types';
import { getEventBoundaryDates } from '@bq/components/Calendar/Utils/get-event-boundary-dates';
import { populateWeek } from '@bq/components/Calendar/Utils/populate-week';
import { populateWeekShowMoreButtons } from '@bq/components/Calendar/Utils/populate-week-show-more-buttons';
import { parseToDate } from 'BootQuery/Assets/js/dateUtils';

import { generateWeekDatesWithAvaliableSpaces } from '../../Month/utils/gen-week-with-avaliable-spaces';
import { generateNumberOfItems } from '../../Month/utils/generate-number-of-items';
import { generateShowMoreFlags } from '../../Month/utils/generate-show-more-flags';
import {
  getItemFlags,
  getRawGridPositions,
} from '../../Month/utils/shared-utils';
import { WeekKey } from '../../Month/utils/types';

export const getWholeDayEvents = (params: PopulateMonthCalendarParams) => {
  const { gridMap, items, range, maxItems } = params;

  const withDuration = items.reduce<CalendarItemSplitted[]>(
    (all, currentItem) => {
      const dates = getEventBoundaryDates(
        currentItem.startDate,
        currentItem.endDate,
        range
      );

      const gridRawPositions = getRawGridPositions(
        gridMap,
        dates.startDate.formatted,
        dates.endDate.formatted
      );

      const { isSingleDayEvent } = getItemFlags(dates, gridRawPositions);

      if (!isSingleDayEvent) {
        const duration = differenceInDays(
          dates.endDate.rawDate,
          dates.startDate.rawDate
        );

        return [...all, { ...currentItem, duration }];
      }

      return [...all, { ...currentItem, duration: 0 }];
    },
    []
  );
  const sorted = sortItems(withDuration);

  const formatStart = format(range.start, 'yyyy-MM-dd');
  const formatEnd = format(range.end, 'yyyy-MM-dd');

  const key = `${formatStart}/${formatEnd}` as WeekKey;

  const weekSpacesMap = generateWeekDatesWithAvaliableSpaces(key, maxItems);
  // This is map with flags if we already put a show more button
  const showMoreButtonMap = generateShowMoreFlags(key);
  const noOfItemsInEachColumn = generateNumberOfItems(key);

  const {
    newItems,
    noOfItemsInEachColumn: noOfItemsInEachColumnUpdated,
    showMoreButtonMap: showMoreButtonMapUpdated,
  } = populateWeek({
    gridMap,
    items: sorted,
    noOfItemsInEachColumn,
    range,
    showMoreButtonMap,
    weekSpacesMap,
  });
  const showMoreButtons = populateWeekShowMoreButtons({
    showMoreButtonMap: showMoreButtonMapUpdated,
    gridMap,
    range,
  });

  const maxReservedCount = Math.max(
    ...Object.values(noOfItemsInEachColumnUpdated)
  );

  let maxOverflow = 0;

  if (maxReservedCount < maxItems) {
    maxOverflow = Math.max(...Object.values(showMoreButtonMapUpdated));
  }

  return {
    items: [...newItems, ...showMoreButtons],
    maxOverflow,
    maxReservedCount,
  };
};

const sortItems = (items: CalendarItemSplitted[]) => {
  return items.sort((a, b) => {
    const dateA = parseToDate(a.startDate).getTime();
    const dateB = parseToDate(b.startDate).getTime();

    // Compare dates using date-fns
    if (isSameDay(dateA, dateB)) {
      // If the dates are the same, sort by duration (longer first)
      return b.duration - a.duration;
    }

    // Sort by date (ignoring time)
    return dateA < dateB ? -1 : 1;
  });
};
