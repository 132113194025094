import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { Ticket } from '../../types';

export const getTicketsByID = async (IDs: number[]) => {
  const { data } = await Api.get<ListingResponse<Ticket>>(
    '/api/ticketing/tickets',
    {
      params: {
        filters: { 'ID:inArray': IDs },
      },
    }
  );

  return data;
};
