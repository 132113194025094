import { MonthViewItem } from '../Views/Month/MonthViewItem';
import { MonthViewShowMoreButton } from '../Views/Month/MonthViewShowMoreButton';
import { CalendarLayoutItem } from '../Views/Month/utils/types';

export const DayItem = (
  props: CalendarLayoutItem & {
    idx: number;
  }
) => {
  if (props.calendarItemType === 'item') {
    return <MonthViewItem {...props} />;
  }

  return <MonthViewShowMoreButton {...props} />;
};
