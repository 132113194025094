import { eachDayOfInterval } from 'date-fns';

import {
  CalendarDateRange,
  CalendarGridMap,
  EventBoundaryDates,
} from '@bq/components/Calendar/types';

import { GridRawPositions, WeekKey, WeeksMap } from './types';

// This files is various utils that are not big enough to go into separate file
// Most of them are just shorthand to read specific deep nested data
// with fallbacks

export const readPreviousInstance = (weekMap: WeeksMap, weekKey: WeekKey) => {
  // We try to read the previous instance if it exists
  const previousInstance = weekMap[weekKey] ?? {
    items: [],
  };

  return previousInstance;
};

export const getRawGridPositions = (
  gridMap: CalendarGridMap,
  startDate: string,
  endDate: string
): GridRawPositions => {
  const startGridPos = gridMap[startDate];
  const endGridPos = gridMap[endDate];

  return { startGridPos, endGridPos };
};

export const getItemFlags = (
  dates: EventBoundaryDates,
  { startGridPos, endGridPos }: GridRawPositions
) => {
  // If the dates are equal it's obviously a single day event
  const isSingleDayEvent =
    dates.startDate.formatted === dates.endDate.formatted;
  // We know all the positions of dates in a month, if the row changed
  // It means that the week changed, we need to handle multi week events
  // differently than the single week ones
  const didWeekChange = startGridPos.row.start !== endGridPos.row.start;

  return { isSingleDayEvent, didWeekChange };
};

export const generateMonth = (range: CalendarDateRange) => {
  return eachDayOfInterval({ start: range.start, end: range.end });
};
