import { Api } from 'BootQuery/Assets/js/api';

import { ticketingEventListener } from '../ticketing-event-listener';
import { WatcherMap } from './types';

interface SendOpenResponse {
  currentWatchers: WatcherMap;
}

export async function sendOpenTicket(
  ticketID: number | null
): Promise<WatcherMap> {
  const token = ticketingEventListener().socketToken;
  const reqBody = { ticketID, token };

  const { data } = await Api.post<SendOpenResponse>(
    '/api/ticketing/currentlyOpenTicket',
    reqBody
  );

  return data.currentWatchers;
}
